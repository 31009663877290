import React, { useState, useRef } from "react";
import Swal from "sweetalert";
import Header from "./Header";
import Sidebar from "./Sidebar";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useContext } from "react";
import { url } from "../index";
import { useEffect } from "react";
import { useNavigate, useParams } from "react-router";
import DocumentTitle from "react-document-title";
import Footer from "./Footer";
import DOMPurify from "dompurify";

const CaseTypeDetails = () => {
  const baseUrl = useContext(url);
  const navigate = useNavigate();
  const [data, setData] = useState("");
  const { id } = useParams();

  // -------------- HANDLE SUBMIT CASE STATUS ---------------
  const getApiData = async (id) => {
    const caseStatusForm = new FormData();
    caseStatusForm.append("cnr", id);

    try {
      const response = await fetch(
        `${baseUrl}CaseStatusByGovWeb/fetchCaseDataByCNR`,
        {
          method: "POST",
          headers: {
            Authorization: `${localStorage.getItem("access_token")}`,
            Accept: "*/*",
          },
          body: caseStatusForm,
        }
      );

      const data = await response.json();
      Swal.close();
      if (data && data.result.message === "Session Time Expire.") {
        toast.error("Token expired please login again");
        navigate("/");
      }

      if (data && data.status === "Success") {
        toast.success("Success !");
        setData(data && data.result);
        console.log(data && data.result);
      } else {
        toast.error(data.result.message);
      }
    } catch (error) {
      console.error(error);
    }
};

    // Generate the dynamic HTML string
    const dynamicHTML = `
    <!DOCTYPE html>
    <html lang="en">
    <head>
        <meta charset="UTF-8">
        <meta name="viewport" content="width=device-width, initial-scale=1.0">
        <title>Notice Letter</title>
    </head>
    <body>
        <p>${data}</p>
    </body>
    </html>
  `;
 
   // Sanitize the dynamic HTML
   const sanitizedHTML = DOMPurify.sanitize(dynamicHTML);

  useEffect(() => {
    if (localStorage.getItem("access_token") === null) {
      navigate("/");
    } else {
      window.scrollTo(0, 0);
      getApiData(id)
    }
  }, [id]);

  return (
    <>
      <DocumentTitle title="Search Case Type | LEGAL CRM">
        <div>
          <div className="wraper">
            <Header />
            <div className="mainWraper">
              <Sidebar />
              <div className="pageBody">
                <div className="pageContent">
                  <div className="PageTitle">
                <h3>Case Type Detail</h3>
                  </div>
                  <div
                  dangerouslySetInnerHTML={{ __html: sanitizedHTML }}
                  style={{
                    border: "1px solid #ccc",
                    padding: "10px",
                    marginTop: "20px",
                  }}
                ></div>
                </div>
              </div>
            </div>
            <Footer />
          </div>
        </div>
      </DocumentTitle>
    </>
  );
};

export default CaseTypeDetails;
