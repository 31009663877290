import React, { useState, useRef } from "react";
import Swal from "sweetalert";
import Header from "./Header";
import Sidebar from "./Sidebar";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useContext } from "react";
import { url } from "../index";
import { useEffect } from "react";
import { useNavigate } from "react-router";
import { Link } from "react-router-dom";
import { jsPDF } from "jspdf";
import autoTable from "jspdf-autotable";
import {
  MaterialReactTable,
  createMRTColumnHelper,
  useMaterialReactTable,
} from "material-react-table";
import { Box, Button } from "@mui/material";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import { mkConfig, generateCsv, download } from "export-to-csv";
import {
  deleteUser,
  formatDate1,
  activeStatus,
  getcourtView,
  permission,
  getClient,
  formateDateTime,
  getBranchCity,
} from "../services/api.service";
import Preloader from "./Preloader";
import Select from "react-select";
import DocumentTitle from "react-document-title";
import Footer from "./Footer";
import Checkbox from "@mui/material/Checkbox";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";

const Case = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [saveButton, setSaveButton] = useState(true);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    setTimeout(() => {
      setIsLoading(false);
    }, 500);
  }, []);

  const handleKeyPress = (e) => {
    const charCode = e.which || e.keyCode;
    if (
      charCode < 48 ||
      charCode > 57 ||
      (charCode !== 8 && e.target.value.length >= 10)
    ) {
      e.preventDefault();
    }
  };

  //------------ CLOSE POPUP VARIABLE ----------

  const closeCaseAdd = document.querySelector(".closeCaseAdd");
  const openCaseAddForm = document.querySelector(".openCaseAddForm");
  const closeCaseStatusForm = document.querySelector(".closeCaseStatusForm");
  const closeButton = document.querySelector(".btn_popup");
  const closeEdit = document.querySelector(".e_popup");
  const closeDelete = document.querySelector(".d_case");
  const aorClick = document.querySelector(".aorClick");
  const btnCancelAor = document.querySelector(".btn_cancle_aor");

  //------------ CASE VARIABLE ----------

  const currentDate = new Date().toISOString().split("T")[0];
  const [registration_number, setregistration_number] = useState("");
  const [filterActive, setFilterActive] = useState(false);
  const [fCaseId, setFCaseId] = useState("");
  const [fFromDate, setFFromDate] = useState("");
  const [fCourt, setFCourt] = useState("");
  const [coCounselData, setCoCounselData] = useState([]);
  const [fCaseNo, setFCaseNo] = useState("");
  const [fIsOpen, setFIsOpen] = useState("1");
  const [fFilingDate, setFFilingDate] = useState("");
  const [isFieldRequired, setIsFieldRequired] = useState(false);
  const filterform = new FormData();
  const addAorForm = new FormData();
  const editCaseDelete = new FormData();
  const detailsForm = new FormData();
  const [fToDate, setFToDate] = useState("");
  const [fClientID, setFClientID] = useState("");
  const [fClientOption, setFClientOption] = useState([]);
  const Case = new FormData();
  const aorForm = new FormData();
  const [optionsEvent, setOptionsEvent] = useState([]);

  const baseUrl = useContext(url);
  const [branchCity, setBranchCity] = useState("");
  const [editBranchCity, setEditBranchCity] = useState("");
  const [branchCityData, setBranchCityData] = useState([]);
  const [aorData, setAorData] = useState([]);
  const navigate = useNavigate();
  const [dCaseName, setDCaseName] = useState("");
  const [dCaseType, setDCaseType] = useState("");
  const [dCaseYear, setDCaseYear] = useState("");
  const [dcity_title, setDcity_title] = useState("");
  const [dClientCompany, setDClientCompany] = useState("");
  const [dClientLastName, setDClientLastName] = useState("");
  const [dClientName, setDClientName] = useState("");
  const [dCourtName, setDCourtName] = useState("");
  const [dAddedBy, setDAddedBy] = useState("");
  const [dDate, setDDate] = useState("");
  const [dCityCourtName, setDCityCourtName] = useState("");
  const [dIsOpen, setDIsOpen] = useState("");
  const [ddiaryNo, setDDiaryNo] = useState("");
  const [ddiaryYear, setDDiaryYear] = useState("");
  const [dcaseNo, setDCaseNo] = useState("");
  const [villageID, setVillageID] = useState("");
  const [village, setVillage] = useState([]);
  const [detailData, setDetailData] = useState([]);
  const [dsummary, setDSummary] = useState("");
  const [dfixedFees, setDFixedFees] = useState("");
  const [dexpenses, setDExpenses] = useState("");
  const [dappearanceFee, setDAppearanceFee] = useState("");
  const [dnonAppearanceFee, setDNonAppearanceFee] = useState("");
  const [daor, setDAor] = useState("");
  const [dretaineed, setDRetaineed] = useState("");
  const [dpatitioners, setDPatitioners] = useState("");
  const [drespondants, setDRespondants] = useState("");
  const [dintervenors, setDIntervenors] = useState("");
  const [ddistrict_title, setDdistrict_title] = useState("");
  const [dFileNo, setDFileNo] = useState("");
  const [dstate_title, setDstate_title] = useState("");
  const [isAdd, setIsAdd] = useState("0");
  const [isEdit, setIsEdit] = useState("0");
  const [isView, setIsView] = useState("0");
  const [isDelete, setIsDelete] = useState("0");
  const [isStatus, setIsStatus] = useState("0");
  const [popup, setPopup] = useState(false);
  const [compantPrecentage, setCompantPrecentage] = useState("");
  const [viewUserData, setViewUserData] = useState([]);
  const [viewUserCoCounselData, setViewUserCoCounselData] = useState([]);
  const [cocounselRow, setCocounselRow] = useState([
    { coCounselName: "", coCounselShare: "" },
  ]);
  const [judgements, setJudgements] = useState([]);
  const [filingDate, setFilingdate] = useState("");
  const [fileNo, setFileNo] = useState("");
  const [title1, setTitle1] = useState("");
  const [title2, setTitle2] = useState("");
  const [year, setYear] = useState([]);
  const [caseType, setCaseType] = useState([]);
  const [state, setState] = useState([]);
  const [district, setDistrict] = useState([]);
  const [stateId, setStateId] = useState("");
  const [districtId, setDistrictId] = useState("");
  const [cityId, setCityId] = useState("");
  const [courtData, setCourtData] = useState([]);
  const [court, setCourt] = useState("");
  const [city, setCity] = useState([]);
  const [type, setType] = useState("");
  const [diaryNo, setDiaryNo] = useState("");
  const [diaryYear, setDiaryYear] = useState("");
  const [caseNo, setCaseNo] = useState("");
  const [caseYear, setCaseYear] = useState("");
  const [client, setClient] = useState("");
  const [title, setTitle] = useState("");
  const [aorName, setAorName] = useState("");
  const [summary, setSummary] = useState("");
  const [fixedFees, setFixedFees] = useState("");
  const [expenses, setExpenses] = useState("");
  const [appearanceFee, setAppearanceFee] = useState("");
  const [nonAppearanceFee, setNonAppearanceFee] = useState("");
  const [counsel, setCounsel] = useState("");
  const [coCounsel, setCoCounsel] = useState("");
  const [counselShare, setCounselShare] = useState("0");
  const [coCounselShare, setCoCounselShare] = useState("");
  const [aor, setAor] = useState("");
  const [retaineed, setRetaineed] = useState("");
  const [patitioners, setPatitioners] = useState("");
  const [respondants, setRespondants] = useState("");
  const [intervenors, setIntervenors] = useState("");
  const [filterUser, setFilterUser] = useState("");
  const [delId, setDelId] = useState("");
  const [active, setActive] = useState(false);
  const [totalCoCounsel, setTotalCoCounsel] = useState("0");
  const [data, setData] = useState([]);
  const [csrfToken, setCsrfToken] = useState("");
  const [captchaPath, setCaptchaPath] = useState("");
  const [caseStatusCourtID, setCaseStatusCourtID] = useState("");
  const [caseStatusCaseType, setCaseStatusCaseType] = useState("");
  const [caseStatusCaseNumber, setCaseStatusCaseNumber] = useState("");
  const [caseStatusCaptchaCode, setCaseStatusCaptchaCode] = useState("");
  const [caseStatusCaseYear, setCaseStatusCaseYear] = useState("");

  //------------ EDIT CASE VARIABLE ----------

  const [editId, setEditId] = useState("");
  const [deleteId, setDeleteId] = useState("");
  const [editfilingDate, setEditFilingdate] = useState("");

  const [inputWidth, setInputWidth] = useState(6);
  const [showClosingDate, setShowClosingDate] = useState(false);
  const [closingDate, setClosingDate] = useState("");
  const [isOpen, setIsOpen] = useState("1");
  const [editnewOld, setEditNewOld] = useState("");
  const [editdiaryNo, setEditDiaryNo] = useState("");
  const [editdiaryYear, setEditDiaryYear] = useState("");
  const [editcaseNo, setEditCaseNo] = useState("");
  const [editcaseYear, setEditCaseYear] = useState("");
  const [editclient, setEditClient] = useState("");
  const [edittitle, setEditTitle] = useState("");
  const [editsummary, setEditSummary] = useState("");
  const [editfixedFees, setEditFixedFees] = useState("");
  const [editexpenses, setEditExpenses] = useState("");
  const [editappearanceFee, setEditAppearanceFee] = useState("");
  const [editnonAppearanceFee, setEditNonAppearanceFee] = useState("");
  const [editcounsel, setEditCounsel] = useState("");
  const [editcoCounsel, setEditCoCounsel] = useState("");
  const [editcounselShare, setEditCounselShare] = useState("");
  const [editcoCounselShare, setEditCoCounselShare] = useState("");
  const [editaor, setEditAor] = useState("");
  const [editretaineed, setEditRetaineed] = useState("");
  const [editpatitioners, setEditPatitioners] = useState("");
  const [editrespondants, setEditRespondants] = useState("");
  const [editintervenors, setEditIntervenors] = useState("");
  const [caseTypeID, setcaseTypeID] = useState("");

  const formData = new FormData();
  const editCaseLead = new FormData();
  const districtForm = new FormData();
  const cityForm = new FormData();
  const villegeForm = new FormData();
  const configForm = new FormData();
  const form = new FormData();
  const duplicateCaseForm = new FormData();

  const [open, setOpen] = useState(false);
  const [selectedColumns, setSelectedColumns] = useState([]);

  const handleExportButtonClick = () => {
    setOpen(true);
  };

  const handleClose1 = () => {
    setOpen(false);
  };

  const handleToggleColumn = (columnName) => {
    const currentIndex = selectedColumns.indexOf(columnName);
    const newSelectedColumns = [...selectedColumns];

    if (currentIndex === -1) {
      newSelectedColumns.push(columnName);
    } else {
      newSelectedColumns.splice(currentIndex, 1);
    }

    setSelectedColumns(newSelectedColumns);
  };

  // ------- FILE DATA --------

  const getFileNo = async () => {
    Case.append("AdminID", localStorage.getItem("id"));
    Case.append("UserID", localStorage.getItem("uId"));
    try {
      const response = await fetch(`${baseUrl}Event/GetFilename`, {
        method: "POST",
        body: Case,
        headers: {
          Authorization: `${localStorage.getItem("access_token")}`,
          Accept: "*/*",
        },
      });
      const data = await response.json();

      if (data && data.result[0].message === "Success") {
        const Mapped =
          data &&
          data.result.map((item) => ({
            value: item.CaseID,
            label: `${item.FileNo} / ${item.CaseNo} / ${item.CaseName} / ${item.CaseYear}`,
          }));
        setOptionsEvent(Mapped);
      }
      if (data && data.result.message == "Session Time Expire.") {
        toast.error("Token expired please login again");
        setTimeout(() => {
          navigate("/");
        }, 3000);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const configApi = async () => {
    configForm.append("AdminID", localStorage.getItem("id"));
    try {
      const response = await fetch(`${baseUrl}Config/ViewConfigById`, {
        method: "POST",
        body: configForm,
        headers: {
          Authorization: `${localStorage.getItem("access_token")}`,
          Accept: "*/*",
        },
      });
      const data = await response.json();
      setCompantPrecentage(parseInt(data && data.result[0].CompanyPer));
      if (data && data.result.message == "Session Time Expire.") {
        toast.error("Token expired please login again");
        setTimeout(() => {
          navigate("/");
        }, 3000);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const clientApi = async () => {
    configForm.append("AdminID", localStorage.getItem("id"));
    configForm.append("UserID", localStorage.getItem("uId"));
    try {
      const response = await fetch(`${baseUrl}Admin/ViewUser`, {
        method: "POST",
        body: configForm,
        headers: {
          Authorization: `${localStorage.getItem("access_token")}`,
          Accept: "*/*",
        },
      });
      const data = await response.json();
      if (data && data.result.message == "Session Time Expire.") {
        toast.error("Token expired please login again");
        setTimeout(() => {
          navigate("/");
        }, 3000);
      }

      const clientData =
        data && data.result.advocateUser.filter((item) => item.Status === "1");

      const sortData =
        clientData &&
        clientData.sort((a, b) => {
          const nameA = a.Name.toUpperCase();
          const nameB = b.Name.toUpperCase();

          if (nameA < nameB) {
            return -1;
          }
          if (nameA > nameB) {
            return 1;
          }
          return 0;
        });

      setViewUserData(sortData);
    } catch (error) {
      console.error(error);
    }
  };

  //------------ VIEW CASE ---------

  const withUidApi = async (path) => {
    setLoading(true);
    form.append("AdminID", localStorage.getItem("id"));
    form.append("UserID", localStorage.getItem("uId"));
    form.append("IsOpen", 1);
    try {
      const response = await fetch(`${baseUrl}${path}`, {
        method: "POST",
        body: form,
        headers: {
          Authorization: `${localStorage.getItem("access_token")}`,
          Accept: "*/*",
        },
      });
      const data = await response.json();
      if (data && data.result.message == "Session Time Expire.") {
        toast.error("Token expired please login again");
        setTimeout(() => {
          navigate("/");
        }, 2000);
      }

      if (data && data.result.length === 0) {
        setLoading(false);
        setData([]);
      }

      if (data && data.result[0].message === "Success") {
        setLoading(false);
        setData(data && data.result);
      } else {
        setData([]);
        setLoading(false);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const viewAorApi = async () => {
    aorForm.append("AdminID", localStorage.getItem("id"));
    try {
      const response = await fetch(`${baseUrl}cases/GetAor`, {
        method: "POST",
        body: aorForm,
        headers: {
          Authorization: `${localStorage.getItem("access_token")}`,
          Accept: "*/*",
        },
      });

      const data = await response.json();
      if (data && data.result.message == "Session Time Expire.") {
        toast.error("Token expired please login again");
        setTimeout(() => {
          navigate("/");
        }, 2000);
      }

      if (data && data.result.length) {
        setAorData(data && data.result);
      } else {
        setAorData([]);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const getCaseView = async () => {
    const [yearData, coData, data, cdata, branchCityData] = await Promise.all([
      getYear(),
      getcourtView("Master/ViewCourt", court),
      permission(4),
      getClient("Client/ViewClient"),
      getBranchCity(),
      getFileNo(),
      configApi(),
      clientApi(),
      getCountries(),
      getCountriesUser(),
      withUidApi("Cases/ViewCasefilter"),
      viewAorApi(),
      getCaptchaCode(),
    ]);

    const clientData = cdata && cdata.filter((item) => item.Status === "1");

    const Mapped =
      clientData &&
      clientData.map((item) => ({
        value: item.Id,
        label: `${item.FirstName} ${item.LastName}`,
      }));

    setFClientOption(Mapped);

    setYear(yearData);
    setBranchCityData(branchCityData);
    const c_data = coData && coData.filter((item) => item.Status === "1");
    setCourtData(c_data);

    if (
      data &&
      data.result[0].IsAdd === "0" &&
      data &&
      data.result[0].IsView === "0"
    ) {
      navigate("/unauthorized");
    }

    setIsView(data && data.result[0].IsView);
    setIsAdd(data && data.result[0].IsAdd);
    setIsEdit(data && data.result[0].IsEdit);
    setIsStatus(data && data.result[0].IsStatus);
    setIsDelete(data && data.result[0].IsDelete);
  };

  const getCaseType = async () => {
    const data = await getcourtView("Master/ViewCaseTypeByCourtId", court);
    setCaseType(data);
  };

  //----------- DELETE CASE ----------

  const deleteCaseLead = async () => {
    const data = await deleteUser(delId, "Cases/DeleteCase");
    if (data === "token") {
      setTimeout(() => {
        closeDelete.click();
        navigate("/");
      }, 1000);
    }

    if (data === true) {
      closeDelete.click();
      filterActive ? filterApi() : getCaseView();
    }
  };

  //------------ ACTIVE DEACTIVE CASE ----------

  const deActive = async (row) => {
    const data = await activeStatus(row, "Cases/CaseStatus");
    if (data === "token") {
      setTimeout(() => {
        navigate("/");
      }, 3000);
    }

    if (data === true) {
      filterActive ? filterApi() : getCaseView();
    }
  };

  //------------ SEARCH DETAILS ----------

  const details = async (id) => {
    detailsForm.append("AdminID", `${localStorage.getItem("id")}`);
    detailsForm.append("ID", id);
    try {
      const response = await fetch(`${baseUrl}Cases/ViewCaseByIdOLD`, {
        method: "POST",
        body: detailsForm,
        headers: {
          Authorization: `${localStorage.getItem("access_token")}`,
          Accept: "*/*",
        },
      });
      const data = await response.json();

      if (data && data.result.message === "Session Time Expire.") {
        toast.error("Token expired please login again");
        setTimeout(() => {
          navigate("/");
        }, 1000);
      }

      if (data && data.result.Case[0].message === "Success") {
        setDetailData(data && data.result.CaseDetail);
        setDCourtName(data && data.result.Case[0].CourtName);
        setDstate_title(data && data.result.Case[0].state_title);
        setDdistrict_title(data && data.result.Case[0].district_title);
        setDcity_title(data && data.result.Case[0].city_title);
        setDCaseType(data && data.result.Case[0].CaseType);
        setDFileNo(data && data.result.Case[0].FileNo);
        setDCaseYear(data && data.result.Case[0].CaseYear);
        setDClientName(data && data.result.Case[0].ClientName);
        setDClientLastName(data && data.result.Case[0].ClientLastName);
        setDClientCompany(data && data.result.Case[0].ClientCompany);
        setDCaseName(data && data.result.Case[0].CaseName);
        setDAddedBy(data && data.result.Case[0].AddedBy);
        setDDate(data && data.result.Case[0].Date);
        setDCityCourtName(data && data.result.Case[0].CityCourtName);
        setDIsOpen(data && data.result.Case[0].IsOpen);
        setDAor(data && data.result.Case[0].AOR);
        setDIntervenors(data && data.result.Case[0].Intervenors);
        setDRespondants(data && data.result.Case[0].Respondants);
        setDRetaineed(data && data.result.Case[0].RetainedFor);
        setDPatitioners(data && data.result.Case[0].Petitioners);
        setDNonAppearanceFee(data && data.result.Case[0].NonAppearanceFee);
        setDAppearanceFee(data && data.result.Case[0].AppearanceFee);
        setDExpenses(data && data.result.Case[0].Expenses);
        setDFixedFees(data && data.result.Case[0].FixedFees);
        setDSummary(data && data.result.Case[0].CaseSummary);
        setDCaseNo(data && data.result.Case[0].CaseNo);
        setDDiaryNo(data && data.result.Case[0].DiaryNo);
        setDDiaryYear(data && data.result.Case[0].DiaryYear);
      } else {
        toast.error(data && data.result.Case[0].message);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const getById = async (id) => {
    detailsForm.append("AdminID", `${localStorage.getItem("id")}`);
    detailsForm.append("ID", id);
    try {
      const response = await fetch(`${baseUrl}Cases/ViewCaseByIdOLD`, {
        method: "POST",
        body: detailsForm,
        headers: {
          Authorization: `${localStorage.getItem("access_token")}`,
          Accept: "*/*",
        },
      });
      const data = await response.json();

      if (data && data.result.message === "Session Time Expire.") {
        toast.error("Token expired please login again");
        setTimeout(() => {
          navigate("/");
        }, 1000);
      }

      if (data && data.result.Case[0].message === "Success") {
        const updatedItems =
          data &&
          data.result.CaseDetail.filter((item) => item.CounselType === "2");

        const rowsData = [];

        if (updatedItems && updatedItems.length) {
          updatedItems &&
            updatedItems.forEach((item) => {
              rowsData.push({
                coCounselName: item.CounselID,
                coCounselShare: item.CounselPer,
                coCounselId: item.Id,
              });
            });

          setCocounselRow(rowsData);
        } else {
          setCocounselRow([{ coCounselName: "", coCounselShare: "" }]);
        }

        setEditId(data && data.result.Case[0].Id);
        setVillageID(data && data.result.Case[0].VillageID);
        setCourt(
          data && data.result.Case[0].CourtID === "0"
            ? ""
            : data.result.Case[0].CourtID
        );
        setStateId(
          data && data.result.Case[0].StateID === "0"
            ? ""
            : data.result.Case[0].StateID
        );
        setDistrictId(
          data.result.Case[0].DistrictID === "0"
            ? ""
            : data.result.Case[0].DistrictID
        );
        setCityId(
          data && data.result.Case[0].CityID === "0"
            ? ""
            : data.result.Case[0].CityID
        );
        setType(
          data && data.result.Case[0].CaseTypeID === "0"
            ? ""
            : data.result.Case[0].CaseTypeID
        );

        setEditNewOld(data && data.result.Case[0].CaseStage);
        setEditDiaryNo(data && data.result.Case[0].DiaryNo);
        setEditDiaryYear(data && data.result.Case[0].DiaryYear);
        setEditCaseNo(data && data.result.Case[0].CaseNo);
        setEditCaseYear(data && data.result.Case[0].CaseYear);
        setEditClient(data && data.result.Case[0].ClientID);
        const ab = data && data.result.Case[0].CaseName;
        const a = ab.split("VS");
        setTitle1(a[0]);
        setTitle2(a[1]);
        setEditSummary(data && data.result.Case[0].CaseSummary);
        setEditBranchCity(data && data.result.Case[0].BranchCity);
        setEditPatitioners(data && data.result.Case[0].Petitioners);
        setEditIntervenors(data && data.result.Case[0].Intervenors);
        setEditFixedFees(data && data.result.Case[0].FixedFees);
        setEditAppearanceFee(data && data.result.Case[0].AppearanceFee);
        setEditAor(data && data.result.Case[0].AOR);
        setEditRespondants(data && data.result.Case[0].Respondants);
        setEditRetaineed(data && data.result.Case[0].RetainedFor);
        setEditExpenses(data && data.result.Case[0].Expenses);
        setEditNonAppearanceFee(data && data.result.Case[0].NonAppearanceFee);
        setEditFilingdate(data && data.result.Case[0].FilingDate);
        setEditTitle(data && data.result.Case[0].CaseName);
        setIsOpen(data && data.result.Case[0].IsOpen);
        setClosingDate(
          (data && data.result.Case[0].ClosingDate === null) ||
            data.result.Case[0].ClosingDate === "0000-00-00"
            ? ""
            : data.result.Case[0].ClosingDate
        );
        setEditCounsel(data && data.result.CaseDetail[0].CounselID);
        setEditCounselShare(data && data.result.CaseDetail[0].CounselPer);
        data && data.result.Case[0].CourtID === "3"
          ? setShowInputField(true)
          : setShowInputField(false);
      } else {
        toast.error(data && data.result.Case[0].message);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const formRef = useRef(null);
  const [show, setShow] = useState(false);
  const handleClose = () => {
    setShow(false);
    if (formRef.current) {
      formRef.current.reset();
    }
  };
  const [showField, setShowField] = useState(false);
  const handleClick = () => {
    setShowField(true);
    if (showField === true) {
      setShowField(false);
    }
  };

  const handleClickback = () => {
    setShowField(false);
    clearForm();
  };

  const [showInputField, setShowInputField] = useState(false);
  const [showInputField1, setShowInputField1] = useState(false);
  const handleCourtChange = (selectedValue, name) => {
    setCourt(selectedValue);
    setType("");
    if (name === "Supreme Court") {
      setShowInputField(true);
    } else {
      setShowInputField(false);
      setAor("");
      setDiaryYear("");
      setDiaryNo("");
      setEditDiaryNo("");
      setEditDiaryYear("");
      setEditAor("");
    }
  };

  const handlecoCounsel = (index, value) => {
    const updatedFormData = [...cocounselRow];
    updatedFormData[index].coCounselName = value;
    setCocounselRow(updatedFormData);
  };

  const editHandlecoCounsel = (index, value) => {
    const updatedFormData = [...cocounselRow];
    updatedFormData[index].coCounselName = value;
    setCocounselRow(updatedFormData);
  };

  const handlecoCounsel1 = (event) => {
    const selectedValue = event.target.value;
    setEditCoCounsel(selectedValue);
    setEditCoCounselShare("");
    if (selectedValue) {
      setShowInputField1(true);
    } else {
      setShowInputField1(false);
    }
  };
  const handleStateChange = (e) => {
    setStateId(e.target.value);
    setDistrictId("");
    setCityId("");
  };

  const handleDistrictChange = (e) => {
    setDistrictId(e.target.value);
    setCityId("");
  };

  // ---------- FILTER API --------- MANISH PRAJAPAT DATE 06 - 10 - 2023 ---------

  const filterApiSearch = async (e) => {
    e.preventDefault();
    setLoading(true);
    filterform.append("AdminID", localStorage.getItem("id"));
    filterform.append("UserID", localStorage.getItem("uId"));
    filterform.append("CaseID", fCaseId);
    filterform.append("ClientID", fClientID);
    filterform.append("From", fFromDate);
    filterform.append("To", fToDate);
    filterform.append("IsOpen", fIsOpen);
    filterform.append("Dates", fFilingDate);

    try {
      const response = await fetch(`${baseUrl}Cases/ViewCasefilter`, {
        method: "POST",
        body: filterform,
        headers: {
          Authorization: `${localStorage.getItem("access_token")}`,
          Accept: "*/*",
        },
      });
      const data = await response.json();

      if (data && data.result.message == "Session Time Expire.") {
        toast.error("Token expired please login again");
        setTimeout(() => {
          navigate("/");
        }, 2000);
      }

      if (
        data &&
        data.result.length &&
        data &&
        data.result[0].message === "Success"
      ) {
        setFilterActive(true);
        setData(data.result);
        setLoading(false);
      } else {
        setData([]);
        setLoading(false);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const filterApi = async () => {
    setLoading(true);
    filterform.append("AdminID", localStorage.getItem("id"));
    filterform.append("UserID", localStorage.getItem("uId"));
    filterform.append("CaseID", fCaseId);
    filterform.append("ClientID", fClientID);
    filterform.append("From", fFromDate);
    filterform.append("To", fToDate);
    filterform.append("IsOpen", fIsOpen);
    filterform.append("Dates", fFilingDate);

    try {
      const response = await fetch(`${baseUrl}Cases/ViewCasefilter`, {
        method: "POST",
        body: filterform,
        headers: {
          Authorization: `${localStorage.getItem("access_token")}`,
          Accept: "*/*",
        },
      });
      const data = await response.json();

      if (data && data.result.message == "Session Time Expire.") {
        toast.error("Token expired please login again");
        setTimeout(() => {
          navigate("/");
        }, 2000);
      }

      if (
        data &&
        data.result.length &&
        data &&
        data.result[0].message === "Success"
      ) {
        setFilterActive(true);
        setData(data.result);
        setLoading(false);
      } else {
        setData([]);
        setLoading(false);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const filterCancel = () => {
    clearForm();
    setClient("");
    setCounsel("");
    setCaseYear("");
    setFCaseId("");
    setFClientID("");
    setFFromDate("");
    setIsFieldRequired(false);
    setFFilingDate("");
    setFIsOpen("1");
    setFCaseNo("");
    setFToDate("");
    getCaseView();
    setFilterActive(false);
  };

  const handleFilterCase = (selectedOption) => {
    if (selectedOption === null) {
      setFCaseId("");
    } else {
      setFCaseId(selectedOption.value);
    }
  };

  const handleFilterClient = (selectedOption) => {
    if (selectedOption === null) {
      setFClientID("");
    } else {
      setFClientID(selectedOption.value);
    }
  };

  // --------- ADD ROW ---------> MANISH PRAJAPAT 05 - 10 - 2023 <---------

  const addRow = () => {
    const total = 100 - compantPrecentage;
    if (cocounselRow.length < total) {
      setCocounselRow([
        ...cocounselRow,
        { coCounselName: "", coCounselShare: "" },
      ]);
    } else {
      alert(`Total Percentage is ${total} %`);
    }
  };

  const editAddRow = () => {
    const total = 100 - compantPrecentage;
    if (cocounselRow.length < total) {
      setCocounselRow([
        ...cocounselRow,
        { coCounselName: "", coCounselShare: "" },
      ]);
    } else {
      alert(`Total Percentage is ${total} %`);
    }
  };

  const removeRow = (indexToRemove) => {
    if (cocounselRow.length === 1) {
      return;
    }

    let updatedFormData = cocounselRow.filter(
      (_, index) => index !== indexToRemove
    );

    setTotalCoCounsel(
      totalCoCounsel - cocounselRow[indexToRemove].coCounselShare
    );
    setCocounselRow(updatedFormData);
  };

  const editRemoveRow = async (indexToRemove, Id) => {
    editCaseDelete.append("AdminID", localStorage.getItem("id"));
    editCaseDelete.append("ID", Id);
    editCaseDelete.append("CaseID", editId);
    editCaseDelete.append("DeletedBy", localStorage.getItem("UserId"));

    try {
      const response3 = await fetch(`${baseUrl}Cases/DeleteCaseDeatil`, {
        method: "POST",
        body: editCaseDelete,
        headers: {
          Authorization: `${localStorage.getItem("access_token")}`,
          Accept: "*/*",
        },
      });
    } catch (error) {
      console.error(error);
    }

    if (cocounselRow.length === 1) {
      return;
    }

    let updatedFormData = cocounselRow.filter(
      (_, index) => index !== indexToRemove
    );

    setTotalCoCounsel(
      totalCoCounsel - cocounselRow[indexToRemove].coCounselShare
    );
    setCocounselRow(updatedFormData);
  };

  //------------ SUBMIT CASE ----------

  const [isValid1, setIsValid1] = useState(true);

  const handleCheck = () => {
    const num1 = parseInt(counselShare);
    const num2 = parseInt(coCounselShare);
    if (isNaN(num1) || num2 < 0 || num2 > 50) {
      setIsValid1(false);
    } else {
      const sum = num1 + num2;
      if (sum === 50) {
        formData.append("CoCounselShare", coCounselShare);
        formData.append("CounselShare", counselShare);
        setIsValid1(true);
      } else {
        toast.error("Sum is not 50.");
      }
    }
  };

  useEffect(() => {
    setIsOpen(isOpen);
    if (isOpen === "0") {
      setShowClosingDate(true);
      setInputWidth(12);
    } else {
      setShowClosingDate(false);
      setInputWidth(6);
      setClosingDate("");
    }
  }, [isOpen]);

  const handleCounselShareChange = (e) => {
    if (e.target.value === "") {
      setCounselShare("0");
    } else {
      const value = parseInt(e.target.value);
      const total = 100 - compantPrecentage;
      const total1 = total - totalCoCounsel;

      if (
        value !== null &&
        value !== undefined &&
        value !== "" &&
        value <= total &&
        value <= total1
      ) {
        setCounselShare(value);
      } else {
        alert(`Total Percentage is ${total} %`);
      }
    }
  };

  const handleCounselShareChange1 = (e) => {
    if (e.target.value === "") {
      setEditCounselShare("0");
    } else {
      const value = parseInt(e.target.value);
      const total = 100 - compantPrecentage;
      const total1 = total - totalCoCounsel;

      if (
        value !== null &&
        value !== undefined &&
        value !== "" &&
        value <= total &&
        value <= total1
      ) {
        setEditCounselShare(value);
      } else {
        alert(`Total Percentage is ${total} %`);
      }
    }
  };

  const [isValid, setIsValid] = useState(true);

  const handleCoCounselShareChange = (index, value) => {
    const updatedFormData = [...cocounselRow];
    if (updatedFormData[index].coCounselName === "") {
      toast.error("Please Select CoCounsel");
    } else {
      const total = 100 - compantPrecentage;
      const updatedFormData = [...cocounselRow];
      const oldValue = +updatedFormData[index].coCounselShare;
      const total1 = total - counselShare;

      if (value <= total && value <= total1) {
        updatedFormData[index].coCounselShare = value;

        let sum = updatedFormData.reduce(function (prev, current) {
          return prev + +current.coCounselShare;
        }, 0);

        if (sum <= total && sum <= total1) {
          setTotalCoCounsel(sum);
          setCocounselRow(updatedFormData);
        } else {
          updatedFormData[index].coCounselShare = oldValue;
          alert(`Total Percentage is ${total} %`);
        }
      } else {
        alert(`Total Percentage is ${total} %`);
      }
    }
  };

  const editHandleCoCounselShare = (index, value) => {
    const updatedFormData = [...cocounselRow];
    if (updatedFormData[index].coCounselName === "") {
      toast.error("Please Select CoCounsel");
    } else {
      const total = 100 - compantPrecentage;
      const updatedFormData = [...cocounselRow];
      const oldValue = +updatedFormData[index].coCounselShare;
      const total1 = total - editcounselShare;

      if (value <= total && value <= total1) {
        updatedFormData[index].coCounselShare = value;

        let sum = updatedFormData.reduce(function (prev, current) {
          return prev + +current.coCounselShare;
        }, 0);

        if (sum <= total && sum <= total1) {
          setCocounselRow(updatedFormData);
        } else {
          updatedFormData[index].coCounselShare = oldValue;
          alert(`Total Percentage is ${total} %`);
        }
      } else {
        alert(`Total Percentage is ${total} %`);
      }
    }
  };

  const closeCaseAdd_CheckCase = () => {
    closeCaseAdd.click();
  };

  const submitHandlerAor = async (e) => {
    e.preventDefault();
    addAorForm.append("AdminID", localStorage.getItem("id"));
    addAorForm.append("AORName", aorName);
    addAorForm.append("CreatedBy", localStorage.getItem("UserId"));

    try {
      const response = await fetch(`${baseUrl}cases/AddAor`, {
        method: "POST",
        body: addAorForm,
        headers: {
          Authorization: `${localStorage.getItem("access_token")}`,
          Accept: "*/*",
        },
      });

      const data = await response.json();

      if (data && data.result.message == "Session Time Expire.") {
        toast.error("Token expired please login again");
        setTimeout(() => {
          navigate("/");
        }, 2000);
      }

      if (data && data.status === "Error") {
        toast.error(data && data.result.message);
      }

      if (data && data.result[0].message === "Success") {
        btnCancelAor.click();
        toast.success("AOR Added Successfully");
        viewAorApi();
      }
    } catch (error) {
      console.error(error);
    }
  };

  const handleAorChange = (e) => {
    if (e === "newadd") {
      aorClick.click();
      setAor("");
    } else {
      setAor(e);
    }
  };

  const handleEditAorChange = (e) => {
    if (e === "newadd") {
      aorClick.click();
      setEditAor("");
    } else {
      setEditAor(e);
    }
  };

  const handleCoCounselShareChange1 = (e) => {
    const value = parseInt(e.target.value);

    if (isNaN(value) || value < 0 || value > 50) {
      setIsValid(false);
      // toast.error("Please enter a valid number between 0 and 100.");
      return;
    }
    setEditCoCounselShare(value);
    // setIsValid(true)
    setEditCounselShare(50 - parseInt(value));
  };

  const handleCheck1 = () => {
    const num1 = parseInt(editcounselShare);
    const num2 = parseInt(editcoCounselShare);
    if (isNaN(num1) || isNaN(num2)) {
    } else {
      const sum = num1 + num2;
      if (sum === 50) {
        formData.append("CoCounselShare", coCounselShare);
        formData.append("CounselShare", counselShare);
      } else {
        toast.error("Sum is not 50.");
      }
    }
  };

  // -------------- HANDLE SUBMIT CASE STATUS ---------------
  const addJudgementStore = async (judgements, caseId) => {
    Swal({
      text: "Loading...",
      content: {
        element: "div",
        attributes: {
          innerHTML: `
              <div style="display: flex; align-items: center; justify-content: center;">
                <div class="spinner" style="
                  width: 30px;
                  height: 30px;
                  border: 4px solid rgba(0, 0, 0, 0.1);
                  border-left-color: #000;
                  border-radius: 50%;
                  animation: spin 1s linear infinite;
                "></div>
              </div>
            `,
        },
      },
      buttons: false,
      closeOnClickOutside: false,
    });
    const addJudgementStoreForm = new FormData();
    addJudgementStoreForm.append("Date", "");
    addJudgementStoreForm.append("CaseID", caseId);
    addJudgementStoreForm.append("CreatedBy", localStorage.getItem("UserId"));
    addJudgementStoreForm.append("AdminID", localStorage.getItem("id"));

    const pdfUrls = judgements.map((judgement) => ({
      order_type: judgement.order_type,
      order_date: judgement.order_date,
      pdf_link: judgement.pdf_link,
    }));
    addJudgementStoreForm.append("PdfUrl", JSON.stringify(pdfUrls));

    try {
      const response = await fetch(
        `${baseUrl}CaseStatusByGovWeb/downloadAndStore`,
        {
          method: "POST",
          headers: {
            Authorization: `${localStorage.getItem("access_token")}`,
            Accept: "*/*",
          },
          body: addJudgementStoreForm,
        }
      );

      const data = await response.json();
      Swal.close();
      if (data && data.status === "Success") {
        toast.success(data.message);
        setJudgements([]);
      } else {
        toast.error(data.message);
      }
    } catch (error) {
      console.error(error);
    }
  };

  // ----------- CASE ADD ---------
  const dataSave = async () => {
    setSaveButton(false);
    const filter =
      cocounselRow &&
      cocounselRow.filter(
        (item) => item.coCounselName !== "" && item.coCounselName !== "0"
      );

    const coCounselNameData =
      filter &&
      filter.map((coCounsel) =>
        coCounsel.coCounselName === "" ? "0" : coCounsel.coCounselName
      );
    const coCounselshareData =
      filter &&
      filter.map((coCounsel) =>
        coCounsel.coCounselShare === "" ? "0" : coCounsel.coCounselShare
      );

    const CounselName = [counsel, ...coCounselNameData];

    const CounselPer = [counselShare, ...coCounselshareData];

    const CounselType =
      CounselPer &&
      CounselPer.map((obj, index) => {
        return index === 0 ? 1 : 2;
      });

    formData.append("AdminID", localStorage.getItem("id"));
    formData.append("CreatedBy", localStorage.getItem("UserId"));
    formData.append("CourtID", court);
    formData.append("StateID", stateId);
    formData.append("CaseTypeID", type === "0" ? "" : type);
    formData.append("DistrictID", districtId);
    formData.append("CityID", cityId);
    formData.append("DiaryNo", diaryNo);
    formData.append("DiaryYear", diaryYear);
    formData.append("CaseNo", caseNo);
    formData.append("CaseYear", caseYear);
    formData.append("ClientID", client);
    formData.append("CaseName", title.toUpperCase());
    formData.append("CaseSummary", summary.toUpperCase());
    formData.append("Petitioners", patitioners.toUpperCase());
    formData.append("Intervenors", intervenors.toUpperCase());
    formData.append("CounselID", CounselName);
    formData.append("CounselPer", CounselPer);
    formData.append("CounselType", CounselType);
    formData.append("FixedFees", fixedFees);
    formData.append("AppearanceFee", appearanceFee);
    formData.append("AOR", aor.toUpperCase());
    formData.append("Respondants", respondants.toUpperCase());
    formData.append("RetainedFor", retaineed);
    formData.append("CoCounselID", coCounsel);
    formData.append("Expenses", expenses);
    formData.append("NonAppearanceFee", nonAppearanceFee);
    formData.append("Date", filingDate);
    formData.append("CityCourtID", 1);
    formData.append("FilingDate", filingDate);
    // formData.append("VillageID", villageID);
    formData.append("CompanyPer", compantPrecentage);
    formData.append("BranchCity", branchCity);

    try {
      const response = await fetch(`${baseUrl}Cases/AddCase`, {
        method: "POST",
        body: formData,
        headers: {
          Authorization: `${localStorage.getItem("access_token")}`,
          Accept: "*/*",
        },
      });
      const data = await response.json();
      if (data && data.result.message == "Session Time Expire.") {
        toast.error("Token expired please login again");
        setTimeout(() => {
          closeButton.click();
          navigate("/");
        }, 1000);
      }

      if (data.result.length && data.result[0].message === "Success") {
        toast.success("Case added successfully");
        handleClose();
        closeButton.click();
        filterActive ? filterApi() : getCaseView();
        setCourt("");
        setStateId("");
        setType("");
        setDistrictId("");
        setCityId("");
        setDiaryNo("");
        setDiaryYear("");
        setCaseNo("");
        setCaseYear("");
        setClient("");
        setTitle1("");
        setTitle2("");
        setTitle("");
        setSummary("");
        setPatitioners("");
        setIntervenors("");
        setCounsel("");
        setCocounselRow([{ coCounselName: "", coCounselShare: "" }]);
        setCounselShare("");
        setFixedFees("");
        setAppearanceFee("");
        setAor("");
        setTotalCoCounsel("0");
        setRespondants("");
        setRetaineed("");
        setCoCounsel("");
        setCoCounselShare("");
        setExpenses("");
        setNonAppearanceFee("");
        setFilingdate("");
        setFileNo("");
        setSaveButton(true);
        setBranchCity("");

        if (judgements && judgements.length) {
          Swal({
            text: "Do you want to add event ?",
            icon: "info",
            buttons: {
              cancel: {
                text: "No",
                value: null,
                visible: true,
                className: "",
                closeModal: true,
              },
              confirm: {
                text: "Yes",
                value: true,
                visible: true,
                className: "",
                closeModal: true,
              },
            },
            dangerMode: true,
            closeOnClickOutside: false,
          }).then((willAdd) => {
            if (willAdd) {
              addJudgementStore(judgements, data.result[0].CaseID);
            }
          });
        }
      } else {
        toast.error(data.result.message);
        setSaveButton(true);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const sweetAlert = () => {
    Swal({
      title: "Are you sure ?",
      text: "We have identified a duplicate record based on the combination of caseYear, Court Name ,caseType , clientName  and caseNo. Are you certain you wish to proceed with adding this duplicate record ?",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then((willDelete) => {
      if (willDelete) {
        dataSave();
      } else {
        Swal("Case not added !");
      }
    });
  };

  const duplicateCase = async () => {
    duplicateCaseForm.append("CaseYear", caseYear);
    duplicateCaseForm.append("CaseNo", caseNo);
    duplicateCaseForm.append("CourtID", court);
    duplicateCaseForm.append("CaseTypeID", type);
    duplicateCaseForm.append("ClientID", client);
    try {
      const response = await fetch(`${baseUrl}Cases/IsDuplicateCase`, {
        method: "POST",
        body: duplicateCaseForm,
        headers: {
          Authorization: `${localStorage.getItem("access_token")}`,
          Accept: "*/*",
        },
      });

      const data = await response.json();

      if (data && data.result[0].IsDuplicateCase === "1") {
        sweetAlert();
      } else {
        dataSave();
      }
    } catch (error) {
      console.error(error);
    }
  };

  //------------ SUBMIT CASE ----------

  const submitHandler = async (event) => {
    event.preventDefault();
    duplicateCase();
  };

  // ----------- GET CAPTCHA CODE ----------

  const getCaptchaCode = async () => {
    try {
      const response = await fetch(
        `${baseUrl}CaseStatusByGovWeb/GetCsrfToken`,
        {
          method: "POST",
        }
      );
      const data = await response.json();

      if (data && data.status === "Success") {
        setCsrfToken(data.CsrfToken);
        setCaptchaPath(data.CaptchaPath);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const formatRespondents = (respondentsString) => {
    // Regular expression to match each respondent
    const respondentPattern = /\s*(\d+)\s+([A-Z\s@]+(?:\(DEAD\)|))/g;
    let match;
    const respondentsArray = [];

    while ((match = respondentPattern.exec(respondentsString)) !== null) {
      const number = match[1];
      const name = match[2].trim();
      const details = respondentsString
        .substring(match.index + match[0].length)
        .split(/\d+\s+/)[0]
        .trim();
      respondentsArray.push({ number, name, details });
    }

    return respondentsArray.map(
      (respondent) =>
        `${respondent.number}. ${respondent.name}: ${respondent.details}`
    );
  };

  // -------------- HANDLE SUBMIT CASE STATUS ---------------
  const handleCaseStatusSubmit = async (e) => {
    e.preventDefault();
    Swal({
      text: "Loading...",
      content: {
        element: "div",
        attributes: {
          innerHTML: `
                  <div style="display: flex; align-items: center; justify-content: center;">
                    <div class="spinner" style="
                      width: 30px;
                      height: 30px;
                      border: 4px solid rgba(0, 0, 0, 0.1);
                      border-left-color: #000;
                      border-radius: 50%;
                      animation: spin 1s linear infinite;
                    "></div>
                  </div>
                `,
        },
      },
      buttons: false,
      closeOnClickOutside: false,
    });
    const caseStatusForm = new FormData();
    caseStatusForm.append("CourtID", caseStatusCourtID);
    caseStatusForm.append("CaseType", caseStatusCaseType);
    caseStatusForm.append("CaseNumber", caseStatusCaseNumber);
    caseStatusForm.append("CaptchaCode", caseStatusCaptchaCode);
    caseStatusForm.append("CaseYear", caseStatusCaseYear);
    caseStatusForm.append("CsrfToken", csrfToken);

    try {
      const response = await fetch(
        `${baseUrl}CaseStatusByGovWeb/fetch_case_status`,
        {
          method: "POST",
          headers: {
            Authorization: `${localStorage.getItem("access_token")}`,
            Accept: "*/*",
          },
          body: caseStatusForm,
        }
      );

      const data = await response.json();
      Swal.close();
      if (data && data.result.message == "Session Time Expire.") {
        toast.error("Token expired please login again");
        navigate("/");
      }

      if (data && data.status === "Success") {
        if (data && data.result.petitioners) {
          toast.success("Success !");
          getCaptchaCode();
          setCaseStatusCaseYear("");
          setCaseStatusCaptchaCode("");
          setCaseStatusCaseNumber("");
          setCaseStatusCaseType("");
          setCaseStatusCourtID("");
          setCaptchaPath("");
          setDistrictId(data.result.CourtCityID);
          setStateId("20");
          setCaseYear(data.result.CaseYear);
          setCaseNo(data.result.CaseNumber);
          setCourt("2");
          setCsrfToken("");
          setTitle1(
            data.result.petitioners &&
              data.result.petitioners.split("2")[0].split("1")[1]
          );
          setTitle2(
            data.result.respondent &&
              data.result.respondent.split("2")[0].split("1")[1]
          );
          setcaseTypeID(data.result.caseTypeID);
          setPatitioners(formatRespondents(data.result.petitioners).join("\n"));
          setRespondants(formatRespondents(data.result.respondent).join("\n"));
          setJudgements(data.result.judgements);
          closeCaseStatusForm.click();
          openCaseAddForm.click();
        } else {
          setcaseTypeID(data.result.caseTypeID);
          if (
            data.result.registration_number === "Registration Number not found."
          ) {
            setregistration_number("");
          } else {
            setregistration_number(data.result.registration_number);
          }
          toast.error("Data not found");
        }
      } else {
        toast.error(data.message);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const handleReset = () => {
    setCaseStatusCourtID("");
    setCaseStatusCaseType("");
    setCaseStatusCaseNumber("");
    setCaseStatusCaptchaCode("");
    setCaseStatusCaseYear("");
    getCaptchaCode();
    setregistration_number("");
    setcaseTypeID("");
  };
  //------------ EDIT CASE ----------

  const editDataSave = async () => {
    const filter =
      cocounselRow &&
      cocounselRow.filter(
        (item) => item.coCounselName !== "" && item.coCounselName !== "0"
      );

    const coCounselNameData =
      filter &&
      filter.map((coCounsel) =>
        coCounsel.coCounselName === "" ? "0" : coCounsel.coCounselName
      );

    const coCounselshareData =
      filter &&
      filter.map((coCounsel) =>
        coCounsel.coCounselShare === "" ? "0" : coCounsel.coCounselShare
      );

    const CounselName = [editcounsel, ...coCounselNameData];

    const CounselPer = [editcounselShare, ...coCounselshareData];

    const CounselType =
      CounselPer &&
      CounselPer.map((obj, index) => {
        return index === 0 ? 1 : 2;
      });

    editCaseLead.append("ID", editId);
    editCaseLead.append("AdminID", localStorage.getItem("id"));
    editCaseLead.append("UpdatedBy", localStorage.getItem("UserId"));
    editCaseLead.append("CourtID", court);
    editCaseLead.append("StateID", stateId);
    editCaseLead.append("CaseTypeID", type === "0" ? "" : type);
    editCaseLead.append("DistrictID", districtId);
    editCaseLead.append("CityID", cityId);
    editCaseLead.append("DiaryNo", editdiaryNo);
    editCaseLead.append("DiaryYear", editdiaryYear);
    editCaseLead.append("CaseNo", editcaseNo);
    editCaseLead.append("CaseYear", editcaseYear);
    editCaseLead.append("CounselID", CounselName);
    editCaseLead.append("CounselPer", CounselPer);
    editCaseLead.append("CounselType", CounselType);
    editCaseLead.append("ClientID", editclient);
    editCaseLead.append("CaseName", edittitle.toUpperCase());
    editCaseLead.append("CaseSummary", editsummary.toUpperCase());
    editCaseLead.append("Petitioners", editpatitioners.toUpperCase());
    editCaseLead.append("Intervenors", editintervenors.toUpperCase());
    editCaseLead.append("FixedFees", editfixedFees);
    editCaseLead.append("AppearanceFee", editappearanceFee);
    editCaseLead.append("AOR", editaor.toUpperCase());
    editCaseLead.append("Respondants", editrespondants.toUpperCase());
    editCaseLead.append("RetainedFor", editretaineed);
    editCaseLead.append("Expenses", editexpenses);
    editCaseLead.append("NonAppearanceFee", editnonAppearanceFee);
    editCaseLead.append("FilingDate", editfilingDate);
    // editCaseLead.append("VillageID", villageID);
    editCaseLead.append("IsOpen", isOpen);
    editCaseLead.append("ClosingDate", closingDate);
    editCaseLead.append("BranchCity", editBranchCity);

    try {
      const response = await fetch(`${baseUrl}Cases/EditCaseRecords`, {
        method: "POST",
        body: editCaseLead,
        headers: {
          Authorization: `${localStorage.getItem("access_token")}`,
          Accept: "*/*",
        },
      });
      const data = await response.json();

      if (data && data.result.message === "Session Time Expire.") {
        toast.error("Token expired please login again");
        setTimeout(() => {
          closeEdit.click();
          navigate("/");
        }, 1000);
      }

      if (data.result.length && data.result[0].message === "Success") {
        toast.success(" Updated successfully");
        setTitle("");
        setTitle1("");
        setTitle2("");
        setCocounselRow([{ coCounselName: "", coCounselShare: "" }]);
        closeEdit.click();
        filterActive ? filterApi() : getCaseView();
      } else {
        toast.error(data.result.message);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const editsweetAlert = () => {
    Swal({
      title: "Are you sure ?",
      text: "We have identified a duplicate record based on the combination of Case year, Court name , Case type , Client name  and Case No. Are you certain you wish to proceed with adding this duplicate record ?",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then((willDelete) => {
      if (willDelete) {
        editDataSave();
      } else {
        Swal("Case not added !");
      }
    });
  };

  const editDuplicateCase = async () => {
    duplicateCaseForm.append("CaseYear", editcaseYear);
    duplicateCaseForm.append("CaseNo", editcaseNo);
    duplicateCaseForm.append("CourtID", court);
    duplicateCaseForm.append("CaseTypeID", type);
    duplicateCaseForm.append("ClientID", editclient);
    try {
      const response = await fetch(`${baseUrl}Cases/IsDuplicateCase`, {
        method: "POST",
        body: duplicateCaseForm,
        headers: {
          Authorization: `${localStorage.getItem("access_token")}`,
          Accept: "*/*",
        },
      });

      const data = await response.json();

      if (data && data.result[0].IsDuplicateCase === "1") {
        editsweetAlert();
      } else {
        editDataSave();
      }
    } catch (error) {
      console.error(error);
    }
  };

  const submitEditHandler = async (event) => {
    event.preventDefault();
    editDuplicateCase();
  };

  //------------ CASE TABLE ----------

  const columnHelper = createMRTColumnHelper();

  const columns = [
    columnHelper.accessor("name", {
      enableColumnFilter: false,
      enableColumnActions: false,
      enableSorting: false,
      header: "Action",
      Cell: (row) => {
        return (
          <>
            <div style={{ display: "flex", gap: "15px" }}>
              {isEdit !== "0" && (
                <span
                  type="button"
                  data-toggle="modal"
                  data-target="#exampleModalLongEdit"
                  onClick={() => getById(row.row.original.Id)}
                >
                  <img
                    src="assets/img/icons/edit.svg"
                    alt="Edit"
                    title="Edit"
                  />
                </span>
              )}

              <span
                type="button"
                data-toggle="modal"
                data-target="#exampleModalLongDetail"
                onClick={() => details(row.row.original.Id)}
              >
                <img
                  src="assets/img/icons/eye.svg"
                  alt="Details"
                  title="Details"
                />
              </span>
              <span
                type="button"
                data-toggle="modal"
                data-target="#exampleModalLongDetail"
                onClick={() => details(row.row.original.Id)}
              >
                <img
                  src="assets/img/icons/send.svg"
                  alt="Email"
                  title="Send Notification"
                />
              </span>
              <span>
                <Link
                  target="_blank"
                  to={`/View_Case_PDF/${row.row.original.Id}`}
                  title="PDF"
                >
                  <img
                    src="assets/img/icons/pdf.svg"
                    className="pdf"
                    title="PDF"
                  />
                </Link>
              </span>
              {isDelete !== "0" && (
                <span
                  data-bs-toggle="modal"
                  data-bs-target="#exampleModal"
                  style={{ cursor: "pointer" }}
                  onClick={() => setDelId(row.row.original.Id)}
                >
                  <img
                    src="assets/img/icons/delete.svg"
                    alt="Delete"
                    title="Delete"
                  />
                </span>
              )}
            </div>
          </>
        );
      },
    }),

    columnHelper.accessor("FileNo", {
      minSize: "10px",
      header: "File No",
    }),

    columnHelper.accessor("CaseType", {
      header: "Case Type",
      minSize: "10px",
      Cell: (row) => {
        return (
          <div className="upperCase">
            {row.row.original.CaseType === null ||
            row.row.original.CaseType === ""
              ? "-"
              : row.row.original.CaseType}
          </div>
        );
      },
    }),

    columnHelper.accessor("CaseNo", {
      minSize: "10px",
      header: "Case No.",
      Cell: (row) => {
        return (
          <div>
            {row.row.original.CaseNo === null || row.row.original.CaseNo === ""
              ? "-"
              : row.row.original.CaseNo}
          </div>
        );
      },
    }),

    columnHelper.accessor("CaseYear", {
      minSize: "10px",
      header: "Case Year",
      Cell: (row) => {
        return (
          <div>
            {row.row.original.CaseYear === null ||
            row.row.original.CaseYear === "0000"
              ? "-"
              : row.row.original.CaseYear}
          </div>
        );
      },
    }),

    columnHelper.accessor("CaseName", {
      header: "Case Name",
      Cell: (row) => {
        return (
          <div style={{ minWidth: "350px" }} className="upperCase">
            <a
              title="Case Details"
              href={`#/view-casedetails/${row.row.original.Id}`}
              className="visuald_case"
              target="_blank"
              style={{ cursor: "pointer" }}
            >
              {row.row.original.CaseName}
            </a>
          </div>
        );
      },
    }),

    columnHelper.accessor("CourtName", {
      header: "Court Name",
      Cell: (row) => {
        return (
          <div className="upperCase">
            {row.row.original.CourtName === null ||
            row.row.original.CourtName === ""
              ? "-"
              : row.row.original.CourtName}
          </div>
        );
      },
    }),

    columnHelper.accessor("state_title", {
      header: "State",
      Cell: (row) => {
        return (
          <div className="upperCase">
            {row.row.original.state_title === null ||
            row.row.original.state_title === ""
              ? "-"
              : row.row.original.state_title}
          </div>
        );
      },
    }),
    columnHelper.accessor("district_title", {
      header: "District",
      Cell: (row) => {
        return (
          <div className="upperCase">
            {row.row.original.district_title === null ||
            row.row.original.district_title === ""
              ? "-"
              : row.row.original.district_title}
          </div>
        );
      },
    }),
    columnHelper.accessor("city_title", {
      header: "Tehsil",
      Cell: (row) => {
        return (
          <div className="upperCase">
            {row.row.original.city_title === null ||
            row.row.original.city_title === ""
              ? "-"
              : row.row.original.city_title}
          </div>
        );
      },
    }),
    columnHelper.accessor("BranchCity", {
      header: "Branch City",
      Cell: (row) => {
        return (
          <div className="upperCase">
            {row.row.original.BranchCity === null ||
            row.row.original.BranchCity === ""
              ? "-"
              : row.row.original.BranchCity}
          </div>
        );
      },
    }),

    columnHelper.accessor("DiaryNo", {
      minSize: "10px",
      header: "Diary No",
      Cell: (row) => {
        return (
          <div>
            {row.row.original.DiaryNo === null ||
            row.row.original.DiaryNo === ""
              ? "-"
              : row.row.original.DiaryNo}
          </div>
        );
      },
    }),

    columnHelper.accessor("DiaryYear", {
      minSize: "10px",
      header: "Diary Year",
      Cell: (row) => {
        return (
          <div>
            {row.row.original.DiaryYear === null ||
            row.row.original.DiaryYear === "" ||
            row.row.original.DiaryYear === "0000"
              ? "-"
              : row.row.original.DiaryYear}
          </div>
        );
      },
    }),
    columnHelper.accessor("FixedFees", {
      header: "Fixed Fees",
      Cell: (row) => {
        return (
          <div>
            {row.row.original.FixedFees === null ||
            row.row.original.FixedFees === ""
              ? "-"
              : row.row.original.FixedFees}
          </div>
        );
      },
    }),
    columnHelper.accessor("Expenses", {
      minSize: "10px",
      header: "Expenses",
      Cell: (row) => {
        return (
          <div>
            {row.row.original.Expenses === ""
              ? "-"
              : row.row.original.Expenses === "1"
              ? "INCLUSIVE"
              : "EXCLUSIVE"}
          </div>
        );
      },
    }),
    columnHelper.accessor("AppearanceFee", {
      header: "Appearance Fee",
      Cell: (row) => {
        return (
          <div>
            {row.row.original.AppearanceFee === null ||
            row.row.original.AppearanceFee === ""
              ? "-"
              : row.row.original.AppearanceFee}
          </div>
        );
      },
    }),
    columnHelper.accessor("NonAppearanceFee", {
      header: "Non Appearance Fee",
      Cell: (row) => {
        return (
          <div>
            {row.row.original.NonAppearanceFee === null ||
            row.row.original.NonAppearanceFee === ""
              ? "-"
              : row.row.original.NonAppearanceFee}
          </div>
        );
      },
    }),
    columnHelper.accessor("AOR", {
      header: "AOR",
      Cell: (row) => {
        return (
          <div className="upperCase">
            {row.row.original.AOR === null || row.row.original.AOR === ""
              ? "-"
              : row.row.original.AOR}
          </div>
        );
      },
    }),

    columnHelper.accessor("FilingDate", {
      minSize: "10px",
      header: "Filing Date",
      Cell: (row) => {
        return (
          <div>
            {row.row.original.FilingDate === null ||
            row.row.original.FilingDate === "0000-00-00"
              ? "-"
              : formatDate1(row.row.original.FilingDate)}
          </div>
        );
      },
    }),

    columnHelper.accessor("Counsel", {
      header: "Counsel",
      Cell: (row) => {
        return (
          <div className="upperCase">
            {row.row.original.Counsel === null ||
            row.row.original.Counsel === ""
              ? "-"
              : row.row.original.Counsel}
          </div>
        );
      },
    }),
    columnHelper.accessor("Cocounsel", {
      header: "Co-counsel",
      Cell: (row) => {
        return (
          <div className="upperCase">
            {row.row.original.Cocounsel === null ||
            row.row.original.Cocounsel === ""
              ? "-"
              : row.row.original.Cocounsel}
          </div>
        );
      },
    }),
    columnHelper.accessor("ClientName", {
      header: "Client",
      Cell: (row) => {
        return (
          <div className="upperCase">
            {row.row.original.ClientName}
            <br />
            {row.row.original.MobileNo}
          </div>
        );
      },
    }),

    columnHelper.accessor("Petitioners", {
      header: "Petitioners",
      Cell: (row) => {
        return (
          <div className="upperCase">
            {row.row.original.Petitioners === null ||
            row.row.original.Petitioners === ""
              ? "-"
              : row.row.original.Petitioners}
          </div>
        );
      },
    }),

    columnHelper.accessor("Respondants", {
      header: "Respondants",
      Cell: (row) => {
        return (
          <div className="upperCase">
            {row.row.original.Respondants === null ||
            row.row.original.Respondants === ""
              ? "-"
              : row.row.original.Respondants}
          </div>
        );
      },
    }),

    columnHelper.accessor("Intervenors", {
      header: "Intervenors",
      Cell: (row) => {
        return (
          <div className="upperCase">
            {row.row.original.Intervenors === null ||
            row.row.original.Intervenors === ""
              ? "-"
              : row.row.original.Intervenors}
          </div>
        );
      },
    }),

    columnHelper.accessor("RetainedFor", {
      header: "Retained For",
      Cell: (row) => {
        return (
          <div className="upperCase">
            {row.row.original.RetainedFor === null ||
            row.row.original.RetainedFor === ""
              ? "-"
              : row.row.original.RetainedFor}
          </div>
        );
      },
    }),

    columnHelper.accessor("CaseSummary", {
      header: "Case Summary",
      Cell: (row) => {
        return (
          <div className="upperCase">
            {row.row.original.CaseSummary === null ||
            row.row.original.CaseSummary === ""
              ? "-"
              : row.row.original.CaseSummary}
          </div>
        );
      },
    }),

    columnHelper.accessor("IsOpen", {
      minSize: "10px",
      header: "Is Open",
      Cell: (row) => {
        return <div>{row.row.original.IsOpen}</div>;
      },
    }),

    columnHelper.accessor("ClosingDate", {
      minSize: "10px",
      header: "Closing Date",
      Cell: (row) => {
        return (
          <div>
            {row.row.original.ClosingDate === null ||
            row.row.original.ClosingDate === "0000-00-00"
              ? "-"
              : formatDate1(row.row.original.ClosingDate)}
          </div>
        );
      },
    }),

    columnHelper.accessor("Status", {
      header: "Status",
      Cell: (row) => {
        return (
          <div>{row.row.original.Status === "1" ? "ACTIVE" : "DEACTIVE"}</div>
        );
      },
    }),

    columnHelper.accessor("UpdatedBy", {
      header: "Last-Modified",
      Cell: (row) => {
        return (
          <div className="upperCase">
            {row.row.original.UpdatedBy === null ||
            row.row.original.UpdatedBy === ""
              ? "-"
              : row.row.original.UpdatedBy}
            <br />
            {formateDateTime(row.row.original.UpdatedDate)}
          </div>
        );
      },
    }),

    columnHelper.accessor("AddedBy", {
      header: "Added By",
      Cell: (row) => {
        return (
          <div className="upperCase">
            {row.row.original.AddedBy === null ||
            row.row.original.AddedBy === ""
              ? "-"
              : row.row.original.AddedBy}
            <br />
            {formateDateTime(row.row.original.CreatedDate)}
          </div>
        );
      },
    }),
  ];

  const csvConfig = mkConfig({
    fieldSeparator: ",",
    decimalSeparator: ".",
    useKeysAsHeaders: true,
  });

  useEffect(() => {
    setTitle(title1 + " " + "VS" + " " + title2);
  }, [title1, title2]);

  //-------- TITLE ------
  const handleTitle1 = (e) => {
    setTitle1(e.target.value);
    setPatitioners(e.target.value);
    setTitle(e.target.value + " " + "VS" + " " + title2);
  };

  const handleEditTitle1 = (e) => {
    setTitle1(e.target.value);
    setEditTitle(e.target.value + " " + "VS" + " " + title2);
  };

  const handleTitle2 = (e) => {
    setTitle2(e.target.value);
    setRespondants(e.target.value);
    setTitle(title1 + " " + "VS" + " " + e.target.value);
  };

  const handleEditTitle2 = (e) => {
    setTitle2(e.target.value);
    setEditTitle(title1 + " " + "VS" + " " + e.target.value);
  };

  const handlePatitioners = (e) => {
    setPatitioners(e.target.value);
  };

  const handleRespondants = (e) => {
    setRespondants(e.target.value);
  };

  //------------ VIEW CITY ----------

  const getState = async () => {
    try {
      const response = await fetch(`${baseUrl}Master/ViewState`, {
        method: "POST",
        headers: {
          Authorization: `${localStorage.getItem("access_token")}`,
          Accept: "*/*",
        },
      });
      const data = await response.json();
      if (data && data.result.message == "Session Time Expire.") {
        toast.error("Token expired please login again");
        setTimeout(() => {
          navigate("/");
        }, 3000);
      }
      setState(data.result);
    } catch (error) {
      console.error(error);
    }
  };

  //------------ VIEW CITY ----------

  const getDistrict = async () => {
    districtForm.append("state_id", stateId);
    try {
      const response = await fetch(`${baseUrl}Master/ViewDistricByStateID`, {
        method: "POST",
        body: districtForm,
        headers: {
          Authorization: `${localStorage.getItem("access_token")}`,
          Accept: "*/*",
        },
      });
      const data = await response.json();
      if (data && data.result.message == "Session Time Expire.") {
        toast.error("Token expired please login again");
        setTimeout(() => {
          navigate("/");
        }, 3000);
      }
      setDistrict(data.result);
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    if (editcounsel === "") {
      setEditCounsel("");
      setViewUserCoCounselData([]);
    } else {
      const data =
        viewUserData && viewUserData.filter((item) => item.Id !== editcounsel);
      setViewUserCoCounselData(data);
    }
  }, [editcounsel]);

  // ------------------Add Client----------------
  const formCData = new FormData();
  const [FirstName, setFirstName] = useState("");
  const [LastName, setLastName] = useState("");
  const [CompanyName, setCompanyName] = useState("");
  const [Designation, setDesignation] = useState("");
  const [HouseNo, setHouseNo] = useState("");
  const [MobileNo, setMobileNo] = useState("");
  const [Phone, setPhone] = useState("");
  const [Email, setEmail] = useState("");
  const [CityID, setCityID] = useState([]);
  const [Address, setAddress] = useState("");
  const [PinCode, setPinCode] = useState("");
  const [Source, setSource] = useState("");
  const [ReferredByClientID, setReferredByClientID] = useState("");
  const [ReferredByCounselID, setReferredByCounselID] = useState("");
  const [CountryID, setCountryID] = useState("1");
  const [StateID, setStateID] = useState([]);
  const [cityData, setCityData] = useState([]);
  const handleInputChange = (e) => {
    const value = e.target.value;
    const isValidInput = /^\d{10}$/.test(value);

    setMobileNo(value);
    setIsValid(isValidInput);
  };

  const handleInputChange2 = (e) => {
    const value = e.target.value;
    const isValidInput = /^\d{10}$/.test(value);

    setPhone(value);
    setIsValid(isValidInput);
  };

  // ----------- VIEW User/Counsel---------

  const ViewUserData = new FormData();
  const [optionsUser, setOptionsUser] = useState([]);

  const [countriesUser, setCountriesUser] = useState([]);

  const getCountriesUser = async () => {
    ViewUserData.append("AdminID", localStorage.getItem("id"));
    ViewUserData.append("UserID", localStorage.getItem("uId"));
    try {
      const response = await fetch(`${baseUrl}Admin/ViewUser`, {
        method: "POST",

        headers: {
          Authorization: `${localStorage.getItem("access_token")}`,
          Accept: "*/*",
        },
        body: ViewUserData,
      });

      const data = await response.json();
      if (data && data.result.message == "Session Time Expire.") {
        toast.error("Token expired please login again");
        navigate("/");
        window.location.reload();
      }

      setCountriesUser(data && data.result);

      const clientData =
        data && data.result.advocateUser.filter((item) => item.Status === "1");

      const Mapped =
        clientData &&
        clientData.map((item) => ({
          value: item.Id,
          label: item.Name,
        }));
      setOptionsUser(Mapped);
      if (data.response_code === 400) {
        toast.error("Token expired please login again");
      }
    } catch (error) {
      console.error(error);
    }
  };

  // ----------- VIEW Year---------
  const ViewYearData = new FormData();
  const [optionsYear, setOptionYear] = useState([]);
  ViewYearData.append("AdminID", localStorage.getItem("id"));
  const getYear = async () => {
    try {
      const response = await fetch(`${baseUrl}Master/ViewYear`, {
        method: "POST",

        headers: {
          Authorization: `${localStorage.getItem("access_token")}`,
          Accept: "*/*",
        },
        body: ViewYearData,
      });

      const data = await response.json();
      if (data && data.result.message == "Session Time Expire.") {
        toast.error("Token expired please login again");
        navigate("/");
        window.location.reload();
      }

      const y_data = data && data.result.filter((item) => item.Status === "1");

      const Mapped =
        y_data &&
        y_data.map((item) => ({
          value: item.Year,
          label: item.Year,
        }));
      setOptionYear(Mapped);
      if (data.response_code === 400) {
        toast.error("Token expired please login again");
      }
    } catch (error) {
      console.error(error);
    }
  };

  // ----------- VIEW Client---------
  const ViewData = new FormData();
  const [options, setOptions] = useState([]);

  ViewData.append("AdminID", localStorage.getItem("id"));

  const getCountries = async () => {
    try {
      const response = await fetch(`${baseUrl}Client/ViewClient`, {
        method: "POST",

        headers: {
          Authorization: `${localStorage.getItem("access_token")}`,
          Accept: "*/*",
        },
        body: ViewData,
      });

      const data = await response.json();
      if (data && data.result.message == "Session Time Expire.") {
        toast.error("Token expired please login again");
        navigate("/");
        window.location.reload();
      }

      const clientData =
        data && data.result.filter((item) => item.Status === "1");

      const Mapped =
        clientData &&
        clientData.map((item) => ({
          value: item.Id,
          label: `${item.FirstName} ${item.LastName}`,
        }));
      setOptions(Mapped);
      if (data.response_code === 400) {
        toast.error("Token expired please login again");
      }
    } catch (error) {
      console.error(error);
    }
  };

  const HandleCSubmit = async (e) => {
    e.preventDefault();
    formCData.append("AdminID", localStorage.getItem("id"));
    formCData.append("FirstName", FirstName.toUpperCase());
    formCData.append("LastName", LastName.toUpperCase());
    formCData.append("CompanyName", CompanyName);
    formCData.append("Designation", Designation);
    formCData.append("HouseNo", HouseNo);
    if (MobileNo.length === 10) {
      formCData.append("MobileNo", MobileNo);
    } else {
      toast.error("Invalid Mobile No");
    }
    formCData.append("Phone", Phone);
    formCData.append("Email", Email);
    formCData.append("IsLead", selectedOption);
    formCData.append("CityID", cityId);
    formCData.append("Address", Address);
    formCData.append("PinCode", PinCode);
    formCData.append("Source", Source);
    formCData.append("ReferredByClientID", ReferredByClientID);
    formCData.append("ReferredByCounselID", ReferredByCounselID);
    formCData.append("CreatedBy", localStorage.getItem("UserId"));
    formCData.append("CountryID", CountryID);
    formCData.append("StateID", stateId);
    formCData.append("DistrictID", districtId);
    formCData.append("Comments", text);

    // Iterate through the fields and update empty or undefined fields
    for (let pair of formCData.entries()) {
      let fieldName = pair[0];
      let fieldValue = pair[1];

      if (fieldValue === "undefined" || fieldValue === "") {
        // Set the field value to three spaces
        formCData.set(fieldName, "");
      }
    }

    try {
      const response = await fetch(`${baseUrl}Client/AddClient`, {
        method: "POST",

        headers: {
          Authorization: `${localStorage.getItem("access_token")}`,
          Accept: "*/*",
        },
        body: formCData,
      });

      const data = await response.json();
      if (data && data.result.message == "Session Time Expire.") {
        toast.error("Token expired please login again");
        navigate("/");
        window.location.reload();
      }
      if (data.result[0].code === "200") {
        handleClickback();
        clearForm();
        toast.success("Client Added !");
        getCountries();
        getClient();
      } else {
        toast.error(data.result[0].message);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const handleCounselChange = (selectedOption) => {
    if (selectedOption === "") {
      setCounsel("0");
      setCoCounselData([]);
    } else {
      setCounsel(selectedOption);
      const data =
        optionsUser &&
        optionsUser.filter((item) => item.value !== selectedOption);

      const sortData =
        data &&
        data.sort((a, b) => {
          const nameA = a.label.toUpperCase();
          const nameB = b.label.toUpperCase();

          if (nameA < nameB) {
            return -1;
          }
          if (nameA > nameB) {
            return 1;
          }
          return 0;
        });
      setCoCounselData(sortData);
    }
  };

  const handleSelectChange = (selectedOption) => {
    if (selectedOption === null) {
      setClient(null);
    } else {
      setClient(selectedOption.value);
    }
  };

  useEffect(() => {}, []);
  function clearForm() {
    var fields = document.getElementsByClassName("name");
    for (var i = 0; i < fields.length; i++) {
      fields[i].value = "";
    }
  }

  const [selectedOption, setSelectedOption] = useState([]);

  const handleOptionChange = (event) => {
    setSelectedOption(event.target.value);
  };

  const handleOptionChange1 = (event) => {
    setSource(event.target.value);
  };

  const [text, setText] = useState("");

  const handleTextChange = (event) => {
    setText(event.target.value);
  };

  // dropdown

  const [State, setState1] = useState([]);
  const getState1 = async () => {
    try {
      const response = await fetch(`${baseUrl}Master/ViewState`, {
        method: "POST",

        headers: {
          Authorization: `${localStorage.getItem("access_token")}`,
          Accept: "*/*",
        },
      });

      const data2 = await response.json();
      if (data2 && data2.result.message == "Session Time Expire.") {
        toast.error("Token expired please login again");
        setTimeout(() => {
          navigate("/");
        }, 3000);
      }
      setState1(data2.result);
      setState(data2.result);
    } catch (error) {
      console.error(error);
    }
  };
  const district1 = new FormData();

  const [District, setDistrict1] = useState([]);
  const getDistrict1 = async () => {
    district1.append("state_id", StateID);
    try {
      const response = await fetch(`${baseUrl}Master/ViewDistricByStateID`, {
        method: "POST",
        body: district,
        headers: {
          Authorization: `${localStorage.getItem("access_token")}`,
          Accept: "*/*",
        },
      });

      const data3 = await response.json();
      if (data3 && data3.result.message == "Session Time Expire.") {
        toast.error("Token expired please login again");
        navigate("/");
        window.location.reload();
      }
      setDistrict1(data3.result);
      setDistrict(data3.result);
    } catch (error) {
      console.error(error);
    }
  };

  const [City, setCity1] = useState([]);
  const getCity1 = async () => {
    cityForm.append("district_id", CityID);

    try {
      const response = await fetch(`${baseUrl}Master/ViewCityByDistrictid`, {
        method: "POST",
        body: cityForm,
        headers: {
          Authorization: `${localStorage.getItem("access_token")}`,
          Accept: "*/*",
        },
      });

      const data1 = await response.json();
      if (data1 && data1.result.message == "Session Time Expire.") {
        toast.error("Token expired please login again");
        setTimeout(() => {
          navigate("/");
        }, 3000);
      }
      setCity1(data1.result);
      setCity(data1.result);
    } catch (error) {
      console.error(error);
    }
  };
  const handleStateChange1 = (e) => {
    setStateID(e.target.value);
    getDistrict1();
  };

  const handleDistrictChange1 = (e) => {
    setCityID(e.target.value);
    getCity1();
  };
  const handleCityChange = (e) => {
    setCityData(e.target.value);
  };
  useEffect(() => {
    getState1();
    getCity1();
    getDistrict1();
  }, [StateID, CityID]);

  //------------ VIEW CITY ----------

  const getCity = async () => {
    cityForm.append("district_id", districtId);
    try {
      const response = await fetch(`${baseUrl}Master/ViewCityByDistrictid`, {
        method: "POST",
        body: cityForm,
        headers: {
          Authorization: `${localStorage.getItem("access_token")}`,
          Accept: "*/*",
        },
      });
      const data = await response.json();

      setCity(data.result);
      if (data && data.result.message == "Session Time Expire.") {
        toast.error("Token expired please login again");
        setTimeout(() => {
          navigate("/");
        }, 3000);
      }
    } catch (error) {
      console.error(error);
    }
  };

  //------------ VIEW VILEGE ----------

  const getVillege = async () => {
    villegeForm.append("city_id", cityId);
    try {
      const response = await fetch(`${baseUrl}Master/ViewVillageByCityId`, {
        method: "POST",
        body: villegeForm,
        headers: {
          Authorization: `${localStorage.getItem("access_token")}`,
          Accept: "*/*",
        },
      });
      const data = await response.json();
      setVillage(data.result);
      if (data && data.result.message == "Session Time Expire.") {
        toast.error("Token expired please login again");
        setTimeout(() => {
          navigate("/");
        }, 3000);
      }
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    getDistrict();
    setCity(null);
  }, [stateId]);

  useEffect(() => {
    getCity();
    setVillage(null);
  }, [districtId]);

  useEffect(() => {
    getVillege();
  }, [cityId]);

  useEffect(() => {
    getCaseType();
  }, [court]);

  useEffect(() => {
    getState();
    getCity();
    getDistrict();
  }, [StateID, CityID]);
  //------------ Checking the share ----------
  useEffect(() => {
    if (counselShare !== "" && coCounselShare !== "") {
      handleCheck();
    }
  }, [counselShare, coCounselShare]);

  useEffect(() => {
    if (editcounselShare !== "" && editcoCounselShare !== "") {
      handleCheck1();
    }
  }, [editcounselShare, editcoCounselShare]);

  const handleFilingDate = (e) => {
    setFFilingDate(e.target.value);
    if (e.target.value !== "") {
      setIsFieldRequired(true);
    } else {
      setIsFieldRequired(false);
    }
  };

  useEffect(() => {
    if (localStorage.getItem("access_token") === null) {
      navigate("/");
    } else {
      window.scrollTo(0, 0);
      getCaseView();
      getState();
    }
  }, []);

  const excelData =
    data &&
    data.map((obj) => {
      const { Id, code, message, Status, AdminID, ...rest } = obj;
      return rest;
    });

  const handleExportData = () => {
    const csvConfig = mkConfig({
      fieldSeparator: ",",
      decimalSeparator: ".",
      useKeysAsHeaders: true,
    });
    const formattedDate = new Date().toISOString().slice(0, 10);
    const filename = `Case|LegalCRM__${formattedDate}.csv`;

    const csv = generateCsv(csvConfig)(excelData);
    const blob = new Blob([csv], { type: "text/csv" });
    const link = document.createElement("a");
    link.href = window.URL.createObjectURL(blob);
    link.download = filename;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  const handleExportRows = (rows) => {
    const doc = new jsPDF();
    const columnsToExport = [
      { id: "serialNo", header: "S No." },
      ...columns.filter((column) => selectedColumns.includes(column.id)),
    ];

    const tableHeaders = columnsToExport.map((c) => c.header);

    const formattedDate = new Date().toLocaleDateString("en-GB", {
      year: "numeric",
      month: "2-digit",
      day: "2-digit",
    });

    const filename = `Case|LegalCRM__${formattedDate}.pdf`;
    const addWatermarkToFirstPage = () => {
      const watermarkHeight = 20;

      // Calculate positioning to center the watermark on each page

      doc.setFontSize(10);
      doc.text(
        10,
        watermarkHeight + -10,
        `Generated by : ${localStorage.getItem("name")} `
      );
      doc.text(172, watermarkHeight + -10, `Date: ${formattedDate}`);
      doc.setFontSize(16);
      doc.text("Case Table", 84, 20);
    };
    const watermarkHeight = 20;

    addWatermarkToFirstPage();
    const tableData = rows.map((row, index) => {
      return [
        { content: (index + 1).toString(), styles: { fontStyle: "bold" } }, // Serial number
        ...columnsToExport.slice(1).map((column) => {
          // Exclude the first column (serial number) when mapping
          const value =
            column.id in row.original ? row.original[column.id] : "";
          return value !== null && value !== undefined ? value : "";
        }),
      ];
    });

    autoTable(doc, {
      head: [tableHeaders],
      body: tableData,
      startY: watermarkHeight + 5,
    });

    // Add a heading to the table

    // Save the PDF to a Blob
    const pdfBlob = doc.output("blob");

    // Create a blob URL
    const blobUrl = URL.createObjectURL(pdfBlob);

    // Open the URL in a new tab
    window.open(blobUrl, "_blank");

    // Optionally revoke the object URL after opening the new tab
    URL.revokeObjectURL(blobUrl);
    // doc.save(filename);
  };

  const table = useMaterialReactTable({
    columns,
    data: data && data,
    enableRowSelection: false,
    columnFilterDisplayMode: "popover",
    initialState: {
      columnVisibility: {
        DiaryNo: false,
        DiaryYear: false,
        AOR: false,
        Respondants: false,
        Petitioners: false,
        Intervenors: false,
        CaseSummary: false,
        RetainedFor: false,
        ClosingDate: false,
        Status: false,
        IsOpen: false,
        AddedBy: false,
        UpdatedDate: false,
        UpdatedBy: false,
        Expenses: false,
        AppearanceFee: false,
        FixedFees: false,
        NonAppearanceFee: false,
      },
    },
    state: {
      isLoading: loading,
    },
    muiCircularProgressProps: {
      color: "primary",
      thickness: 5,
      size: 55,
      style: {
        position: "absolute",
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%)",
        zIndex: 1000,
      },
    },
    muiSkeletonProps: {
      animation: "pulse",
      height: 10,
    },

    enableStickyHeader: true,
    enableRowNumbers: true,
    muiPaginationProps: {
      rowsPerPageOptions: ["25", "100", "500", "1000", "5000"],
      showFirstButton: true,
      showLastButton: true,
    },
    positionToolbarAlertBanner: "bottom",
    renderTopToolbarCustomActions: ({ table }) => (
      <Box
        sx={{
          display: "flex",
          gap: "16px",
          padding: "8px",
          flexWrap: "wrap",
        }}
      >
        <Button
          disabled={table.getPrePaginationRowModel().rows.length === 0}
          onClick={handleExportData}
          startIcon={<FileDownloadIcon />}
        >
          Export Excel
        </Button>

        <Button
          disabled={
            !table || table.getPrePaginationRowModel().rows.length === 0
          }
          onClick={handleExportButtonClick}
          startIcon={<FileDownloadIcon />}
        >
          Export PDF
        </Button>
        <Dialog open={open} onClose={handleClose1}>
          <DialogTitle>Select Columns To Export</DialogTitle>
          <DialogContent>
            {columns.map((column) =>
              column.header !== "Action" ? (
                <div key={column.id}>
                  <Checkbox
                    checked={selectedColumns.includes(column.id)}
                    onChange={() => handleToggleColumn(column.id)}
                  />
                  {column.header}
                </div>
              ) : (
                ""
              )
            )}
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose1}>Cancel</Button>
            <Button
              onClick={() =>
                handleExportRows(table.getPrePaginationRowModel().rows)
              }
            >
              Export
            </Button>
          </DialogActions>
        </Dialog>
      </Box>
    ),
  });

  return (
    <>
      <DocumentTitle title="CASE | LEGAL CRM">
        {isLoading ? (
          <Preloader />
        ) : (
          <div>
            <div className="wraper">
              <Header />
              <div className="mainWraper">
                <Sidebar />
                <div className="pageBody">
                  <div className="pageContent">
                    <div className="PageTitle">
                      <h3>Case List</h3>

                      <a
                        style={{
                          display: isAdd === "0" ? "none" : "block",
                        }}
                        type="button"
                        className="btn btn-primary btn_client headbtn openCaseAddForm"
                        data-toggle="modal"
                        data-target="#exampleModalLong"
                      >
                        Add Case <img src="assets/img/icons/add.svg" />
                      </a>
                    </div>

                    <div
                      className="filter_type add_wrap"
                      style={{
                        display: isView === "0" ? "none" : "block",
                      }}
                    >
                      <form role="form " onSubmit={filterApiSearch}>
                        <div className="caseMobile">
                          <div className="form_flex1 flex_wrap  caseMobile">
                            <div className="form-group1">
                              <label htmlFor="uname">Is Open</label>
                              <select
                                name="cars"
                                id="cars"
                                value={fIsOpen}
                                onChange={(e) => setFIsOpen(e.target.value)}
                              >
                                <option value="1">Open</option>
                                <option value="0">Close</option>
                                <option value="">All</option>
                              </select>
                            </div>

                            <div className="form-group1 zIndex1">
                              <label>File No.</label>

                              <Select
                                className="basic-single"
                                placeholder="Select "
                                classNamePrefix="select"
                                onChange={handleFilterCase}
                                isClearable={true}
                                isSearchable={true}
                                name="color"
                                options={optionsEvent}
                              />
                            </div>

                            <div className="form-group1 zIndex2">
                              <label>Client Name</label>
                              <Select
                                className="basic-single"
                                placeholder="Select "
                                classNamePrefix="select"
                                onChange={handleFilterClient}
                                isClearable={true}
                                isSearchable={true}
                                name="color"
                                options={fClientOption}
                              />
                            </div>

                            <div className="form-group1">
                              <label htmlFor="uname">Date</label>
                              <select
                                className="name"
                                name="cars"
                                id="cars"
                                value={fFilingDate}
                                onChange={handleFilingDate}
                              >
                                <option value="">Select</option>
                                <option value="1">Filing Date</option>
                                <option value="2">Created On</option>
                                <option value="3">Closing Date</option>
                              </select>
                            </div>

                            <div className="form-group1">
                              <label>From Date</label>
                              <input
                                required={isFieldRequired}
                                max={currentDate}
                                autoComplete="off"
                                className="name"
                                type="date"
                                value={fFromDate}
                                onChange={(e) => setFFromDate(e.target.value)}
                              />
                            </div>
                            <div className="form-group1">
                              <label>To Date</label>
                              <input
                                required={isFieldRequired}
                                autoComplete="off"
                                className="name"
                                type="date"
                                value={fToDate}
                                onChange={(e) => setFToDate(e.target.value)}
                              />
                            </div>

                            <div className="form-group1 caseMobile btnGroup">
                              <div className="btn_main casebtn">
                                <button
                                  type="submit"
                                  className="btn btn_client"
                                >
                                  Search
                                </button>
                                <button
                                  type="button"
                                  className="btn btn_client"
                                  onClick={filterCancel}
                                >
                                  Cancel
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                      </form>
                    </div>
                    <div
                      style={{
                        display: isView === "0" ? "none" : "block",
                      }}
                      className="searDesign"
                    >
                      <MaterialReactTable table={table} />
                    </div>
                  </div>
                </div>
              </div>
              <Footer />
            </div>

            {/*------------ DELETE CASE -----------*/}

            <div
              className="modal fade sc_modal"
              id="exampleModal"
              tabIndex={-1}
              aria-labelledby="exampleModalLabel"
              aria-hidden="true"
            >
              <div className="modal-dialog modal-dialog-centered">
                <div className="modal-content">
                  <div className="modal-header">
                    <button
                      type="button"
                      className="btn-close d_case"
                      data-bs-dismiss="modal"
                      aria-label="Close"
                    />
                  </div>
                  <div className="modal-body">
                    <h4>Delete Case</h4>
                    <p>
                      Event, Note, Letter, Task, Invoice, Receipt and
                      Appointment all related to this case will be deleted.
                    </p>
                    <ul>
                      <li>
                        <a
                          className="btn btn-secondary"
                          data-bs-dismiss="modal"
                        >
                          Cancel
                        </a>
                      </li>
                      <li>
                        <a
                          className="btn btn-danger"
                          onClick={() => deleteCaseLead(delId)}
                        >
                          Delete
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>

            {/*------------ ADD CASE FORM -----------*/}

            <div
              className="modal fade"
              id="exampleModalLong"
              tabIndex={-1}
              role="dialog"
              aria-labelledby="exampleModalLongTitle"
              aria-hidden="true"
              show={show}
            >
              <div
                className="modal-dialog modal-xl"
                role="document"
                // style={{ maxWidth: "80%" }}
              >
                <div className="modal-content">
                  <div className="add_wrap">
                    <div className="main_heading">
                      <h2>Add Case</h2>
                    </div>
                    {showField && (
                      <div className="add_wrap">
                        <form
                          role="form "
                          className="clientf"
                          onSubmit={HandleCSubmit}
                        >
                          <div className="main_heading">
                            <h2>Add New Client</h2>
                          </div>

                          <hr />
                          <div className="form_flex caseMobile">
                            <div className="form-group">
                              <label for="uname">
                                Full Name <text className="m_star">*</text>
                              </label>
                              <input
                                autoComplete="off"
                                className="name"
                                type="text"
                                placeholder="Enter Full Name"
                                onChange={(e) => setFirstName(e.target.value)}
                                required
                              />
                            </div>
                            <div className="form-group">
                              <label for="uname">
                                Mobile No. <text className="m_star">*</text>
                              </label>
                              <input
                                onKeyPress={handleKeyPress}
                                maxLength={10}
                                className="name"
                                autoComplete="off"
                                type="text"
                                placeholder="Enter Mobile No"
                                onChange={handleInputChange}
                                required
                              />
                            </div>
                          </div>
                          <div className="form_flex caseMobile">
                            <div className="form-group">
                              <label htmlFor="username">
                                State <text className="m_star">*</text>{" "}
                              </label>
                              <select
                                required
                                className="name"
                                name="cars"
                                id="cars"
                                onChange={(e) => setStateId(e.target.value)}
                              >
                                <option>--Select State--</option>
                                {state && state.length ? (
                                  state &&
                                  state.map((val, index) => {
                                    return (
                                      <option key={index} value={val.state_id}>
                                        {val.state_title}
                                      </option>
                                    );
                                  })
                                ) : (
                                  <option value="">Loading...</option>
                                )}
                              </select>
                            </div>
                            <div className="form-group">
                              <label htmlFor="username">
                                {" "}
                                District <text className="m_star">*</text>
                              </label>
                              <select
                                required
                                className="name"
                                name="cars"
                                id="cars"
                                onChange={(e) => setDistrictId(e.target.value)}
                              >
                                <option>--Select District--</option>
                                {district && district.length ? (
                                  district.map((val, index) => {
                                    return (
                                      <option
                                        key={index}
                                        value={val.district_id}
                                      >
                                        {val.district_title}
                                      </option>
                                    );
                                  })
                                ) : (
                                  <option value="">Loading...</option>
                                )}
                              </select>
                            </div>
                            <div className="form-group">
                              <label htmlFor="username">
                                Tehsil <text className="m_star">*</text>
                              </label>
                              <select
                                className="name"
                                required
                                name="cars"
                                id="cars"
                                onChange={(e) => setCityId(e.target.value)}
                              >
                                <option value="">--Select Tehsil--</option>
                                {city && city.length ? (
                                  city.map((val, index) => {
                                    return (
                                      <option key={index} value={val.city_id}>
                                        {val.city_title}
                                      </option>
                                    );
                                  })
                                ) : (
                                  <option value="">Loading...</option>
                                )}
                              </select>
                            </div>
                          </div>
                          <div className="btn_main">
                            <button type="submit" className="btn_save">
                              Save
                            </button>
                            <button
                              type="button"
                              onClick={handleClickback}
                              className="btn_save btn_cancle"
                            >
                              Cancel
                            </button>
                          </div>
                          {/* </div> */}
                        </form>
                      </div>
                    )}
                  </div>
                  <div className="popup_modal ">
                    <button
                      type="button"
                      className="close  btn_popup closeCaseAdd"
                      data-dismiss="modal"
                      aria-label="Close"
                    >
                      <span aria-hidden="true" onClick={handleClickback}>
                        ×
                      </span>
                    </button>
                    <a
                      style={{ display: isAdd === "0" ? "none" : "block" }}
                      type="button"
                      className="clientpop"
                      data-toggle="modal"
                      onClick={handleClick}
                    >
                      + Add Client if you missed
                    </a>
                    <a
                      type="button"
                      className="btn btn-primary btn_client"
                      data-toggle="modal"
                      data-target="#exampleModalLongCheckCaseStatus"
                      onClick={closeCaseAdd_CheckCase}
                    >
                      Check Case Status
                    </a>
                    &nbsp;
                    <div className="dashed_popup">
                      <form role="form" onSubmit={submitHandler} ref={formRef}>
                        <div className="form_flex caseMobile">
                          <div className="form-group">
                            <label htmlFor="uname">
                              Case Name<text className="m_star">*</text>
                            </label>
                            <input
                              value={title1}
                              className="name"
                              type="text"
                              placeholder="Enter Case Name"
                              name="uname"
                              onChange={handleTitle1}
                              required
                            />
                          </div>
                          VS
                          <div className="form-group">
                            <label htmlFor="uname">&nbsp;</label>
                            <input
                              value={title2}
                              className="name"
                              type="text"
                              placeholder="Enter Case Name"
                              name="uname"
                              onChange={handleTitle2}
                            />
                          </div>
                          <div className="form-group">
                            <label htmlFor="uname" className="clientd">
                              Client<text className="m_star">*</text>
                            </label>
                            <Select
                              required
                              className="basic-single"
                              placeholder="Please Select"
                              classNamePrefix="select"
                              onChange={handleSelectChange}
                              isClearable={true}
                              isSearchable={true}
                              name="color"
                              options={options}
                            />
                          </div>
                        </div>

                        <div className="form_flex caseMobile">
                          <div className="form-group">
                            <label htmlFor="username">Court </label>
                            <select
                              value={court}
                              className="name"
                              name="cars"
                              id="cars"
                              onChange={(e) => {
                                const selectedCourtId = e.target.value;
                                const selectedCourtName = courtData.find(
                                  (val) => val.Id === selectedCourtId
                                )?.CourtName;
                                handleCourtChange(
                                  selectedCourtId,
                                  selectedCourtName
                                );
                              }}
                            >
                              <option value="">--Please Select--</option>
                              {courtData &&
                                courtData.map((val, index) => {
                                  return (
                                    <option key={index} value={val.Id}>
                                      {val.CourtName}
                                    </option>
                                  );
                                })}
                            </select>
                          </div>

                          <div className="form-group">
                            {console.log(caseTypeID)}
                            <label htmlFor="username">Case Type </label>
                            <select
                              className="name"
                              name="cars"
                              id="cars"
                              onChange={(e) => setType(e.target.value)}
                            >
                              <option value="">--Please Select--</option>
                              {caseType && caseType.length ? (
                                caseType &&
                                caseType.map((val, index) => {
                                  return (
                                    <option
                                      key={index}
                                      value={val.Id}
                                      selected={val.Id === caseTypeID}
                                    >
                                      {val.CaseType}
                                    </option>
                                  );
                                })
                              ) : (
                                <>
                                  <option value="Booked Consultation/Case">
                                    Booked Consultation / Case
                                  </option>

                                  <option value="">
                                    Please select court first...
                                  </option>
                                </>
                              )}
                            </select>
                          </div>
                          <div className="form-group">
                            <label htmlFor="uname">Case No.</label>
                            <input
                              onKeyPress={handleKeyPress}
                              value={caseNo}
                              className="name"
                              type="text"
                              placeholder="Enter Case No."
                              name="uname"
                              onChange={(e) => setCaseNo(e.target.value)}
                            />
                          </div>
                        </div>
                        <div className="form_flex caseMobile">
                          <div className="form-group">
                            <label htmlFor="uname">
                              Case Year <text className="m_star">*</text>
                            </label>
                            <select
                              required
                              value={caseYear}
                              className="name"
                              name="cars"
                              id="cars"
                              onChange={(e) => setCaseYear(e.target.value)}
                            >
                              <option value="">---Please select---</option>
                              {optionsYear &&
                                optionsYear.map((val, index) => {
                                  return (
                                    <option key={index} value={val.value}>
                                      {val.label}
                                    </option>
                                  );
                                })}
                            </select>
                          </div>
                          <div className="form-group">
                            <label htmlFor="username">
                              Court State <text className="m_star">*</text>
                            </label>
                            <select
                              value={stateId}
                              className="name"
                              required
                              name="cars"
                              id="cars"
                              onChange={(e) => setStateId(e.target.value)}
                            >
                              <option value="">--Select State--</option>
                              {state && state.length ? (
                                state &&
                                state.map((val, index) => {
                                  return (
                                    <option key={index} value={val.state_id}>
                                      {val.state_title}
                                    </option>
                                  );
                                })
                              ) : (
                                <option value="">Loading...</option>
                              )}
                            </select>
                          </div>
                          <div className="form-group">
                            <label htmlFor="username">
                              {" "}
                              Court District <text className="m_star">*</text>
                            </label>
                            <select
                              value={districtId}
                              className="name"
                              name="cars"
                              required
                              id="cars"
                              onChange={(e) => setDistrictId(e.target.value)}
                            >
                              <option value="">--Select District--</option>
                              {district && district.length ? (
                                district.map((val, index) => {
                                  return (
                                    <option key={index} value={val.district_id}>
                                      {val.district_title}
                                    </option>
                                  );
                                })
                              ) : (
                                <option value="">Loading...</option>
                              )}
                            </select>
                          </div>
                        </div>
                        <div className="form_flex caseMobile">
                          <div className="form-group">
                            <label htmlFor="username">Court Tehsil</label>
                            <select
                              className="name"
                              name="cars"
                              id="cars"
                              onChange={(e) => setCityId(e.target.value)}
                            >
                              <option value="">--Select Tehsil--</option>
                              {city && city.length ? (
                                city.map((val, index) => {
                                  return (
                                    <option key={index} value={val.city_id}>
                                      {val.city_title}
                                    </option>
                                  );
                                })
                              ) : (
                                <option value="">Loading...</option>
                              )}
                            </select>
                          </div>

                          <div className="form-group">
                            <label htmlFor="uname">
                              Case Booked At <text className="m_star">*</text>
                            </label>
                            <select
                              required
                              value={branchCity}
                              className="name"
                              name="cars"
                              id="cars"
                              onChange={(e) => setBranchCity(e.target.value)}
                            >
                              <option value="">---Please select---</option>
                              {branchCityData &&
                                branchCityData.map((val, index) => {
                                  return (
                                    <option key={index} value={val.Id}>
                                      {val.BranchCity}
                                    </option>
                                  );
                                })}
                            </select>
                          </div>

                          <div className="form-group">
                            <label htmlFor="uname">Filing Date </label>
                            <input
                              className="name"
                              value={filingDate}
                              type="date"
                              placeholder=""
                              name="uname"
                              onChange={(e) => setFilingdate(e.target.value)}
                            />
                          </div>
                        </div>

                        {showInputField && (
                          <>
                            <div className="main_heading">
                              <h2>Diary</h2>
                            </div>
                            <div className="form_flex caseMobile">
                              <div className="form-group">
                                <label htmlFor="uname">Diary No.</label>
                                <input
                                  className="name"
                                  type="text"
                                  placeholder="Enter Diary No."
                                  name="uname"
                                  onChange={(e) => setDiaryNo(e.target.value)}
                                />
                              </div>
                              <div className="form-group">
                                <label htmlFor="uname">Diary Year</label>
                                <select
                                  className="name"
                                  name="cars"
                                  id="cars"
                                  onChange={(e) => setDiaryYear(e.target.value)}
                                >
                                  <option value="">--Please Select--</option>
                                  {optionsYear &&
                                    optionsYear.map((val, index) => {
                                      return (
                                        <option key={index} value={val.label}>
                                          {val.label}
                                        </option>
                                      );
                                    })}
                                </select>
                              </div>
                              <div className="form-group">
                                <label htmlFor="uname">Name Of Aor </label>
                                <a
                                  className="aorClick"
                                  type="button"
                                  data-toggle="modal"
                                  data-target="#exampleModalLongAor"
                                ></a>
                                <select
                                  className="name"
                                  name="cars"
                                  id="cars"
                                  onChange={(e) =>
                                    handleAorChange(e.target.value)
                                  }
                                >
                                  <option value="">--Please Select--</option>
                                  <option value="newadd"> Add New AOR +</option>
                                  {aorData &&
                                    aorData.map((val, index) => {
                                      return (
                                        <option key={index} value={val.Id}>
                                          {val.AORName}
                                        </option>
                                      );
                                    })}
                                </select>
                              </div>
                            </div>
                          </>
                        )}
                        <hr />
                        <div className="main_heading">
                          <h2>Case Summary</h2>
                        </div>

                        <div className="form_flex caseMobile">
                          <div className="form-group">
                            <label htmlFor="uname">Summary</label>
                            <textarea
                              className="name"
                              type="text"
                              placeholder="Enter Case Summary"
                              name="uname"
                              onChange={(e) => setSummary(e.target.value)}
                            />
                          </div>
                        </div>
                        <hr />
                        <div className="main_heading">
                          <h2>Counsel</h2>
                        </div>

                        <div className="form_flex caseMobile">
                          <div className="form-group">
                            <label htmlFor="uname">
                              Counsel (Incharge){" "}
                              <text className="m_star">*</text>{" "}
                            </label>
                            <select
                              className="name"
                              name="cars"
                              id="cars"
                              value={counsel}
                              onChange={(e) =>
                                handleCounselChange(e.target.value)
                              }
                              required
                            >
                              <option value="">Please select</option>
                              {viewUserData &&
                                viewUserData.map((val, index) => {
                                  return (
                                    <option key={index} value={val.Id}>
                                      {val.Name}
                                    </option>
                                  );
                                })}
                            </select>
                          </div>

                          <div className="form-group">
                            <label htmlFor="uname">
                              Counsel Share ( In % )
                            </label>
                            <input
                              className="name"
                              type="text"
                              placeholder="%"
                              value={counselShare}
                              onChange={handleCounselShareChange}
                            />
                          </div>
                        </div>
                        <hr />
                        {cocounselRow &&
                          cocounselRow.map((row, index) => (
                            <div key={index}>
                              <div className="form_flex caseMobile">
                                <div className="form-group">
                                  <label htmlFor={`coCounselName-${index}`}>
                                    Co-Counsel _ {index + 1}
                                  </label>
                                  <select
                                    className="name"
                                    name="cars"
                                    id="cars"
                                    value={row.coCounselName}
                                    onChange={(e) =>
                                      handlecoCounsel(index, e.target.value)
                                    }
                                  >
                                    <option value="">--Please Select--</option>
                                    {coCounselData &&
                                      coCounselData.map((val, index) => {
                                        return (
                                          <option value={val.value}>
                                            {val.label}
                                          </option>
                                        );
                                      })}
                                  </select>
                                </div>

                                <div className="form-group">
                                  <label htmlFor={`coCounselShare-${index}`}>
                                    Co Counsel Share ( In % )
                                  </label>
                                  <input
                                    className="name"
                                    type="text"
                                    placeholder="%"
                                    value={row.coCounselShare}
                                    onChange={(e) =>
                                      handleCoCounselShareChange(
                                        index,
                                        e.target.value
                                      )
                                    }
                                  />
                                </div>
                                {index === 0 && (
                                  <a
                                    onClick={addRow}
                                    style={{
                                      color: "white",
                                      cursor: "pointer",
                                      margin: "10px",
                                      padding: "6px 10px",
                                      background: "green",
                                    }}
                                  >
                                    +
                                  </a>
                                )}

                                {index !== 0 && (
                                  <a
                                    onClick={() => removeRow(index)}
                                    style={{
                                      color: "white",
                                      cursor: "pointer",
                                      margin: "10px",
                                      padding: "6px 6px",
                                    }}
                                  >
                                    ❌
                                  </a>
                                )}
                              </div>
                            </div>
                          ))}
                        <div className="form_flex caseMobile">
                          <div className="form-group">
                            <label htmlFor="uname">Company Percentage</label>
                            <input
                              className="name"
                              readOnly
                              type="text"
                              value={compantPrecentage}
                            />
                          </div>
                          <div className="form-group">
                            <label htmlFor="uname">
                              Total Co Counsel Share
                            </label>
                            <input
                              className="name"
                              readOnly
                              type="text"
                              value={totalCoCounsel}
                            />
                          </div>
                        </div>

                        <hr />
                        <div className="form_flex caseMobile">
                          <div className="form-group">
                            <label htmlFor="uname">Retaineed For</label>
                            <select
                              className="name"
                              name="cars"
                              id="cars"
                              onChange={(e) => setRetaineed(e.target.value)}
                            >
                              <option value="">--Please Select--</option>
                              <option value="Petitioners">
                                PETITIONERS / PLAINTIFFS / APPELLANTS
                              </option>
                              <option value="Respondants">
                                RESPONDANTS / DEFANDANTS
                              </option>
                              <option value="Intervenors">
                                INTERVENORS / COMPLAINANTS
                              </option>
                            </select>
                          </div>
                          <div className="form-group">
                            <label htmlFor="uname">
                              Intervenors/ Complainants
                            </label>
                            <textarea
                              className="name"
                              rows={2}
                              cols={10}
                              placeholder="Enter Intervenors"
                              onChange={(e) => setIntervenors(e.target.value)}
                            />
                          </div>
                        </div>
                        <div className="form_flex caseMobile">
                          <div className="form-group">
                            <label htmlFor="uname">
                              Petitioners/ Plaintiffs
                            </label>
                            <textarea
                              className="name"
                              rows={2}
                              cols={10}
                              value={patitioners}
                              placeholder="Enter Petitioners"
                              onChange={handlePatitioners}
                            />
                          </div>

                          <div className="form-group">
                            <label htmlFor="uname">
                              Respondants/ Defandants
                            </label>
                            <textarea
                              className="name"
                              rows={2}
                              cols={10}
                              value={respondants}
                              placeholder="Enter Respondants"
                              onChange={handleRespondants}
                            />
                          </div>
                        </div>
                        <hr />
                        <div className="main_heading">
                          <h2>Payment Structure</h2>
                        </div>

                        <div className="form_flex caseMobile">
                          <div className="form-group">
                            <label htmlFor="uname">Fixed Fees</label>
                            <input
                              className="name"
                              onKeyPress={handleKeyPress}
                              type="text"
                              placeholder="Enter Fixed Fees"
                              name="uname"
                              onChange={(e) => setFixedFees(e.target.value)}
                            />
                          </div>
                          <div className="form-group">
                            <label htmlFor="uname">Expenses</label>
                            <select
                              className="name"
                              name="cars"
                              id="cars"
                              onChange={(e) => setExpenses(e.target.value)}
                            >
                              <option value="">--Please Select--</option>
                              <option value="0">EXCLUSIVE</option>
                              <option value="1">INCLUSIVE</option>
                            </select>
                          </div>
                        </div>
                        <div className="form_flex caseMobile">
                          <div className="form-group">
                            <label htmlFor="uname">
                              Effective Appearance Fees
                            </label>
                            <input
                              className="name"
                              onKeyPress={handleKeyPress}
                              type="text"
                              placeholder="Per Effective Appearance Fees"
                              name="uname"
                              onChange={(e) => setAppearanceFee(e.target.value)}
                            />
                          </div>
                          <div className="form-group">
                            <label htmlFor="uname">
                              Non Effective Appr. Fees
                            </label>
                            <input
                              className="name"
                              onKeyPress={handleKeyPress}
                              type="text"
                              placeholder="per Non Effective Appr. Fees"
                              name="uname"
                              onChange={(e) =>
                                setNonAppearanceFee(e.target.value)
                              }
                            />
                          </div>
                        </div>
                        {saveButton ? (
                          <div className="btn_main caseMobile">
                            <button
                              type="submit"
                              className="btn_save"
                              data-dismiss={popup ? "modal" : ""}
                            >
                              Save
                            </button>
                            <button
                              type="button"
                              data-dismiss="modal"
                              className="btn_save btn_cancle"
                            >
                              Cancel
                            </button>
                          </div>
                        ) : (
                          ""
                        )}
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            {/*------------ EDIT CASE FORM -----------*/}

            <div
              className="modal fade"
              id="exampleModalLongEdit"
              tabIndex={-1}
              role="dialog"
              aria-labelledby="exampleModalLongTitle"
              aria-hidden="true"
            >
              <div
                className="modal-dialog modal-xl"
                role="document"
                // style={{ maxWidth: "80%" }}
              >
                <div className="modal-content">
                  <div className="add_wrap">
                    <div className="main_heading">
                      <h2>Edit Case</h2>
                    </div>
                  </div>
                  <div className="popup_modal ">
                    <button
                      type="button"
                      className="close e_popup btn_popup"
                      data-dismiss="modal"
                      aria-label="Close"
                    >
                      <span aria-hidden="true">×</span>
                    </button>
                    <div className="main_heading">
                      <h2>Case</h2>
                    </div>
                    <div className="dashed_popup">
                      <form role="form" onSubmit={submitEditHandler}>
                        <div className="form_flex caseMobile">
                          <div className="form-group">
                            <label htmlFor="uname">
                              Case Name<text className="m_star">*</text>
                            </label>
                            <input
                              className="name"
                              type="text"
                              placeholder="Enter Case Name"
                              name="uname"
                              value={title1}
                              onChange={handleEditTitle1}
                              required
                            />
                          </div>
                          VS
                          <div className="form-group">
                            <label htmlFor="uname">&nbsp;</label>
                            <input
                              className="name"
                              type="text"
                              placeholder="Enter Case Name"
                              name="uname"
                              value={title2}
                              onChange={handleEditTitle2}
                              required
                            />
                          </div>
                          <div className="form-group">
                            <label htmlFor="uname">
                              Client <text className="m_star">*</text>
                            </label>
                            <select
                              className="name"
                              name="cars"
                              id="cars"
                              onChange={(e) => setEditClient(e.target.value)}
                              required
                            >
                              <option value="">Please select</option>
                              {options &&
                                options.map((val, index) => {
                                  return (
                                    <option
                                      key={index}
                                      value={val.value}
                                      selected={val.value === editclient}
                                    >
                                      {val.label}
                                    </option>
                                  );
                                })}
                            </select>
                          </div>
                        </div>
                        <div className="form_flex caseMobile">
                          <div className="form-group">
                            <label htmlFor="username">Court </label>

                            <select
                              className="name"
                              name="cars"
                              id="cars"
                              onChange={(e) => {
                                const selectedCourtId = e.target.value;
                                const selectedCourtName = courtData.find(
                                  (val) => val.Id === selectedCourtId
                                )?.CourtName;
                                handleCourtChange(
                                  selectedCourtId,
                                  selectedCourtName
                                );
                              }}
                            >
                              <option value="">--Please Select--</option>

                              {courtData &&
                                courtData.map((val, index) => {
                                  return (
                                    <option
                                      key={index}
                                      value={val.Id}
                                      selected={val.Id === court}
                                    >
                                      {val.CourtName}
                                    </option>
                                  );
                                })}
                            </select>
                          </div>
                          <div className="form-group">
                            <label htmlFor="username">Case Type </label>
                            <select
                              className="name"
                              name="cars"
                              id="cars"
                              onChange={(e) => setType(e.target.value)}
                            >
                              <option value="">Please select</option>
                              {caseType && caseType.length ? (
                                caseType.map((val, index) => {
                                  return (
                                    <option
                                      key={index}
                                      value={val.Id}
                                      selected={val.Id === type}
                                    >
                                      {val.CaseType}
                                    </option>
                                  );
                                })
                              ) : (
                                <option value="">Loading...</option>
                              )}
                            </select>
                          </div>
                          <div className="form-group">
                            <label htmlFor="uname">Case No.</label>
                            <input
                              onKeyPress={handleKeyPress}
                              className="name"
                              type="text"
                              placeholder="Enter Case No."
                              name="uname"
                              value={editcaseNo}
                              onChange={(e) => setEditCaseNo(e.target.value)}
                            />
                          </div>
                        </div>
                        <div className="form_flex caseMobile">
                          <div className="form-group">
                            <label htmlFor="uname">
                              Case Year<text className="m_star">*</text>
                            </label>
                            <select
                              required
                              className="name"
                              name="cars"
                              id="cars"
                              onChange={(e) => setEditCaseYear(e.target.value)}
                            >
                              <option value="">---Please select---</option>
                              {optionsYear &&
                                optionsYear.map((val, index) => {
                                  return (
                                    <option
                                      key={index}
                                      value={val.value}
                                      selected={val.value == editcaseYear}
                                    >
                                      {val.label}
                                    </option>
                                  );
                                })}
                            </select>
                          </div>
                          <div className="form-group">
                            <label htmlFor="username">
                              Court State <text className="m_star">*</text>
                            </label>
                            <select
                              required
                              className="name"
                              name="cars"
                              id="cars"
                              onChange={handleStateChange}
                            >
                              <option value="">--Please Select--</option>
                              {state && state.length
                                ? state.map((val, index) => {
                                    return (
                                      <option
                                        key={index}
                                        value={val.state_id}
                                        selected={
                                          val.state_id === stateId
                                            ? true
                                            : false
                                        }
                                      >
                                        {val.state_title}
                                      </option>
                                    );
                                  })
                                : ""}
                            </select>
                          </div>
                          <div className="form-group">
                            <label htmlFor="username">
                              Court District<text className="m_star">*</text>
                            </label>
                            <select
                              required
                              className="name"
                              name="cars"
                              id="cars"
                              onChange={handleDistrictChange}
                            >
                              <option value="">--Please Select--</option>
                              {district && district.length
                                ? district.map((val, index) => {
                                    return (
                                      <option
                                        key={index}
                                        value={val.district_id}
                                        selected={
                                          val.district_id === districtId
                                            ? true
                                            : false
                                        }
                                      >
                                        {val.district_title}
                                      </option>
                                    );
                                  })
                                : ""}
                            </select>
                          </div>
                        </div>

                        <div className="form_flex caseMobile">
                          <div className="form-group">
                            <label htmlFor="username">Court Tehsil </label>
                            <select
                              className="name"
                              name="cars"
                              id="cars"
                              onChange={(e) => setCityId(e.target.value)}
                            >
                              ( <option value="">please select...</option> )
                              {city && city.length ? (
                                city.map((val, index) => {
                                  return (
                                    <option
                                      key={index}
                                      value={val.city_id}
                                      selected={
                                        val.city_id === cityId ? true : false
                                      }
                                    >
                                      {val.city_title}
                                    </option>
                                  );
                                })
                              ) : (
                                <option value="">Loading...</option>
                              )}
                            </select>
                          </div>

                          <div className="form-group">
                            <label htmlFor="uname">
                              Case Booked At<text className="m_star">*</text>
                            </label>

                            <select
                              required
                              value={editBranchCity}
                              className="name"
                              name="cars"
                              id="cars"
                              onChange={(e) =>
                                setEditBranchCity(e.target.value)
                              }
                            >
                              <option value="">---Please select---</option>
                              {branchCityData &&
                                branchCityData.map((val, index) => {
                                  return (
                                    <option
                                      key={index}
                                      value={val.Id}
                                      selected={val.Id === editBranchCity}
                                    >
                                      {val.BranchCity}
                                    </option>
                                  );
                                })}
                            </select>
                          </div>

                          <div className="form-group">
                            <label htmlFor="uname">Filing Date </label>
                            <input
                              className="name"
                              type="date"
                              name="uname"
                              value={editfilingDate}
                              onChange={(e) =>
                                setEditFilingdate(e.target.value)
                              }
                            />
                          </div>
                        </div>
                        <hr />

                        {showInputField && (
                          <>
                            <div className="main_heading">
                              <h2>Diary</h2>
                            </div>
                            <div className="form_flex caseMobile">
                              <div className="form-group">
                                <label htmlFor="uname">Diary No.</label>
                                <input
                                  className="name"
                                  type="text"
                                  placeholder="Enter Diart No."
                                  name="uname"
                                  value={editdiaryNo}
                                  onChange={(e) =>
                                    setEditDiaryNo(e.target.value)
                                  }
                                />
                              </div>
                              <div className="form-group">
                                <label htmlFor="uname">Diary Year</label>
                                <select
                                  className="name"
                                  name="cars"
                                  id="cars"
                                  onChange={(e) =>
                                    setEditDiaryYear(e.target.value)
                                  }
                                >
                                  <option value="">--Please Select--</option>
                                  {optionsYear &&
                                    optionsYear.map((val, index) => {
                                      return (
                                        <option
                                          key={index}
                                          value={val.label}
                                          selected={
                                            val.label === editdiaryYear
                                              ? true
                                              : false
                                          }
                                        >
                                          {val.label}
                                        </option>
                                      );
                                    })}
                                </select>
                              </div>
                              <div className="form-group">
                                <label htmlFor="uname">Name Of Aor </label>
                                <a
                                  className="aorClick"
                                  type="button"
                                  data-toggle="modal"
                                  data-target="#exampleModalLongAor"
                                ></a>
                                <select
                                  className="name"
                                  name="cars"
                                  id="cars"
                                  onChange={(e) =>
                                    handleEditAorChange(e.target.value)
                                  }
                                >
                                  <option value="">--Please Select--</option>
                                  <option value="newadd"> Add New AOR +</option>
                                  {aorData &&
                                    aorData.map((val, index) => {
                                      return (
                                        <option
                                          key={index}
                                          selected={val.Id === editaor}
                                          value={val.Id}
                                        >
                                          {val.AORName}
                                        </option>
                                      );
                                    })}
                                </select>
                              </div>
                            </div>
                            <hr />
                          </>
                        )}

                        <div className="main_heading">
                          <h2>Case Summary</h2>
                        </div>

                        <div className="form_flex caseMobile">
                          <div className="form-group">
                            <label htmlFor="uname">Case Summary</label>
                            <textarea
                              className="name"
                              type="text"
                              placeholder="Enter Case Summary"
                              name="uname"
                              value={editsummary}
                              onChange={(e) => setEditSummary(e.target.value)}
                            />
                          </div>
                        </div>
                        <hr />
                        <div className="main_heading">
                          <h2>Counsel</h2>
                        </div>

                        <div className="form_flex caseMobile">
                          <div className="form-group">
                            <label htmlFor="uname">
                              Counsel (Incharge){" "}
                              <text className="m_star">*</text>{" "}
                            </label>
                            <select
                              className="name"
                              name="cars"
                              id="cars"
                              onChange={(e) => setEditCounsel(e.target.value)}
                              required
                            >
                              <option value="">Please select</option>
                              {viewUserData &&
                                viewUserData.map((val, index) => {
                                  return (
                                    <option
                                      key={index}
                                      value={val.Id}
                                      selected={val.Id === editcounsel}
                                    >
                                      {val.Name}
                                    </option>
                                  );
                                })}
                            </select>
                          </div>
                          <div className="form-group">
                            <label htmlFor="uname">
                              Counsel Share ( In % )
                            </label>
                            <input
                              className="name"
                              type="text"
                              placeholder="%"
                              value={editcounselShare}
                              onChange={handleCounselShareChange1}
                            />
                          </div>
                        </div>
                        <hr />
                        {cocounselRow &&
                          cocounselRow.map((row, index) => (
                            <div key={index}>
                              <div className="form_flex caseMobile">
                                <div className="form-group">
                                  <label htmlFor={`coCounselName-${index}`}>
                                    Co-Counsel _ {index + 1}
                                  </label>
                                  <select
                                    className="name"
                                    name="cars"
                                    id="cars"
                                    value={row.coCounselName}
                                    onChange={(e) =>
                                      editHandlecoCounsel(index, e.target.value)
                                    }
                                  >
                                    <option value="">--Please Select--</option>
                                    {viewUserCoCounselData &&
                                      viewUserCoCounselData.map(
                                        (val, index) => {
                                          return (
                                            <option
                                              value={val.Id}
                                              selected={
                                                val.Id === row.coCounselName
                                              }
                                            >
                                              {val.Name}
                                            </option>
                                          );
                                        }
                                      )}
                                  </select>
                                </div>

                                <div className="form-group">
                                  <label htmlFor={`coCounselShare-${index}`}>
                                    Co Counsel Share ( In % )
                                  </label>
                                  <input
                                    className="name"
                                    type="text"
                                    placeholder="%"
                                    value={row.coCounselShare}
                                    onChange={(e) =>
                                      editHandleCoCounselShare(
                                        index,
                                        e.target.value
                                      )
                                    }
                                  />
                                </div>
                                {index === 0 && (
                                  <a
                                    onClick={editAddRow}
                                    style={{
                                      color: "white",
                                      cursor: "pointer",
                                      margin: "10px",
                                      padding: "6px 10px",
                                      background: "green",
                                    }}
                                  >
                                    +
                                  </a>
                                )}

                                {index !== 0 && (
                                  <a
                                    onClick={() =>
                                      editRemoveRow(index, row.coCounselId)
                                    }
                                    style={{
                                      color: "white",
                                      cursor: "pointer",
                                      margin: "10px",
                                      padding: "6px 6px",
                                    }}
                                  >
                                    ❌
                                  </a>
                                )}
                              </div>
                            </div>
                          ))}
                        <div className="form_flex caseMobile">
                          <div className="form-group">
                            <label htmlFor="uname">Retaineed For</label>
                            <select
                              className="name"
                              name="cars"
                              id="cars"
                              value={editretaineed}
                              onChange={(e) => setEditRetaineed(e.target.value)}
                            >
                              <option value="">--Please Select--</option>
                              <option value="Petitioners">
                                PETITIONERS / PLAINTIFFS / APPELLANTS
                              </option>
                              <option value="Respondants">
                                RESPONDANTS / DEFANDANTS
                              </option>
                              <option value="Intervenors">
                                INTERVENORS / COMPLAINANTS
                              </option>
                            </select>
                          </div>
                          <div className="form-group">
                            <label htmlFor="uname">
                              Intervenors/ Complainants
                            </label>
                            <textarea
                              className="name"
                              placeholder="Enter Intervenors"
                              value={editintervenors}
                              onChange={(e) =>
                                setEditIntervenors(e.target.value)
                              }
                            />
                          </div>
                        </div>
                        <div className="form_flex caseMobile">
                          <div className="form-group">
                            <label htmlFor="uname">
                              Petitioners / Plaintiffs
                            </label>
                            <textarea
                              className="name"
                              placeholder="Enter Petitioners"
                              value={editpatitioners}
                              onChange={(e) =>
                                setEditPatitioners(e.target.value)
                              }
                            />
                          </div>

                          <div className="form-group">
                            <label htmlFor="uname">
                              Respondants/ Defandants
                            </label>
                            <textarea
                              className="name"
                              placeholder="Enter Respondants"
                              value={editrespondants}
                              onChange={(e) =>
                                setEditRespondants(e.target.value)
                              }
                            />
                          </div>
                        </div>
                        <div className={`form_flex col-${inputWidth}`}>
                          <div className="form-group">
                            <label htmlFor="uname">Is Open</label>
                            <select
                              className="name"
                              name="cars"
                              id="cars"
                              value={isOpen}
                              onChange={(e) => setIsOpen(e.target.value)}
                            >
                              <option value="1">Open</option>
                              <option value="0">Close</option>
                            </select>
                          </div>
                          {showClosingDate && (
                            <div className="form-group">
                              <label htmlFor="uname">
                                Closing Date <text className="m_star">*</text>
                              </label>
                              <input
                                className="name"
                                required
                                type="Date"
                                value={closingDate}
                                onChange={(e) => setClosingDate(e.target.value)}
                              />
                            </div>
                          )}
                        </div>
                        <hr />
                        <div className="main_heading">
                          <h2>Payment Structure</h2>
                        </div>

                        <div className="form_flex caseMobile">
                          <div className="form-group">
                            <label htmlFor="uname">Fixed Fees</label>
                            <input
                              className="name"
                              onKeyPress={handleKeyPress}
                              type="text"
                              placeholder="Enter Fixed Fees"
                              name="uname"
                              value={editfixedFees}
                              onChange={(e) => setEditFixedFees(e.target.value)}
                            />
                          </div>
                          <div className="form-group">
                            <label htmlFor="uname">Expenses</label>
                            <select
                              className="name"
                              name="cars"
                              id="cars"
                              value={editexpenses}
                              onChange={(e) => setEditExpenses(e.target.value)}
                            >
                              <option value="">--- Please Select ---</option>
                              <option value="0">EXCLUSIVE</option>
                              <option value="1">INCLUSIVE</option>
                            </select>
                          </div>
                        </div>
                        <div className="form_flex caseMobile">
                          <div className="form-group">
                            <label htmlFor="uname">
                              Effective Appearance Fees
                            </label>
                            <input
                              className="name"
                              onKeyPress={handleKeyPress}
                              type="text"
                              placeholder="Per Effective Appearance Fees"
                              name="uname"
                              value={editappearanceFee}
                              onChange={(e) =>
                                setEditAppearanceFee(e.target.value)
                              }
                            />
                          </div>
                          <div className="form-group">
                            <label htmlFor="uname">
                              Non Effective Appr. Fees
                            </label>
                            <input
                              className="name"
                              onKeyPress={handleKeyPress}
                              type="text"
                              placeholder="per Non Effective Appr. Fees"
                              name="uname"
                              value={editnonAppearanceFee}
                              onChange={(e) =>
                                setEditNonAppearanceFee(e.target.value)
                              }
                            />
                          </div>
                        </div>

                        <div className="btn_main caseMobile">
                          <button type="submit" className="btn_save">
                            Save
                          </button>
                          <button
                            type="button"
                            data-dismiss="modal"
                            className="btn_save btn_cancle"
                            // onClick={filterCancel}
                          >
                            Cancel
                          </button>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div
              className="modal fade"
              id="exampleModalLongAor"
              tabIndex={-1}
              role="dialog"
              aria-labelledby="exampleModalLongTitle"
              aria-hidden="true"
              show={show}
            >
              <div
                className="modal-dialog modal-lg"
                role="document"
                // style={{ maxWidth: "50%" }}
              >
                <div className="modal-content">
                  <div className="add_wrap">
                    <div className="main_heading">
                      <h2>Add AOR</h2>
                    </div>
                  </div>
                  <div className="popup_modal ">
                    <button
                      type="button"
                      className="close btn_popup"
                      data-dismiss="modal"
                      aria-label="Close"
                    >
                      <span aria-hidden="true">×</span>
                    </button>
                    <div className="dashed_popup">
                      <form role="form" onSubmit={submitHandlerAor}>
                        <div className="form_flex caseMobile">
                          <div className="form-group">
                            <label htmlFor="username">Aor</label>
                            <input
                              required
                              className="name"
                              type="text"
                              placeholder="Enter Name Of AOR"
                              name="uname"
                              value={aorName}
                              onChange={(e) => setAorName(e.target.value)}
                            />
                          </div>
                        </div>
                        <div className="btn_main caseMobile">
                          <button type="submit" className="btn_save">
                            Save
                          </button>
                          <button
                            type="button"
                            data-dismiss="modal"
                            className="btn_save btn_cancle btn_cancle_aor"
                          >
                            Cancel
                          </button>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            {/*--------- CASE DETAIL ---------*/}

            <div
              className="modal fade"
              id="exampleModalLongDetail"
              tabIndex={-1}
              role="dialog"
              aria-labelledby="exampleModalLongTitle"
              aria-hidden="true"
            >
              <div
                className="modal-dialog modal-xl"
                role="document"
                // style={{ maxWidth: "70%" }}
              >
                <div className="modal-content">
                  <div className="add_wrap">
                    <div className="main_heading">
                      <h2>Case Details</h2>
                    </div>
                  </div>
                  <div className="popup_modal ">
                    <button
                      type="button"
                      className="close ab btn_popup"
                      data-dismiss="modal"
                      aria-label="Close"
                    >
                      <span aria-hidden="true">×</span>
                    </button>
                    <div className="dashed_popup">
                      <div className="form_flex caseMobile">
                        <div className="form-group">
                          <h4>
                            Name &nbsp;: &nbsp;{" "}
                            {dClientName == null ? "-" : dClientName}{" "}
                          </h4>
                        </div>
                        <div className="form-group">
                          <h4>
                            Last Name &nbsp;:&nbsp;{" "}
                            {dClientLastName == null ? "-" : dClientLastName}{" "}
                          </h4>
                        </div>
                        <div className="form-group">
                          <h4>
                            Company &nbsp;: &nbsp;{" "}
                            {dClientCompany == null ? "-" : dClientCompany}{" "}
                          </h4>
                        </div>
                      </div>

                      <div className="form_flex caseMobile">
                        <div className="form-group">
                          <h4>
                            File No. &nbsp;: &nbsp;{" "}
                            {dFileNo == null ? "-" : dFileNo}{" "}
                          </h4>
                        </div>
                        <div className="form-group">
                          <h4>
                            Case No. &nbsp;:&nbsp;{" "}
                            {dcaseNo === null ? "-" : dcaseNo}
                          </h4>
                        </div>
                        <div className="form-group">
                          <h4>
                            Case Name &nbsp;:&nbsp;{" "}
                            {dCaseName === null ? "-" : dCaseName}
                          </h4>
                        </div>
                      </div>

                      <div className="form_flex caseMobile">
                        <div className="form-group">
                          <h4>
                            Court &nbsp;: &nbsp;{" "}
                            {dCourtName == null ? "-" : dCourtName}{" "}
                          </h4>
                        </div>
                        <div className="form-group">
                          <h4>
                            Case Type &nbsp;: &nbsp;{" "}
                            {dCaseType == null ? "-" : dCaseType}{" "}
                          </h4>
                        </div>
                        <div className="form-group">
                          <h4>
                            Case Year &nbsp;:&nbsp;{" "}
                            {dCaseYear === "0000" ||
                            dCaseYear === null ||
                            dCaseYear === ""
                              ? "-"
                              : dCaseYear}{" "}
                          </h4>
                        </div>
                      </div>

                      <div className="form_flex caseMobile">
                        <div className="form-group">
                          <h4>
                            State &nbsp;:&nbsp;{" "}
                            {dstate_title == null ? "-" : dstate_title}{" "}
                          </h4>
                        </div>

                        <div className="form-group">
                          <h4>
                            District &nbsp;: &nbsp;{" "}
                            {ddistrict_title == null ? "-" : ddistrict_title}{" "}
                          </h4>
                        </div>
                        <div className="form-group">
                          <h4>
                            City &nbsp;:&nbsp;{" "}
                            {dcity_title == null ? "-" : dcity_title}{" "}
                          </h4>
                        </div>
                      </div>

                      <div className="form_flex caseMobile">
                        <div className="form-group">
                          <h4>
                            AOR &nbsp;: &nbsp; {daor == null ? "-" : daor}{" "}
                          </h4>
                        </div>
                        <div className="form-group">
                          <h4>
                            Intervenors &nbsp;:&nbsp;{" "}
                            {dintervenors == null ? "-" : dintervenors}{" "}
                          </h4>
                        </div>
                        <div className="form-group">
                          <h4>
                            Respondants &nbsp;: &nbsp;{" "}
                            {drespondants == null ? "-" : drespondants}{" "}
                          </h4>
                        </div>
                      </div>

                      <div className="form_flex caseMobile">
                        <div className="form-group">
                          <h4 className="upperCase">
                            Retained For &nbsp;:&nbsp;{" "}
                            {dretaineed === null || dretaineed === ""
                              ? "-"
                              : dretaineed}
                          </h4>
                        </div>
                        <div className="form-group">
                          <h4>
                            Petitioners &nbsp;: &nbsp;{" "}
                            {dpatitioners == null ? "-" : dpatitioners}{" "}
                          </h4>
                        </div>
                        <div className="form-group">
                          <h4>
                            Expenses &nbsp;:&nbsp;{" "}
                            {dexpenses === "0"
                              ? "EXCLUSIVE"
                              : dexpenses === "1"
                              ? "INCLUSIVE"
                              : "-"}
                          </h4>
                        </div>
                      </div>

                      <div className="form_flex caseMobile">
                        <div className="form-group">
                          <h4>
                            Appearance Fee &nbsp;: &nbsp;{" "}
                            {dappearanceFee == null ? "-" : dappearanceFee}{" "}
                          </h4>
                        </div>
                        <div className="form-group">
                          <h4>
                            Non Appearance Fee &nbsp;:&nbsp;{" "}
                            {dnonAppearanceFee == null
                              ? "-"
                              : dnonAppearanceFee}{" "}
                          </h4>
                        </div>

                        <div className="form-group">
                          <h4>
                            Fixed Fee &nbsp;: &nbsp;{" "}
                            {dfixedFees == null ? "-" : dfixedFees}{" "}
                          </h4>
                        </div>
                      </div>

                      <div className="form_flex caseMobile">
                        <div className="form-group">
                          <h4>
                            Diary No &nbsp;: &nbsp;{" "}
                            {ddiaryNo == null ? "-" : ddiaryNo}{" "}
                          </h4>
                        </div>
                        <div className="form-group">
                          <h4>
                            Diary Year &nbsp;:&nbsp;{" "}
                            {ddiaryYear == null || "0000" ? "-" : ddiaryYear}{" "}
                          </h4>
                        </div>

                        <div className="form-group">
                          <h4>
                            Added By&nbsp;: &nbsp;{" "}
                            {dAddedBy == null ? "-" : dAddedBy}{" "}
                            {dDate == null ? "-" : formateDateTime(dDate)}
                          </h4>
                        </div>
                      </div>

                      <div className="form_flex caseMobile">
                        <div className="form-group">
                          <h4>
                            Is Open &nbsp;: &nbsp;{" "}
                            {dIsOpen === "1" ? "OPEN" : "CLOSE"}
                          </h4>
                        </div>
                        <div className="form-group">
                          <h4>
                            Case Summary &nbsp;: &nbsp;{" "}
                            {dsummary == null || dsummary === ""
                              ? "-"
                              : dsummary}{" "}
                          </h4>
                        </div>
                      </div>
                      <div className="form_flex caseMobile">
                        <div className="form-group">
                          <h4>Case Detail :</h4>
                        </div>
                      </div>

                      <div className="form_flex">
                        <div className="form-group">
                          <table className="table detail_table">
                            <tr>
                              <th>S. No</th>
                              <th>Counsel Name</th>
                              <th>Percentage</th>
                              <th>Counsel Type</th>
                            </tr>

                            {detailData &&
                              detailData.map((val, index) => {
                                return (
                                  <tr key={index}>
                                    <td>{index + 1}</td>
                                    <td>{val.counsel}</td>
                                    <td>{val.CounselPer} % </td>
                                    <td>
                                      {val.CounselType === "1"
                                        ? "COUNSEL"
                                        : "CO-COUNSEL"}
                                    </td>
                                  </tr>
                                );
                              })}
                          </table>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            {/*-------------------- Check Case Status FORM-------------------- */}
            <div
              className="modal fade"
              id="exampleModalLongCheckCaseStatus"
              tabIndex="-1"
              role="dialog"
              aria-labelledby="exampleModalLongTitle"
              aria-hidden="true"
            >
              <div
                className="modal-dialog popUp modal-xl"
                role="document"
                // style={{ maxWidth: "75%" }}
              >
                <div className="modal-content">
                  <div className="add_wrap">
                    <div className="main_heading">
                      <h2>Check Case Status</h2>
                    </div>
                  </div>
                  <div className="popup_modal ">
                    <button
                      type="button"
                      className="close btn_popup closeCaseStatusForm"
                      data-dismiss="modal"
                      aria-label="Close"
                    >
                      <span aria-hidden="true">&times;</span>
                    </button>
                    <div className="dashed_popup">
                      <form role="form " onSubmit={handleCaseStatusSubmit}>
                        <div className="form_flex caseMobile">
                          <div className="form-group">
                            <label for="uname">
                              Court ID
                              <text className="m_star">*</text>
                            </label>
                            <select
                              required
                              value={caseStatusCourtID}
                              onChange={(e) =>
                                setCaseStatusCourtID(e.target.value)
                              }
                            >
                              <option value="">Select</option>
                              <option value="GWL">Gwalior</option>
                              <option value="JBP">Jabalpur</option>
                              <option value="IND">Indore</option>
                            </select>
                          </div>
                          <div className="form-group">
                            <label for="uname">
                              Case Type
                              <text className="m_star">*</text>
                            </label>
                            <select
                              required
                              value={caseStatusCaseType}
                              onChange={(e) =>
                                setCaseStatusCaseType(e.target.value)
                              }
                            >
                              <option value="">Case Type</option>
                              <option value="63">
                                AA - ARBITRATION APPEAL
                              </option>
                              <option value="100">AB - ADVISORY BOARD</option>
                              <option value="64">AC - ARBITRATION CASE</option>
                              <option value="65">
                                AR - ARBITRATION REVISION
                              </option>
                              <option value="61">
                                ARBA - ARBITRATION APPEAL
                              </option>
                              <option value="60">
                                ARBC - ARBITRATION CASES
                              </option>
                              <option value="101">
                                CA(R) - CIVIL APPLICATION
                              </option>
                              <option value="102">
                                CDR - CIRIMINAL DEATH REFRENCE
                              </option>
                              <option value="74">
                                CEA - CENTRAL EXCISE APPEAL
                              </option>
                              <option value="27">
                                CER - CENTRAL EXCISE REF.
                              </option>
                              <option value="30">
                                CESR - CENTRAL EXCISE SALES
                              </option>
                              <option value="83">CFA - CFA</option>
                              <option value="103">
                                CFA(MBI) - CIVIL FIRST APPEAL(MBI)
                              </option>
                              <option value="104">
                                CMA - CIVIL MISCELLANEOUS APPLICATION
                              </option>
                              <option value="105">
                                CMA(R) - CIVIL MISCELLANEOUS APPEAL
                              </option>
                              <option value="106">
                                CMAP(R) - CIVIL MISCELLANEOUS APPLICATION
                              </option>
                              <option value="107">
                                CMAPL - CIVIL MISCELLANEOUS APPEAL
                              </option>
                              <option value="108">
                                CMC - CIVIL MISCELLANEOUS CASE
                              </option>
                              <option value="109">
                                CMEC - CIVIL MISCELLANEOUS EXECUTION CASE
                              </option>
                              <option value="110">
                                CMJ - CIVIL MISCELLANEOUS JURISDICTION
                              </option>
                              <option value="111">
                                CMP - CIVIL MISCELLANEOUS PETITION
                              </option>
                              <option value="112">
                                CMSA - CIVIL MISCELLANEOUS SECOND APPEAL
                              </option>
                              <option value="113">
                                CMWP - CIVIL MISCELLANEOUS WRIT PETITION
                              </option>
                              <option value="69">COMA - COMPANY APPEAL</option>
                              <option value="21">
                                COMP - COMPANY PETITION
                              </option>
                              <option value="31">
                                COMPA - COMPANY APPEALS
                              </option>
                              <option value="66">CONA - CONTEMPT APPEAL</option>
                              <option value="67">
                                CONC - CONTEMPT PETITION CIVIL
                              </option>
                              <option value="73">
                                CONCR - CONTEMPT PETITION CRIMINAL
                              </option>
                              <option value="15">
                                CONT - CONTEMPT. PETITION
                              </option>
                              <option value="54">
                                CONTR - CRIMINAL CONTEMPT
                              </option>
                              <option value="114">
                                COS - CIVIL ORIGINAL SUIT
                              </option>
                              <option value="140">
                                CP(CRI) - CONTEMPT PETITION (CRIMINAL)
                              </option>
                              <option value="19">CR - CIVIL REVISION</option>
                              <option value="51">CRA - CRIMINAL APPEAL</option>
                              <option value="115">
                                CRCC - CRIMINAL CONFORMATION CASE
                              </option>
                              <option value="116">
                                CREF - CIVIL REFERENCE
                              </option>
                              <option value="141">
                                CREGA - CIVIL REGULAR APPEAL
                              </option>
                              <option value="117">CREV - CIVIL REVIEW</option>
                              <option value="118">
                                CRMA - CRIMINAL MISCELLANEOUS APPLICATION
                              </option>
                              <option value="142">
                                CRMC - CRIMINAL MISCELLANEOUS CASE
                              </option>
                              <option value="119">
                                CRP - CIVIL REVISION PETITION
                              </option>
                              <option value="53">
                                CRR - CRIMINAL REVISION
                              </option>
                              <option value="55">
                                CRRE - CRIMINAL REFERENCE
                              </option>
                              <option value="71">
                                CRRF - CRIMINAL REFERENCE
                              </option>
                              <option value="72">
                                CRRFC - CRIMINAL REFERENCE CAPITAL
                              </option>
                              <option value="120">
                                CRSA - CRIMINAL SPECIAL APPEAL
                              </option>
                              <option value="34">CS - CIVIL SUIT</option>
                              <option value="82">CSA - CSA</option>
                              <option value="121">
                                CSA(MBI) - CIVIL SECOND APPEAL(MBI)
                              </option>
                              <option value="122">
                                CSAP - CIVIL SPECIAL APPEAL
                              </option>
                              <option value="123">DR - DEATH REFERENCE</option>
                              <option value="125">EC - EXECUTION CASE</option>
                              <option value="126">
                                EMC - EXECUTION MISCELLANEOUS CASE
                              </option>
                              <option value="127">
                                EOC - EXECUTION OBJECTION CASE
                              </option>
                              <option value="20">EP - ELECTION PETITION</option>
                              <option value="13">FA - FIRST APPEAL</option>
                              <option value="75">
                                FEMA - FORIEGN EXCHANGE MANAGEMENT APPEAL
                              </option>
                              <option value="26">
                                ITA - INCOME TAX APPEAL
                              </option>
                              <option value="22">
                                ITR - INCOME-TAX REFERENCE
                              </option>
                              <option value="17">
                                LPA - LETTER PATENT APPEAL
                              </option>
                              <option value="128">
                                LPAA - LETTER PATENT APPEAL
                              </option>
                              <option value="12">MA - MISC. APPEAL</option>
                              <option value="129">MA(cri) - MA(CRI)</option>
                              <option value="35">
                                MACE - MISC. APPEAL (C. EXCISE)
                              </option>
                              <option value="59">
                                MACOM - MISC.APPEAL(COMPANY)
                              </option>
                              <option value="62">
                                MACTR - COMMERCIAL TAX REF.
                              </option>
                              <option value="28">
                                MAIT - MISC. APPEAL (I.T.)
                              </option>
                              <option value="130">
                                MAPL - MISCELLANEOUS APPLICATION
                              </option>
                              <option value="58">
                                MAVAT - MISC. APPEAL(VAT)
                              </option>
                              <option value="18">MCC - MISC. CIVIL CASE</option>
                              <option value="70">
                                MCOMA - MISC. COMPANY APPEAL
                              </option>
                              <option value="24">
                                MCP - MISC. CIVIL PETITION
                              </option>
                              <option value="52">
                                MCRC - MISC. CRIMINAL CASE
                              </option>
                              <option value="56">
                                MCRP - MISC. CRI. PETITION
                              </option>
                              <option value="131">
                                MEC - MISCELLANEOUS EXECUTION CASE
                              </option>
                              <option value="132">
                                MFA - MISCALLENEOUS FIRST APPREAL
                              </option>
                              <option value="16">MP - MISC. PETITION</option>
                              <option value="90">
                                MSA - MISCELLANEOUS SECOND APPEAL
                              </option>
                              <option value="133">
                                MSAL - MISCALLENEOUS SECOND APPREAL
                              </option>
                              <option value="25">
                                MWP - MISC. WRIT PETITION
                              </option>
                              <option value="80">
                                OA - ORIGINAL APPLICATION
                              </option>
                              <option value="135">OC - OBJECTION CASE</option>
                              <option value="79">
                                OTA - OTHER TAX APPEALS
                              </option>
                              <option value="136">RC - REVIEW CASE</option>
                              <option value="68">RP - REVIEW PETITION</option>
                              <option value="14">SA - SECOND APPEAL</option>
                              <option value="85">SATMA - SATMA</option>
                              <option value="86">SATMCC - SATMCC</option>
                              <option value="88">SATOA - SATOA</option>
                              <option value="84">SATOT - SATOT</option>
                              <option value="89">SATTA - SATTA</option>
                              <option value="137">
                                SCA - SPECIAL CRIMINAL APPEAL
                              </option>
                              <option value="138">
                                SCR - SMALL CAUSE REVISION
                              </option>
                              <option value="139">
                                SCR(MBI) - SMALL CAUSE REVISION(MBI)
                              </option>
                              <option value="29">
                                STR - SALES TAX REFERENCE
                              </option>
                              <option value="81">
                                TA - TRANSFER APPLICATION
                              </option>
                              <option value="76">TR - TAX REFERENCE</option>
                              <option value="77">
                                VATA - VALUE ADDED TAX APPEAL
                              </option>
                              <option value="57">WA - WRIT APPEAL</option>
                              <option value="11">WP - WRIT PETITION</option>
                              <option value="32">
                                WPS - WRIT PET. (SERVICE)
                              </option>
                              <option value="78">
                                WTA - WEALTH TAX APPEAL
                              </option>
                              <option value="23">
                                WTR - WEALTH-TAX REFERENCE
                              </option>
                            </select>
                          </div>
                          <div className="form-group">
                            <label for="uname">
                              Case Number <text className="m_star">*</text>
                            </label>
                            <input
                              required
                              autoComplete="off"
                              className="name"
                              value={caseStatusCaseNumber}
                              type="text"
                              placeholder="Enter Case No"
                              onChange={(e) =>
                                setCaseStatusCaseNumber(e.target.value)
                              }
                            />
                          </div>
                        </div>
                        <div className="form_flex caseMobile">
                          <div className="form-group">
                            <label for="uname">
                              Case Year
                              <text className="m_star">*</text>
                            </label>
                            <select
                              value={caseStatusCaseYear}
                              required
                              className="name"
                              name="cars"
                              id="cars"
                              onChange={(e) =>
                                setCaseStatusCaseYear(e.target.value)
                              }
                            >
                              <option value="">---Please select---</option>
                              {optionsYear &&
                                optionsYear.map((val, index) => {
                                  return (
                                    <option key={index} value={val.value}>
                                      {val.label}
                                    </option>
                                  );
                                })}
                            </select>
                          </div>
                          <div className="form-group">
                            <label for="uname">Captcha</label>
                            <div>
                              <img src={captchaPath} />
                            </div>
                          </div>
                          <div className="form-group">
                            <label for="uname">
                              Captcha Code
                              <text className="m_star">*</text>
                            </label>
                            <input
                              autoComplete="off"
                              className="name"
                              type="text"
                              placeholder="Enter Captcha Code"
                              required
                              value={caseStatusCaptchaCode}
                              onChange={(e) =>
                                setCaseStatusCaptchaCode(e.target.value)
                              }
                            />
                          </div>
                        </div>
                        {registration_number ? (
                          <div className="form-group">
                            <div style={{ fontWeight: "700", color: "red" }}>
                              {`This case has been registered under ${registration_number}`}
                            </div>
                          </div>
                        ) : (
                          ""
                        )}

                        <div className="btn_main caseMobile">
                          <button
                            type="submit"
                            className="btn_save btn_cancle"
                            onClick={handleReset}
                          >
                            Reset
                          </button>
                          <button type="submit" className="btn_save">
                            Save
                          </button>
                          <button
                            type="button"
                            className="btn_save btn_cancle"
                            data-dismiss="modal"
                          >
                            Cancel
                          </button>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            {/*--------- Toster ---------*/}

            <ToastContainer
              position="top-right"
              autoClose={1000}
              hideProgressBar={false}
              newestOnTop={false}
              closeOnClick
              rtl={false}
              pauseOnFocusLoss
              draggable
              pauseOnHover
            />
          </div>
        )}
      </DocumentTitle>
    </>
  );
};

export default Case;
