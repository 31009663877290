import React, { useState, useRef } from "react";
import Header from "./Header";
import Sidebar from "./Sidebar";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import DataTable from "react-data-table-component";
import { useContext } from "react";
import { url } from "../index";
import Select from "react-select";
import { useEffect } from "react";
import { format } from "date-fns";
import {
  MaterialReactTable,
  createMRTColumnHelper,
  useMaterialReactTable,
} from "material-react-table";
import { Box, Button } from "@mui/material";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import { mkConfig, generateCsv, download } from "export-to-csv";
import { useNavigate } from "react-router";
import { jsPDF } from "jspdf";
import autoTable from "jspdf-autotable";
import { Link } from "react-router-dom";
import Checkbox from "@mui/material/Checkbox";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import {
  viewData,
  getClient,
  deleteUser,
  userDetails,
  activeStatus,
  permission,
  customStyles,
  getCurrentDate,
  clearForm,
  formateDateTime,
} from "../services/api.service";
import { formatDate1 } from "../services/api.service";
import Preloader from "./Preloader";
import DocumentTitle from "react-document-title";
import Footer from "./Footer";

const Events = () => {
  const [saveButton, setSaveButton] = useState(true);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    setTimeout(() => {
      setIsLoading(false);
    }, 500);
  }, []);
  //---------- POPUP CLOSE VARIABLE ----------

  const currentDate = new Date().toISOString().split("T")[0];
  const closeButton = document.querySelector(".btn_popup");
  const closeEdit = document.querySelector(".e_popup");
  const closeDelete = document.querySelector(".d_event");
  const closeView = document.querySelector(".ab");

  //---------- EVENT VARIABLE ----------

  const baseUrl = useContext(url);
  const [excel, setExcel] = useState([]);
  const navigate = useNavigate();
  const [isAdd, setIsAdd] = useState("0");
  const [isEdit, setIsEdit] = useState("0");
  const [isView, setIsView] = useState("0");
  const [isDelete, setIsDelete] = useState("0");
  const [isStatus, setIsStatus] = useState("0");

  //---------- ADD EVENT VARIABLE ----------

  const [caseLeadData, setCaseLeadData] = useState([]);
  const [eventDetails, setEventDetails] = useState("");
  const [isEffective, setIsEffective] = useState("");
  const [isUpcoming, setIsUpcoming] = useState("");
  const [nextDate, setNextDate] = useState(currentDate);
  const [caseID, setCaseID] = useState("");
  const [fCaseId, setFCaseId] = useState("");
  const [fFromDate, setFFromDate] = useState("");
  const filterform = new FormData();
  const [fToDate, setFToDate] = useState("");
  const [fClientID, setFClientID] = useState("");
  const [fClientOption, setFClientOption] = useState([]);
  const [date, setDate] = useState(currentDate);
  const [fIsOpen, setFIsOpen] = useState("1");
  const [delId, setDelId] = useState("");
  const [active, setActive] = useState(false);
  const [filterActive, setFilterActive] = useState(false);
  const [fileData, setFileData] = useState([]);
  const [dStatus, setDStatus] = useState("");
  const [dIsEffective, setDIsEffective] = useState("");
  const [dDate, setDDate] = useState("");
  const [dNextDate, setDNextDate] = useState("");
  const [dEventDetails, setDEventDetails] = useState("");
  const [dIsUpcoming, setDIsUpcoming] = useState("");
  const [dAddedBy, setDAddedBy] = useState("");
  const [dCaseName, setDCaseName] = useState("");
  const [dCaseNo, setDCaseNo] = useState("");
  const [dFileNo, setDFileNo] = useState("");
  const [dCaseYear, setDCaseYear] = useState("");
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);

  //---------- EDIT EVENT VARIABLE ----------

  const [editId, setEditId] = useState("");
  const [editDate, setEditDate] = useState("");
  const [editEventDetails, setEditEventDetails] = useState("");
  const [editIsEffective, setEditIsEffective] = useState("");
  const [editIsUpcoming, setEditIsUpcoming] = useState("");
  const [editNextDate, setEditNextDate] = useState("");
  const [editCaseID, setEditCaseID] = useState("");
  const formData = new FormData();
  const editCaseLead = new FormData();

  const [open, setOpen] = useState(false);
  const [selectedColumns, setSelectedColumns] = useState([]);

  const handleExportButtonClick = () => {
    setOpen(true);
  };

  const handleClose1 = () => {
    setOpen(false);
  };

  const handleToggleColumn = (columnName) => {
    const currentIndex = selectedColumns.indexOf(columnName);
    const newSelectedColumns = [...selectedColumns];

    if (currentIndex === -1) {
      newSelectedColumns.push(columnName);
    } else {
      newSelectedColumns.splice(currentIndex, 1);
    }

    setSelectedColumns(newSelectedColumns);
  };

  // ----------------------BY DISHA HALKARA ---------------------------------------------------

  const Case = new FormData();
  const [optionsEvent, setOptionsEvent] = useState([]);
  const getFileNo = async () => {
    Case.append("AdminID", localStorage.getItem("id"));
    Case.append("UserID", localStorage.getItem("uId"));

    try {
      const response = await fetch(`${baseUrl}Event/GetFilename`, {
        method: "POST",
        body: Case,
        headers: {
          Authorization: `${localStorage.getItem("access_token")}`,
          Accept: "*/*",
        },
      });
      const data = await response.json();
      if (data && data.result.message == "Session Time Expire.") {
        toast.error("Token expired please login again");
        setTimeout(() => {
          navigate("/");
        }, 3000);
      }
      if (data && data.result[0].message === "Success") {
        const Mapped = data.result.map((item) => ({
          value: item.CaseID,
          label: `${item.FileNo} / ${item.CaseNo} / ${item.CaseName} / ${item.CaseYear}`,
        }));
        setOptionsEvent(Mapped);
      }
    } catch (error) {
      console.error(error);
    }
  };

  //---------- VIEW EVENT DATA ----------
  const form = new FormData();

  const viewEventApi = async () => {
    setLoading(true);
    form.append("AdminID", localStorage.getItem("id"));
    form.append("UserID", localStorage.getItem("uId"));
    form.append("IsOpen", "");
    try {
      const response = await fetch(`${baseUrl}Event/ViewEventfilter`, {
        method: "POST",
        body: form,
        headers: {
          Authorization: `${localStorage.getItem("access_token")}`,
          Accept: "*/*",
        },
      });
      const data = await response.json();

      if (data && data.result.message === "Session Time Expire.") {
        toast.error("Token expired please login again");
        setTimeout(() => {
          navigate("/");
        }, 1000);
      }

      if (data && data.result.length) {
        setLoading(false);
      } else {
        setCaseLeadData([]);
        setLoading(false);
        setData([]);
      }

      if (
        (data && data.result.length) ||
        (data && data.result[0].message === "Success")
      ) {
        setCaseLeadData(data && data.result);
        console.log(data.result);
        setLoading(false);
        setData(data.result);
      } else {
        setLoading(false);
      }
    } catch (error) {
      console.error(error);
      setTimeout(() => {
        setLoading(false);
        toast.error(
          "Oops! We encountered an issue with our database connection. Please try again later."
        );
      }, 2000);
    }
  };

  const getEventView = async () => {
    const [data, cdata] = await Promise.all([
      permission(6),
      getClient("Client/ViewClient"),
      getFileNo(),
      viewEventApi(),
    ]);

    const clientData = cdata && cdata.filter((item) => item.Status === "1");

    const Mapped =
      clientData &&
      clientData.map((item) => ({
        value: item.Id,
        label: `${item.FirstName} ${item.LastName}`,
      }));
    setFClientOption(Mapped);

    if (
      data &&
      data.result[0].IsAdd === "0" &&
      data &&
      data.result[0].IsView === "0"
    ) {
      navigate("/unauthorized");
    }

    setIsAdd(data && data.result[0].IsAdd);
    setIsEdit(data && data.result[0].IsEdit);
    setIsView(data && data.result[0].IsView);
    setIsStatus(data && data.result[0].IsStatus);
    setIsDelete(data && data.result[0].IsDelete);
  };

  //---------- DELETE EVENT ----------

  const deleteCaseLead = async () => {
    const data = await deleteUser(delId, "Event/DeleteEvent");
    if (data === "token") {
      setTimeout(() => {
        closeDelete.click();
        navigate("/");
      }, 3000);
    }
    if (data === true) {
      closeDelete.click();
      filterActive ? filterApi() : getEventView();
    }
  };

  //---------- ACTIVE DEACTIVE EVENT ----------

  const deActive = async (row) => {
    const data = await activeStatus(row, "Event/EventStatus");
    if (data === "token") {
      setTimeout(() => {
        navigate("/");
      }, 3000);
    }
    if (data === true) {
      filterActive ? filterApi() : getEventView();
    }
  };

  //---------- DETAILS EVENT ----------

  const details = async (id) => {
    const data = await userDetails(id, "Event/ViewEventById");
    if (data === "token") {
      setTimeout(() => {
        closeView.click();
        navigate("/");
      }, 3000);
    } else {
      setDDate(data && data.result[0].Date);
      setDEventDetails(data && data.result[0].EventDetails);
      setDIsEffective(data && data.result[0].IsEffective);
      setDIsUpcoming(data && data.result[0].IsUpcoming);
      setDNextDate(data && data.result[0].NextDate);
      setDStatus(data && data.result[0].Status);
      setDFileNo(data && data.result[0].FileNo);
      setDCaseNo(data && data.result[0].CaseNo);
      setDCaseYear(data && data.result[0].CaseYear);
      setDCaseName(data && data.result[0].CaseName);
      setDAddedBy(data && data.result[0].AddedBy);
    }
  };

  const handleSelectChange = (selectedOption) => {
    if (selectedOption === null) {
      setCaseID("");
    } else {
      setCaseID(selectedOption.value);
    }
  };

  // ---------- FILTER API ---------

  const filterApi = async () => {
    setLoading(true);
    filterform.append("AdminID", localStorage.getItem("id"));
    filterform.append("UserID", localStorage.getItem("uId"));
    filterform.append("CaseID", fCaseId);
    filterform.append("ClientID", fClientID);
    filterform.append("From", fFromDate);
    filterform.append("To", fToDate);
    filterform.append("IsOpen", fIsOpen);
    try {
      const response = await fetch(`${baseUrl}Event/ViewEventfilter`, {
        method: "POST",
        body: filterform,
        headers: {
          Authorization: `${localStorage.getItem("access_token")}`,
          Accept: "*/*",
        },
      });
      const data = await response.json();

      if (data && data.result.message == "Session Time Expire.") {
        toast.error("Token expired please login again");
        setTimeout(() => {
          closeButton.click();
          navigate("/");
        }, 2000);
      }

      if (
        data &&
        data.result.length &&
        data &&
        data.result[0].message === "Success"
      ) {
        setLoading(false);
        setFilterActive(true);
        setCaseLeadData(data.result);
      } else {
        setLoading(false);
        setCaseLeadData([]);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const filterCancel = () => {
    clearForm();
    setCaseID("");
    setFCaseId("");
    setFIsOpen("1");
    setFClientID("");
    setFFromDate("");
    setFToDate("");
    getEventView();
    setFilterActive(false);
  };

  const handleFilterCase = (selectedOption) => {
    if (selectedOption === null) {
      setFCaseId("");
    } else {
      setFCaseId(selectedOption.value);
    }
  };

  const handleFilterClient = (selectedOption) => {
    if (selectedOption === null) {
      setFClientID("");
    } else {
      setFClientID(selectedOption.value);
    }
  };

  //---------- DETAILS EVENT ----------

  const getById = async (id) => {
    const data = await userDetails(id, "Event/ViewEventById");
    if (data === "token") {
      setTimeout(() => {
        closeEdit.click();
        navigate("/");
      }, 2000);
    } else {
      setEditId(data && data.result[0].Id);
      setEditCaseID(data && data.result[0].CaseID);
      setEditDate(data && data.result[0].Date);
      setEditEventDetails(data && data.result[0].EventDetails);
      setEditIsEffective(data && data.result[0].IsEffective);
      setEditIsUpcoming(data && data.result[0].IsUpcoming);
      setEditNextDate(data && data.result[0].NextDate);
    }
  };

  const formRef = useRef(null);
  const [show, setShow] = useState(false);
  const handleClose = () => {
    setShow(false);
    if (formRef.current) {
      formRef.current.reset();
    }
  };

  //---------- ADD EVENT ----------

  const submitHandler = async (event) => {
    event.preventDefault();
    setSaveButton(false);
    formData.append("AdminID", localStorage.getItem("id"));
    formData.append("CreatedBy", localStorage.getItem("UserId"));
    formData.append("Date", date);
    formData.append("EventDetails", eventDetails.toUpperCase());
    formData.append("IsEffective", isEffective);
    formData.append("IsUpcoming", isUpcoming);
    formData.append("NextDate", nextDate);
    formData.append("CaseID", caseID);
    try {
      const response = await fetch(`${baseUrl}Event/AddEvent`, {
        method: "POST",
        body: formData,
        headers: {
          Authorization: `${localStorage.getItem("access_token")}`,
          Accept: "*/*",
        },
      });
      const data = await response.json();
      if (data && data.result.message == "Session Time Expire.") {
        toast.error("Token expired please login again");
        setTimeout(() => {
          closeButton.click();
          navigate("/");
        }, 2000);
      }

      if (data.result.length && data.result[0].message === "Success") {
        toast.success(" added successfully");
        closeButton.click();
        handleClose();
        filterActive ? filterApi() : getEventView();
        setIsEffective("");
        setCaseID("");
        setDate(currentDate);
        setNextDate(currentDate);
        setEventDetails("");
        setIsUpcoming("");
        setSaveButton(true);
      } else {
        toast.error(data.result.message);
        setSaveButton(true);
      }
    } catch (error) {
      console.error(error);
    }
  };

  //---------- EDIT  EVENT ----------

  const submitEditHandler = async (event) => {
    event.preventDefault();
    editCaseLead.append("ID", editId);
    editCaseLead.append("AdminID", localStorage.getItem("id"));
    editCaseLead.append("UpdatedBy", localStorage.getItem("UserId"));
    editCaseLead.append("Date", editDate);
    editCaseLead.append("EventDetails", editEventDetails.toUpperCase());
    editCaseLead.append("IsEffective", editIsEffective);
    editCaseLead.append("IsUpcoming", editIsUpcoming);
    editCaseLead.append("NextDate", editNextDate);
    editCaseLead.append("CaseID", editCaseID);
    try {
      const response = await fetch(`${baseUrl}Event/EditEvent`, {
        method: "POST",
        body: editCaseLead,
        headers: {
          Authorization: `${localStorage.getItem("access_token")}`,
          Accept: "*/*",
        },
      });
      const data = await response.json();
      if (data && data.result.message == "Session Time Expire.") {
        toast.error("Token expired please login again");
        setTimeout(() => {
          closeEdit.click();
          navigate("/");
        }, 3000);
      }

      if (data.result.length && data.result[0].code == 200) {
        toast.success(" Updated successfully");
        closeEdit.click();
        filterActive ? filterApi() : getEventView();
      } else {
        toast.error(data.result[0].message);
      }
    } catch (error) {
      console.error(error);
    }
  };

  //---------- TABLE EVENT ----------
  const columnHelper = createMRTColumnHelper();
  const columns = [
    columnHelper.accessor("name", {
      enableColumnFilter: false,
      enableColumnActions: false,
      enableSorting: false,
      header: "Action",
      Cell: (row) => {
        return (
          <>
            <div style={{ display: "flex", gap: "15px" }}>
              {isEdit !== "0" && (
                <span
                  type="button"
                  data-toggle="modal"
                  data-target="#exampleModalLongEdit"
                  onClick={() => getById(row.row.original.Id)}
                >
                  <img
                    src="assets/img/icons/edit.svg"
                    alt="Edit"
                    title="Edit"
                  />
                </span>
              )}

              <span
                type="button"
                data-toggle="modal"
                data-target="#exampleModalLongDetail"
                onClick={() => details(row.row.original.Id)}
              >
                <img
                  src="assets/img/icons/eye.svg"
                  alt="Details"
                  title="Details"
                />
              </span>
              <span
                type="button"
                data-toggle="modal"
                data-target="#exampleModalLongDetail"
                onClick={() => details(row.row.original.Id)}
              >
                <img
                  src="assets/img/icons/send.svg"
                  alt="Email"
                  title="Send Notification"
                />
              </span>

              <span>
                <Link
                  target="_blank"
                  to={`/View_Event_PDF/${row.row.original.Id}`}
                  title="PDF"
                >
                  <img
                    src="assets/img/icons/pdf.svg"
                    className="pdf"
                    title="PDF"
                  />
                </Link>
              </span>
              <span>
                <a
                  style={{
                    cursor: "pointer",
                    display: isStatus === "0" ? "none" : "block",
                  }}
                  onClick={() => deActive(row.row.original)}
                >
                  {row.row.original.Status === "1" ? (
                    <img
                      className="thumb_icon"
                      src="assets/img/icons/Up.svg"
                      title="Status"
                    />
                  ) : (
                    <img
                      className="thumb_icon"
                      src="assets/img/icons/Down.svg"
                      title="Status"
                    />
                  )}
                </a>
              </span>
              {isDelete !== "0" && (
                <span
                  data-bs-toggle="modal"
                  data-bs-target="#exampleModal"
                  style={{ cursor: "pointer" }}
                  onClick={() => setDelId(row.row.original.Id)}
                >
                  <img
                    src="assets/img/icons/delete.svg"
                    alt="Delete"
                    title="Delete"
                  />
                </span>
              )}
            </div>
          </>
        );
      },
    }),
        columnHelper.accessor("Document", {
              enableColumnFilter: false,
      enableColumnActions: false,
      enableSorting: false,
      header: "PDF Download",
      Cell: (row) => {
        return (
          <div style={{    display: "flex"
,
    justifyContent: "center",
    alignItems: "center"}}>
            {row.row.original.Document === null ||
            row.row.original.Document === "" ? (
              "-"
            ) : (
              <a 
                target="_blank"
                href={`https://api.lawyerhunt.in//uploaded/judgement/${row.row.original.Document}`}
              >
               <FileDownloadIcon style={{color: "#7a0000"}}/>
              </a>
            )}
          </div>
        );
      },
    }),
    columnHelper.accessor("Date", {
      header: "Date",
      Cell: (row) => {
        return (
          <div>
            {row.row.original.Date === null ||
            row.row.original.Date === "0000-00-00"
              ? "-"
              : formatDate1(row.row.original.Date)}
          </div>
        );
      },
    }),

    columnHelper.accessor("Id", {
      header: "Corr. #",
      Cell: (row) => {
        return (
          <div>
            {row.row.original.Id === null || row.row.original.Id === ""
              ? "-"
              : row.row.original.Id}
          </div>
        );
      },
    }),

    columnHelper.accessor("FileNo", {
      header: "File No",
    }),

    columnHelper.accessor("CaseNo", {
      header: "Case No",
      Cell: (row) => {
        return (
          <div>
            {row.row.original.CaseNo === null || row.row.original.CaseNo === ""
              ? "-"
              : row.row.original.CaseNo}
          </div>
        );
      },
    }),

    columnHelper.accessor("CaseName", {
      header: "Case Name",
      Cell: (row) => {
        return (
          <div style={{ minWidth: "350px" }} className="upperCase">
            <a
              title="Case Details"
              href={`#/view-casedetails/${row.row.original.CaseID}`}
              className="visuald_case"
              target="_blank"
              style={{ cursor: "pointer" }}
            >
              {row.row.original.CaseName}
            </a>
          </div>
        );
      },
    }),

    columnHelper.accessor("Counsel", {
      header: "Counsel",
      Cell: (row) => {
        return (
          <div className="upperCase">
            {row.row.original.Counsel === null ||
            row.row.original.Counsel === ""
              ? "-"
              : row.row.original.Counsel}
          </div>
        );
      },
    }),
    columnHelper.accessor("Cocounsel", {
      header: "Co-counsel",
      Cell: (row) => {
        return (
          <div className="upperCase">
            {row.row.original.Cocounsel === null ||
            row.row.original.Cocounsel === ""
              ? "-"
              : row.row.original.Cocounsel}
          </div>
        );
      },
    }),

    columnHelper.accessor("FirstName", {
      header: "Client",
      Cell: (row) => {
        return (
          <div className="upperCase">
            {row.row.original.FirstName}
            <br />
            {row.row.original.LastName}
            <br />
            {row.row.original.MobileNo}
          </div>
        );
      },
    }),
    columnHelper.accessor("EventDetails", {
      header: "Event Details",
      Cell: (row) => {
        return (
          <div style={{ minWidth: "350px" }} className="upperCase">
            {row.row.original.EventDetails === null ||
            row.row.original.EventDetails === ""
              ? "-"
              : row.row.original.EventDetails}
          </div>
        );
      },
    }),

    columnHelper.accessor("IsUpcoming", {
      header: "IsUpcoming",
      Cell: (row) => {
        return (
          <div>
            {row.row.original.IsUpcoming === null ||
            row.row.original.IsUpcoming === ""
              ? "-"
              : row.row.original.IsUpcoming}
          </div>
        );
      },
    }),

    columnHelper.accessor("NextDate", {
      header: "NextDate",
      Cell: (row) => {
        return (
          <div>
            {row.row.original.NextDate === null ||
            row.row.original.NextDate === "0000-00-00"
              ? "-"
              : new Date(row.row.original.NextDate).toLocaleDateString("en-GB")}
          </div>
        );
      },
    }),

    columnHelper.accessor("UpdatedBy", {
      header: "Last-Modified",
      Cell: (row) => {
        return (
          <div className="upperCase">
            {row.row.original.UpdatedBy === null ||
            row.row.original.UpdatedBy === ""
              ? "-"
              : row.row.original.UpdatedBy}
            <br />
            {formateDateTime(row.row.original.UpdatedDate)}
          </div>
        );
      },
    }),

    columnHelper.accessor("Status", {
      header: "Status",
      Cell: (row) => {
        return (
          <div>{row.row.original.Status === "1" ? "ACTIVE" : "DEACTIVE"}</div>
        );
      },
    }),

    columnHelper.accessor("AddedBy", {
      header: "Added By",
      Cell: (row) => {
        return (
          <div className="upperCase">
            {row.row.original.AddedBy === null ||
            row.row.original.AddedBy === ""
              ? "-"
              : row.row.original.AddedBy}
            <br />
            {formateDateTime(row.row.original.CreatedDate)}
          </div>
        );
      },
    }),
  ];
  const csvConfig = mkConfig({
    fieldSeparator: ",",
    decimalSeparator: ".",
    useKeysAsHeaders: true,
  });

  useEffect(() => {
    if (localStorage.getItem("access_token") === null) {
      navigate("/");
    } else {
      window.scrollTo(0, 0);
      getEventView();
    }
  }, []);
  const excelData =
    caseLeadData &&
    caseLeadData.map((obj) => {
      const { Id, code, message, Status, AdminID, ...rest } = obj;
      return rest;
    });

  const handleExportData = () => {
    const csvConfig = mkConfig({
      fieldSeparator: ",",
      decimalSeparator: ".",
      useKeysAsHeaders: true,
    });
    const formattedDate = new Date().toISOString().slice(0, 10); // Get today's date
    const filename = `Event|LegalCRM__${formattedDate}.csv`;

    const csv = generateCsv(csvConfig)(excelData);
    const blob = new Blob([csv], { type: "text/csv" });
    const link = document.createElement("a");
    link.href = window.URL.createObjectURL(blob);
    link.download = filename;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  const handleExportRows = (rows) => {
    const doc = new jsPDF();
    const columnsToExport = [
      { id: "serialNo", header: "S No." },
      ...columns.filter((column) => selectedColumns.includes(column.id)),
    ];

    const tableHeaders = columnsToExport.map((c) => c.header);

    const formattedDate = new Date().toLocaleDateString("en-GB", {
      year: "numeric",
      month: "2-digit",
      day: "2-digit",
    });

    const filename = `Notes|LegalCRM_${formattedDate}.pdf`;
    const addWatermarkToFirstPage = () => {
      const watermarkHeight = 20;

      // Calculate positioning to center the watermark on each page

      doc.setFontSize(10);
      doc.text(
        10,
        watermarkHeight + -10,
        `Generated by : ${localStorage.getItem("name")} `
      );
      doc.text(172, watermarkHeight + -10, `Date: ${formattedDate}`);
      doc.setFontSize(16);
      doc.text("Events Table", 84, 20);
    };
    const watermarkHeight = 20;

    addWatermarkToFirstPage();
    const tableData = rows.map((row, index) => {
      const formattedDateInPdf = row.original["Date"]
        ? format(new Date(row.original["Date"]), "dd/MM/yyyy")
        : "";

      return [
        { content: (index + 1).toString(), styles: { fontStyle: "bold" } }, // Serial number
        ...columnsToExport.slice(1).map((column) => {
          // Exclude the first column (serial number) when mapping
          if (column.id === "Date") {
            return formattedDateInPdf;
          }

          const value =
            column.id in row.original ? row.original[column.id] : "";
          return value !== null && value !== undefined ? value : "";
        }),
      ];
    });
    autoTable(doc, {
      head: [tableHeaders],
      body: tableData,
      startY: watermarkHeight + 5,
    });

    // Add a heading to the table

    // Save the PDF to a Blob
    const pdfBlob = doc.output("blob");

    // Create a blob URL
    const blobUrl = URL.createObjectURL(pdfBlob);

    // Open the URL in a new tab
    window.open(blobUrl, "_blank");

    // Optionally revoke the object URL after opening the new tab
    URL.revokeObjectURL(blobUrl);
    // doc.save(filename);
  };

  const table = useMaterialReactTable({
    columns,
    data: caseLeadData,
    columnFilterDisplayMode: "popover",
    initialState: {
      columnVisibility: {
        Status: false,
        AddedBy: false,
        UpdatedBy: false,
        UpdatedDate: false,
        CreatedDate: false,
        NextDate: false,
        IsUpcoming: false,
      },
    },
    enableStickyHeader: true,
    enableRowNumbers: true,
    state: {
      isLoading: loading,
    },
    muiCircularProgressProps: {
      color: "primary",
      thickness: 5,
      size: 55,
      style: {
        position: "absolute",
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%)",
        zIndex: 1000,
      },
    },
    muiSkeletonProps: {
      animation: "pulse",
      height: 20,
    },
    muiPaginationProps: {
      rowsPerPageOptions: ["25", "100", "500", "1000", "5000"],
      showFirstButton: true,
      showLastButton: true,
    },

    positionToolbarAlertBanner: "bottom",
    renderTopToolbarCustomActions: ({ table }) => (
      <Box
        sx={{
          display: "flex",
          gap: "16px",
          padding: "8px",
          flexWrap: "wrap",
        }}
      >
        <Button
          disabled={table.getPrePaginationRowModel().rows.length === 0}
          onClick={handleExportData}
          startIcon={<FileDownloadIcon />}
        >
          Export Excel
        </Button>

        <Button
          disabled={
            !table || table.getPrePaginationRowModel().rows.length === 0
          }
          onClick={handleExportButtonClick}
          startIcon={<FileDownloadIcon />}
        >
          Export PDF
        </Button>
        <Dialog open={open} onClose={handleClose1}>
          <DialogContent>
            {columns.map((column) =>
              column.header !== "Action" ? (
                <div key={column.id}>
                  <Checkbox
                    checked={selectedColumns.includes(column.id)}
                    onChange={() => handleToggleColumn(column.id)}
                  />
                  {column.header}
                </div>
              ) : (
                ""
              )
            )}
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose1}>Cancel</Button>
            <Button
              onClick={() =>
                handleExportRows(table.getPrePaginationRowModel().rows)
              }
            >
              Export
            </Button>
          </DialogActions>
        </Dialog>
      </Box>
    ),
  });
  {
    isLoading && (
      <div className="modal">
        <div className="modal-content">
          <p>Please wait, data is loading...</p>
        </div>
      </div>
    );
  }
  return (
    <>
      <DocumentTitle title=" EVENTS | LEGAL CRM">
        {isLoading ? (
          <Preloader />
        ) : (
          <div>
            <div className="wraper">
              <Header />
              <div className="mainWraper">
                <Sidebar />
                <div className="pageBody">
                  <div className="pageContent">
                    <div className="PageTitle">
                      <h3>Event List</h3>

                      <a
                        style={{
                          display: isAdd === "0" ? "none" : "block",
                        }}
                        type="button"
                        className="btn btn-primary btn_client headbtn"
                        data-toggle="modal"
                        data-target="#exampleModalLong"
                      >
                        Add Event <img src="assets/img/icons/add.svg" />
                      </a>
                    </div>

                    <div
                      className="filter_type add_wrap"
                      style={{
                        display: isView === "0" ? "none" : "block",
                      }}
                    >
                      <div className="form_flex1 flex_wrap  caseMobile">
                        <div className="form-group1">
                          <label htmlFor="uname">Is Open</label>
                          <select
                            name="cars"
                            id="cars"
                            value={fIsOpen}
                            onChange={(e) => setFIsOpen(e.target.value)}
                          >
                            <option value="1">Open</option>
                            <option value="0">Close</option>
                            <option value="">All</option>
                          </select>
                        </div>
                        <div className="form-group1 zIndex1">
                          <label>File No.</label>

                          <Select
                            className="basic-single"
                            placeholder="Please Select "
                            classNamePrefix="select"
                            onChange={handleFilterCase}
                            isClearable={true}
                            isSearchable={true}
                            name="color"
                            options={optionsEvent}
                          />
                        </div>
                        <div className="form-group1 zIndex2">
                          <label>Client Name</label>
                          <Select
                            className="basic-single"
                            placeholder="Please Select "
                            classNamePrefix="select"
                            onChange={handleFilterClient}
                            isClearable={true}
                            isSearchable={true}
                            name="color"
                            options={fClientOption}
                          />
                        </div>
                        <div className="form-group1">
                          <label>From Date</label>
                          <input
                            // max={currentDate}
                            autoComplete="off"
                            className="name"
                            type="date"
                            value={fFromDate}
                            onChange={(e) => setFFromDate(e.target.value)}
                          />
                        </div>
                        <div className="form-group1">
                          <label>To Date</label>
                          <input
                            autoComplete="off"
                            className="name"
                            type="date"
                            value={fToDate}
                            onChange={(e) => setFToDate(e.target.value)}
                          />
                        </div>
                        <div className="form-group1 caseMobile btnGroup">
                          <div className="btn_main casebtn">
                            <button
                              type="submit"
                              className="btn btn_client"
                              onClick={filterApi}
                            >
                              Search
                            </button>
                            <button
                              type="button"
                              className="btn btn_client"
                              onClick={filterCancel}
                            >
                              Cancel
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div
                      style={{
                        display: isView === "0" ? "none" : "block",
                      }}
                      className="searDesign"
                    >
                      <MaterialReactTable table={table} />
                    </div>
                  </div>
                  {/* </div> */}
                </div>
              </div>
              <Footer />
            </div>

            {/*-------- DELETE EVENT ---------*/}

            <div
              className="modal fade sc_modal"
              id="exampleModal"
              tabIndex={-1}
              aria-labelledby="exampleModalLabel"
              aria-hidden="true"
            >
              <div className="modal-dialog modal-dialog-centered">
                <div className="modal-content">
                  <div className="modal-header">
                    <button
                      type="button"
                      className="btn-close d_event"
                      data-bs-dismiss="modal"
                      aria-label="Close"
                    />
                  </div>
                  <div className="modal-body">
                    <h4>Delete Row</h4>
                    <p>Are you sure you want to delete Event ?</p>
                    <ul>
                      <li>
                        <a
                          className="btn btn-secondary"
                          data-bs-dismiss="modal"
                        >
                          Cancel
                        </a>
                      </li>
                      <li>
                        <a
                          className="btn btn-danger"
                          onClick={() => deleteCaseLead(delId)}
                        >
                          Delete
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>

            {/*-------- ADD EVENT FORM ---------*/}

            <div
              className="modal fade"
              id="exampleModalLong"
              tabIndex={-1}
              role="dialog"
              aria-labelledby="exampleModalLongTitle"
              aria-hidden="true"
              show={show}
            >
              <div
                className="modal-dialog modal-lg"
                role="document"
                // style={{ maxWidth: "50%" }}
              >
                <div className="modal-content">
                  <div className="add_wrap">
                    <div className="main_heading">
                      <h2>Add Event</h2>
                    </div>
                  </div>
                  <div className="popup_modal ">
                    <button
                      type="button"
                      className="close btn_popup"
                      data-dismiss="modal"
                      aria-label="Close"
                    >
                      <span aria-hidden="true">×</span>
                    </button>
                    <div className="dashed_popup">
                      <form role="form " onSubmit={submitHandler}>
                        <div className="form_flex caseMobile">
                          <div className="form-group">
                            <label for="uname">
                              Date <text className="m_star">*</text>
                            </label>
                            <input
                              required
                              autoComplete="off"
                              className="name"
                              type="date"
                              value={date}
                              onChange={(e) => setDate(e.target.value)}
                            />
                          </div>
                          <div className="form-group">
                            <label for="uname">File No.</label>
                            <text className="m_star">*</text>
                            <Select
                              required
                              className="basic-single"
                              placeholder="Select "
                              classNamePrefix="select"
                              onChange={handleSelectChange}
                              isClearable={true}
                              isSearchable={true}
                              name="color"
                              options={optionsEvent}
                            />
                          </div>
                        </div>
                        <div className="form_flex caseMobile">
                          <div className="form-group">
                            <div>
                              <label for="uname">Is Effective </label>
                              <select
                                className="name"
                                value={isEffective}
                                onChange={(e) => setIsEffective(e.target.value)}
                              >
                                <option>--Please Select--</option>
                                <option value="1">YES</option>
                                <option value="0">NO</option>
                              </select>
                            </div>
                          </div>
                          <div className="form-group">
                            <div>
                              <label for="uname">Is Upcoming </label>
                              <select
                                className="name"
                                value={isUpcoming}
                                onChange={(e) => setIsUpcoming(e.target.value)}
                              >
                                <option value="">--Please Select--</option>
                                <option value="Upcoming">UPCOMING</option>
                                <option value="Completed">COMPLETED</option>
                              </select>
                            </div>
                          </div>
                        </div>
                        <div className="form_flex col-md-6">
                          <div className="form-group">
                            <label for="uname">Next Date</label>
                            <input
                              min={currentDate}
                              autoComplete="off"
                              className="name"
                              type="date"
                              value={nextDate}
                              onChange={(e) => setNextDate(e.target.value)}
                            />
                          </div>
                        </div>

                        <div className="form_flex">
                          <div className="form-group">
                            <label for="uname">Event Details</label>
                            <textarea
                              value={eventDetails}
                              onChange={(e) => setEventDetails(e.target.value)}
                              rows={4}
                              cols={50}
                              placeholder="Enter your text here..."
                            />
                          </div>
                        </div>
                        {saveButton ? (
                          <div className="btn_main caseMobile">
                            <button type="submit" className="btn_save">
                              Save
                            </button>
                            <button
                              type="button"
                              className="btn_save btn_cancle"
                              data-dismiss="modal"
                            >
                              Cancel
                            </button>
                          </div>
                        ) : (
                          ""
                        )}
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            {/*-------- EDIT EVENT FORM ---------*/}

            <div
              className="modal fade"
              id="exampleModalLongEdit"
              tabIndex={-1}
              role="dialog"
              aria-labelledby="exampleModalLongTitle"
              aria-hidden="true"
            >
              <div
                className="modal-dialog modal-lg"
                role="document"
                // style={{ maxWidth: "50%" }}
              >
                <div className="modal-content">
                  <div className="add_wrap">
                    <div className="main_heading">
                      <h2>Edit Event</h2>
                    </div>
                  </div>
                  <div className="popup_modal ">
                    <button
                      type="button"
                      className="close e_popup btn_popup"
                      data-dismiss="modal"
                      aria-label="Close"
                    >
                      <span aria-hidden="true">×</span>
                    </button>
                    <div className="dashed_popup">
                      <form role="form " onSubmit={submitEditHandler}>
                        <div className="form_flex caseMobile">
                          <div className="form-group">
                            <label for="uname">Date</label>
                            <input
                              autoComplete="off"
                              className="name"
                              type="date"
                              value={editDate}
                              onChange={(e) => setEditDate(e.target.value)}
                            />
                          </div>
                          <div className="form-group">
                            <label for="uname">File No.</label>
                            <text style={{ color: "red", fontSize: "20px" }}>
                              *
                            </text>
                            <select
                              id="city"
                              onChange={(e) => setEditCaseID(e.target.value)}
                              required
                            >
                              {optionsEvent && optionsEvent.length ? (
                                optionsEvent.map((val, index) => {
                                  return (
                                    <option
                                      key={index}
                                      value={val.value}
                                      selected={val.value == editCaseID}
                                    >
                                      {val.label}
                                      {/* {val.FileNo && `${val.FileNo} /`}{" "}
                                {val.CaseName && `${val.CaseName} /`}{" "}
                                {val.CaseYear} */}
                                    </option>
                                  );
                                })
                              ) : (
                                <option>No Data Found</option>
                              )}
                            </select>
                          </div>
                        </div>
                        <div className="form_flex caseMobile">
                          <div className="form-group">
                            <div>
                              <label for="uname">Is Effective </label>
                              <select
                                value={editIsEffective}
                                onChange={(e) =>
                                  setEditIsEffective(e.target.value)
                                }
                              >
                                <option>--Please Select--</option>
                                <option value="1">YES</option>
                                <option value="0">NO</option>
                              </select>
                            </div>
                          </div>
                          <div className="form-group">
                            <div>
                              <label for="uname">Is Upcoming </label>
                              <select
                                value={editIsUpcoming}
                                onChange={(e) =>
                                  setEditIsUpcoming(e.target.value)
                                }
                              >
                                <option value="">--Please Select--</option>
                                <option value="Upcoming">UPCOMING</option>
                                <option value="Completed">COMPLETED</option>
                              </select>
                            </div>
                          </div>
                        </div>
                        <div className="form_flex col-md-6">
                          <div className="form-group">
                            <label for="uname">Next Date</label>
                            <input
                              autoComplete="off"
                              className="name"
                              type="date"
                              value={editNextDate}
                              onChange={(e) => setEditNextDate(e.target.value)}
                            />
                          </div>
                        </div>
                        <div className="form_flex">
                          <div className="form-group">
                            <label for="uname">Event Details</label>
                            <textarea
                              value={editEventDetails}
                              onChange={(e) =>
                                setEditEventDetails(e.target.value)
                              }
                              rows={4}
                              cols={50}
                              placeholder="Enter your text here..."
                            />
                          </div>
                        </div>
                        <div className="btn_main caseMobile">
                          <button type="submit" className="btn_save">
                            Save
                          </button>
                          <button
                            type="button"
                            className="btn_save btn_cancle"
                            data-dismiss="modal"
                            // onClick={filterCancel}
                          >
                            Cancel
                          </button>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            {/*-------- EVENT DETAILS ---------*/}

            <div
              className="modal fade"
              id="exampleModalLongDetail"
              tabIndex={-1}
              role="dialog"
              aria-labelledby="exampleModalLongTitle"
              aria-hidden="true"
            >
              <div
                className="modal-dialog modal-lg"
                role="document"
                // style={{ maxWidth: "50%" }}
              >
                <div className="modal-content">
                  <div className="add_wrap">
                    <div className="main_heading">
                      <h2>Event Details</h2>
                    </div>
                  </div>
                  <div className="popup_modal ">
                    <button
                      type="button"
                      className="close ab btn_popup"
                      data-dismiss="modal"
                      aria-label="Close"
                    >
                      <span aria-hidden="true">×</span>
                    </button>
                    <div className="dashed_popup">
                      <div className="form_flex caseMobile">
                        <div className="form-group">
                          <h4>
                            Case Name &nbsp;: &nbsp;{" "}
                            {dCaseName === "" ? "-" : dCaseName}{" "}
                          </h4>
                        </div>
                        <div className="form-group">
                          <h4>
                            Case No. &nbsp;: &nbsp;{" "}
                            {dCaseNo === "" ? "-" : dCaseNo}
                          </h4>
                        </div>
                      </div>

                      <div className="form_flex caseMobile">
                        <div className="form-group">
                          <h4>
                            Case Year &nbsp;: &nbsp;{" "}
                            {dCaseYear === "" ? "-" : dCaseYear}
                          </h4>
                        </div>
                        <div className="form-group">
                          <h4>
                            File No &nbsp;:&nbsp;{" "}
                            {dFileNo === "" ? "-" : dFileNo}
                          </h4>
                        </div>
                      </div>

                      <div className="form_flex caseMobile">
                        <div className="form-group">
                          <h4>
                            Date &nbsp;: &nbsp;{" "}
                            {dDate === "0000-00-00" ? "-" : formatDate1(dDate)}
                          </h4>
                        </div>
                        <div className="form-group">
                          <h4>
                            Status &nbsp;:&nbsp;{" "}
                            {dStatus == 1 ? "ACTIVE" : "DEACTIVE"}{" "}
                          </h4>
                        </div>
                      </div>

                      <div className="form_flex caseMobile">
                        <div className="form-group">
                          <h4>
                            Next Date &nbsp;: &nbsp;{" "}
                            {dNextDate === "" || dNextDate == "0000-00-00"
                              ? "-"
                              : formatDate1(dNextDate)}
                          </h4>
                        </div>
                        <div className="form-group">
                          <h4 className="upperCase">
                            Is Upcoming &nbsp;: &nbsp;{" "}
                            {dIsUpcoming === "" || dIsUpcoming === null
                              ? "-"
                              : dIsUpcoming}
                          </h4>
                        </div>
                      </div>

                      <div className="form_flex caseMobile">
                        <div className="form-group">
                          <h4>
                            Is Effective &nbsp;: &nbsp;
                            {dIsEffective == 0 ? "NO" : "YES"}
                          </h4>
                        </div>
                        <div className="form-group">
                          <h4>
                            Added By &nbsp;: &nbsp;{" "}
                            {dAddedBy === "" ? "-" : dAddedBy}
                          </h4>
                        </div>
                      </div>

                      <div className="form_flex caseMobile">
                        <div className="form-group">
                          <h4>
                            Event Details &nbsp;: &nbsp;{" "}
                            {dEventDetails === "" ? "-" : dEventDetails}
                          </h4>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            {/*-------- Toster ---------*/}

            <ToastContainer
              position="top-right"
              autoClose={1000}
              hideProgressBar={false}
              newestOnTop={false}
              closeOnClick
              rtl={false}
              pauseOnFocusLoss
              draggable
              pauseOnHover
            />
          </div>
        )}
      </DocumentTitle>
    </>
  );
};
export default Events;