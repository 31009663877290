import React, { useState, useRef } from "react";
import Swal from "sweetalert";
import Header from "./Header";
import Sidebar from "./Sidebar";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useContext } from "react";
import { url } from "../index";
import { useEffect } from "react";
import { useNavigate } from "react-router";
import DocumentTitle from "react-document-title";
import Footer from "./Footer";
import DOMPurify from "dompurify";

const SearchLowyerName = () => {
  const baseUrl = useContext(url);
  const navigate = useNavigate();
  const [data, setData] = useState("");
  const [csrfToken, setCsrfToken] = useState("");
  const [captchaPath, setCaptchaPath] = useState("");
  const [caseStatusCourtID, setCaseStatusCourtID] = useState("");
  const [caseStatusCaptchaCode, setCaseStatusCaptchaCode] = useState("");
  const [lowyerName, setLowyerName] = useState("");
  const [dtd, setDtd] = useState("");
  const [selectedCaseType, setSelectedCaseType] = useState("");

  // ----------- GET CAPTCHA CODE ----------

  const getCaptchaCode = async () => {
    try {
      const response = await fetch(
        `${baseUrl}CaseStatusByGovWeb/GetCsrfToken`,
        {
          method: "POST",
        }
      );
      const data = await response.json();

      if (data && data.status === "Success") {
        setCsrfToken(data.CsrfToken);
        setCaptchaPath(data.CaptchaPath);
      }
    } catch (error) {
      console.error(error);
    }
  };

  // -------------- HANDLE SUBMIT CASE STATUS ---------------
  const handleSubmit = async (e) => {
    e.preventDefault();
    Swal({
      text: "Loading...",
      content: {
        element: "div",
        attributes: {
          innerHTML: `
                  <div style="display: flex; align-items: center; justify-content: center;">
                    <div class="spinner" style="
                      width: 30px;
                      height: 30px;
                      border: 4px solid rgba(0, 0, 0, 0.1);
                      border-left-color: #000;
                      border-radius: 50%;
                      animation: spin 1s linear infinite;
                    "></div>
                  </div>
                `,
        },
      },
      buttons: false,
      closeOnClickOutside: false,
    });
    const caseStatusForm = new FormData();
    caseStatusForm.append("lname", lowyerName);
    caseStatusForm.append("dtd", dtd);
    caseStatusForm.append("place", caseStatusCourtID);
    caseStatusForm.append("lside", "");
    caseStatusForm.append("dw", selectedCaseType === "F" ? "2" : "1");
    caseStatusForm.append("mfb", selectedCaseType);
    caseStatusForm.append("a", "");
    caseStatusForm.append("code", caseStatusCaptchaCode);
    caseStatusForm.append("csrf_token", csrfToken);

    try {
      const response = await fetch(
        `${baseUrl}CaseStatusByGovWeb/FetchCaseDataByLawyerName`,
        {
          method: "POST",
          headers: {
            Authorization: `${localStorage.getItem("access_token")}`,
            Accept: "*/*",
          },
          body: caseStatusForm,
        }
      );

      const data = await response.json();
      Swal.close();
      if (data && data.result.message === "Session Time Expire.") {
        toast.error("Token expired please login again");
        navigate("/");
      }

      if (data && data.status === "Success") {
        toast.success("Success !");
        setSelectedCaseType("");
        setDtd("");
        setLowyerName("");
        getCaptchaCode();
        setCaseStatusCaptchaCode("");
        setCaseStatusCourtID("");
        setCaptchaPath("");
        setCsrfToken("");
        setData(data && data.result);
      } else {
        toast.error(data.message);
      }
    } catch (error) {
      console.error(error);
    }
  };

  // Generate the dynamic HTML string
  const dynamicHTML = `
   <!DOCTYPE html>
   <html lang="en">
   <head>
       <meta charset="UTF-8">
       <meta name="viewport" content="width=device-width, initial-scale=1.0">
       <title>Notice Letter</title>
   </head>
   <body>
       <p>${data}</p>
   </body>
   </html>
 `;

  // Sanitize the dynamic HTML
  const sanitizedHTML = DOMPurify.sanitize(dynamicHTML);

  useEffect(() => {
    if (localStorage.getItem("access_token") === null) {
      navigate("/");
    } else {
      window.scrollTo(0, 0);
      getCaptchaCode();
    }
  }, []);

  return (
    <>
      <DocumentTitle title="Search Lawyer Name | LEGAL CRM">
        <div>
          <div className="wraper">
            <Header />
            <div className="mainWraper">
              <Sidebar />
              <div className="pageBody">
                <div className="pageContent">
                  <div className="PageTitle">
                    <h3>Search By Lawyer Name</h3>
                  </div>
                  <div>
                    <div className="popup_modal ">
                      <div className="dashed_popup">
                        <form role="form " onSubmit={handleSubmit}>
                          <div className="form_flex caseMobile">
                            <div className="form-group">
                              <label for="uname">
                                Court ID
                                <text className="m_star">*</text>
                              </label>
                              <select
                                required
                                value={caseStatusCourtID}
                                onChange={(e) =>
                                  setCaseStatusCourtID(e.target.value)
                                }
                              >
                                <option value="">Select</option>
                                <option value="GWL">Gwalior</option>
                                <option value="JBP">Jabalpur</option>
                                <option value="IND">Indore</option>
                              </select>
                            </div>

                            <div className="form-group">
                              <label htmlFor="LawyerName">
                                Lawyer Name <text className="m_star">*</text>
                              </label>
                              <input
                                required
                                name="LawyerName"
                                autoComplete="off"
                                className="name"
                                value={lowyerName}
                                type="text"
                                placeholder="Enter LawyerName"
                                onChange={(e) => setLowyerName(e.target.value)}
                              />
                            </div>
                            <div className="form-group">
                              <label htmlFor="dtd">
                                Date <text className="m_star">*</text>
                              </label>
                              <input
                                required
                                name="dtd"
                                autoComplete="off"
                                className="name"
                                value={dtd}
                                type="date"
                                onChange={(e) => setDtd(e.target.value)}
                              />
                            </div>
                          </div>
                          <div className="form_flex caseMobile">
                            <div className="form-group">
                              <label htmlFor="dtd">
                                Choose Option <text className="m_star">*</text>
                              </label>
                              <select
                                required
                                value={selectedCaseType}
                                onChange={(e) =>
                                  setSelectedCaseType(e.target.value)
                                }
                              >
                                <option value="">Select</option>
                                <option value="M">MOTION </option>
                                <option value="F">FINAL </option>
                                <option value="L">LOK ADALAT</option>
                              </select>
                            </div>
                            <div className="form-group">
                              <label for="uname">Captcha</label>
                              <div>
                                <img src={captchaPath} />
                              </div>
                            </div>
                            <div className="form-group">
                              <label for="uname">
                                Captcha Code
                                <text className="m_star">*</text>
                              </label>
                              <input
                                autoComplete="off"
                                className="name"
                                type="text"
                                placeholder="Enter Captcha Code"
                                required
                                value={caseStatusCaptchaCode}
                                onChange={(e) =>
                                  setCaseStatusCaptchaCode(e.target.value)
                                }
                              />
                            </div>
                          </div>
                          <div className="btn_main caseMobile">
                            <button type="submit" className="btn_save">
                              Save
                            </button>
                            <button
                              type="button"
                              className="btn_save btn_cancle"
                              data-dismiss="modal"
                            >
                              Cancel
                            </button>
                          </div>
                        </form>
                      </div>
                    </div>
                  </div>
                  <div
                    dangerouslySetInnerHTML={{ __html: sanitizedHTML }}
                    style={{
                      border: "1px solid #ccc",
                      padding: "10px",
                      marginTop: "20px",
                    }}
                  ></div>
                </div>
              </div>
            </div>
            <Footer />
          </div>
        </div>
      </DocumentTitle>

      {/*-------- TOASTER ---------*/}

      <ToastContainer
        position="top-right"
        autoClose={1000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
    </>
  );
};

export default SearchLowyerName;
