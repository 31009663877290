import React, { useState, useRef } from "react";
import Swal from "sweetalert";
import Header from "./Header";
import Sidebar from "./Sidebar";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useContext } from "react";
import { url } from "../index";
import { useEffect } from "react";
import { useNavigate } from "react-router";

import {
  getcourtView,
  getClient,
  getBranchCity,
} from "../services/api.service";
import Select from "react-select";
import DocumentTitle from "react-document-title";
import Footer from "./Footer";

const AddCase = () => {
  const handleKeyPress = (e) => {
    const charCode = e.which || e.keyCode;
    if (
      charCode < 48 ||
      charCode > 57 ||
      (charCode !== 8 && e.target.value.length >= 10)
    ) {
      e.preventDefault();
    }
  };

  //------------ CLOSE POPUP VARIABLE ----------
  const closeCaseAdd = document.querySelector(".closeCaseAdd");
  const openCaseAddForm = document.querySelector(".openCaseAddForm");
  const closeCaseStatusForm = document.querySelector(".closeCaseStatusForm");
  const closeButton = document.querySelector(".btn_popup");

  //------------ CASE VARIABLE ----------
  const [registration_number, setregistration_number] = useState("");
  const [coCounselData, setCoCounselData] = useState([]);
  const [branchCity, setBranchCity] = useState("");
  const [branchCityData, setBranchCityData] = useState([]);
  const Case = new FormData();
  const baseUrl = useContext(url);
  const navigate = useNavigate();
  const [saveButton, setSaveButton] = useState(true);
  const [village, setVillage] = useState([]);
  const [popup, setPopup] = useState(false);
  const [compantPrecentage, setCompantPrecentage] = useState("");
  const [viewUserData, setViewUserData] = useState([]);
  const [viewUserCoCounselData, setViewUserCoCounselData] = useState([]);
  const [cocounselRow, setCocounselRow] = useState([
    { coCounselName: "", coCounselShare: "" },
  ]);
  const [filingDate, setFilingdate] = useState("");
  const [fileNo, setFileNo] = useState("");
  const [title1, setTitle1] = useState("");
  const [title2, setTitle2] = useState("");
  const [year, setYear] = useState([]);
  const [caseType, setCaseType] = useState([]);
  const [state, setState] = useState([]);
  const [district, setDistrict] = useState([]);
  const [stateId, setStateId] = useState("");
  const [districtId, setDistrictId] = useState("");
  const [cityId, setCityId] = useState("");
  const [courtData, setCourtData] = useState([]);
  const [court, setCourt] = useState("");
  const [city, setCity] = useState([]);
  const [type, setType] = useState("");
  const [diaryNo, setDiaryNo] = useState("");
  const [diaryYear, setDiaryYear] = useState("");
  const [caseNo, setCaseNo] = useState("");
  const [caseYear, setCaseYear] = useState("");
  const [client, setClient] = useState("");
  const [title, setTitle] = useState("");
  const [summary, setSummary] = useState("");
  const [fixedFees, setFixedFees] = useState("");
  const [expenses, setExpenses] = useState("");
  const [appearanceFee, setAppearanceFee] = useState("");
  const [nonAppearanceFee, setNonAppearanceFee] = useState("");
  const [counsel, setCounsel] = useState("");
  const [coCounsel, setCoCounsel] = useState("");
  const [counselShare, setCounselShare] = useState("0");
  const [coCounselShare, setCoCounselShare] = useState("");
  const [aor, setAor] = useState("");
  const [retaineed, setRetaineed] = useState("");
  const [patitioners, setPatitioners] = useState("");
  const [respondants, setRespondants] = useState("");
  const [intervenors, setIntervenors] = useState("");
  const [totalCoCounsel, setTotalCoCounsel] = useState("0");
  const [csrfToken, setCsrfToken] = useState("");
  const [captchaPath, setCaptchaPath] = useState("");
  const [caseStatusCourtID, setCaseStatusCourtID] = useState("");
  const [caseStatusCaseType, setCaseStatusCaseType] = useState("");
  const [caseStatusCaseNumber, setCaseStatusCaseNumber] = useState("");
  const [caseStatusCaptchaCode, setCaseStatusCaptchaCode] = useState("");
  const [caseStatusCaseYear, setCaseStatusCaseYear] = useState("");
  const [judgements, setJudgements] = useState([]);
  const [caseTypeID, setcaseTypeID] = useState("");

  //------------ EDIT CASE VARIABLE ----------

  const [inputWidth, setInputWidth] = useState(6);
  const [showClosingDate, setShowClosingDate] = useState(false);
  const [closingDate, setClosingDate] = useState("");
  const [isOpen, setIsOpen] = useState("1");
  const [editcounsel, setEditCounsel] = useState("");
  const [editcounselShare, setEditCounselShare] = useState("");
  const [editcoCounselShare, setEditCoCounselShare] = useState("");
  const formData = new FormData();
  const districtForm = new FormData();
  const cityForm = new FormData();
  const villegeForm = new FormData();
  const configForm = new FormData();
  const duplicateCaseForm = new FormData();

  // ------- FILE DATA --------

  const getFileNo = async () => {
    Case.append("AdminID", localStorage.getItem("id"));
    Case.append("UserID", localStorage.getItem("uId"));
    try {
      const response = await fetch(`${baseUrl}Event/GetFilename`, {
        method: "POST",
        body: Case,
        headers: {
          Authorization: `${localStorage.getItem("access_token")}`,
          Accept: "*/*",
        },
      });
      const data = await response.json();

      if (data && data.result[0].message === "Success") {
        const Mapped =
          data &&
          data.result.map((item) => ({
            value: item.CaseID,
            label: `${item.FileNo} / ${item.CaseNo} / ${item.CaseName} / ${item.CaseYear}`,
          }));
      }
      if (data && data.result.message == "Session Time Expire.") {
        toast.error("Token expired please login again");
        setTimeout(() => {
          navigate("/");
        }, 3000);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const configApi = async () => {
    configForm.append("AdminID", localStorage.getItem("id"));
    try {
      const response = await fetch(`${baseUrl}Config/ViewConfigById`, {
        method: "POST",
        body: configForm,
        headers: {
          Authorization: `${localStorage.getItem("access_token")}`,
          Accept: "*/*",
        },
      });
      const data = await response.json();
      setCompantPrecentage(parseInt(data && data.result[0].CompanyPer));
      if (data && data.result.message == "Session Time Expire.") {
        toast.error("Token expired please login again");
        setTimeout(() => {
          navigate("/");
        }, 3000);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const clientApi = async () => {
    configForm.append("AdminID", localStorage.getItem("id"));
    configForm.append("UserID", localStorage.getItem("uId"));
    try {
      const response = await fetch(`${baseUrl}Admin/ViewUser`, {
        method: "POST",
        body: configForm,
        headers: {
          Authorization: `${localStorage.getItem("access_token")}`,
          Accept: "*/*",
        },
      });
      const data = await response.json();
      if (data && data.result.message == "Session Time Expire.") {
        toast.error("Token expired please login again");
        setTimeout(() => {
          navigate("/");
        }, 3000);
      }

      const clientData =
        data && data.result.advocateUser.filter((item) => item.Status === "1");

      setViewUserData(clientData);
    } catch (error) {
      console.error(error);
    }
  };

  //------------ VIEW CASE ---------

  const getCaseView = async () => {
    const [yearData, coData, branchCityData] = await Promise.all([
      getYear(),
      getcourtView("Master/ViewCourt", court),
      getBranchCity(),
      getClient("Client/ViewClient"),
      getFileNo(),
      configApi(),
      clientApi(),
      getCountries(),
      getCountriesUser(),
      getCaptchaCode(),
    ]);

    setYear(yearData);
    setBranchCityData(branchCityData);
    const c_data = coData && coData.filter((item) => item.Status === "1");
    setCourtData(c_data);
  };

  const getCaseType = async () => {
    const data = await getcourtView("Master/ViewCaseTypeByCourtId", court);
    setCaseType(data);
  };

  //----------- DELETE CASE ----------

  const formRef = useRef(null);
  const [show, setShow] = useState(false);
  const handleClose = () => {
    setShow(false);
    if (formRef.current) {
      formRef.current.reset();
    }
  };
  const [showField, setShowField] = useState(false);
  const handleClick = () => {
    setShowField(true);
    if (showField === true) {
      setShowField(false);
    }
  };

  const handleClickback = () => {
    setShowField(false);
    clearForm();
  };

  const [showInputField, setShowInputField] = useState(false);
  const handleCourtChange = (selectedValue, name) => {
    setCourt(selectedValue);
    setType("");
    if (name === "Supreme Court") {
      setShowInputField(true);
    } else {
      setShowInputField(false);
      setAor("");
      setDiaryYear("");
      setDiaryNo("");
    }
  };

  const handlecoCounsel = (index, value) => {
    const updatedFormData = [...cocounselRow];
    updatedFormData[index].coCounselName = value;
    setCocounselRow(updatedFormData);
  };

  // --------- ADD ROW ---------> MANISH PRAJAPAT 05 - 10 - 2023 <---------

  const addRow = () => {
    const total = 100 - compantPrecentage;
    if (cocounselRow.length < total) {
      setCocounselRow([
        ...cocounselRow,
        { coCounselName: "", coCounselShare: "" },
      ]);
    } else {
      alert(`Total Percentage is ${total} %`);
    }
  };

  const removeRow = (indexToRemove) => {
    if (cocounselRow.length === 1) {
      return;
    }

    let updatedFormData = cocounselRow.filter(
      (_, index) => index !== indexToRemove
    );

    setTotalCoCounsel(
      totalCoCounsel - cocounselRow[indexToRemove].coCounselShare
    );
    setCocounselRow(updatedFormData);
  };

  //------------ SUBMIT CASE ----------

  const [isValid1, setIsValid1] = useState(true);

  const handleCheck = () => {
    const num1 = parseInt(counselShare);
    const num2 = parseInt(coCounselShare);
    if (isNaN(num1) || num2 < 0 || num2 > 50) {
      setIsValid1(false);
    } else {
      const sum = num1 + num2;
      if (sum === 50) {
        formData.append("CoCounselShare", coCounselShare);
        formData.append("CounselShare", counselShare);
        setIsValid1(true);
      } else {
        toast.error("Sum is not 50.");
      }
    }
  };

  useEffect(() => {
    setIsOpen(isOpen);
    if (isOpen === "0") {
      setShowClosingDate(true);
      setInputWidth(12);
    } else {
      setShowClosingDate(false);
      setInputWidth(6);
      setClosingDate("");
    }
  }, [isOpen]);

  const handleCounselShareChange = (e) => {
    if (e.target.value === "") {
      setCounselShare("0");
    } else {
      const value = parseInt(e.target.value);
      const total = 100 - compantPrecentage;
      const total1 = total - totalCoCounsel;

      if (
        value !== null &&
        value !== undefined &&
        value !== "" &&
        value <= total &&
        value <= total1
      ) {
        setCounselShare(value);
      } else {
        alert(`Total Percentage is ${total} %`);
      }
    }
  };

  const [isValid, setIsValid] = useState(true);

  const handleCoCounselShareChange = (index, value) => {
    const updatedFormData = [...cocounselRow];
    if (updatedFormData[index].coCounselName === "") {
      toast.error("Please Select CoCounsel");
    } else {
      const total = 100 - compantPrecentage;
      const updatedFormData = [...cocounselRow];
      const oldValue = +updatedFormData[index].coCounselShare;
      const total1 = total - counselShare;

      if (value <= total && value <= total1) {
        updatedFormData[index].coCounselShare = value;

        let sum = updatedFormData.reduce(function (prev, current) {
          return prev + +current.coCounselShare;
        }, 0);

        if (sum <= total && sum <= total1) {
          setTotalCoCounsel(sum);
          setCocounselRow(updatedFormData);
        } else {
          updatedFormData[index].coCounselShare = oldValue;
          alert(`Total Percentage is ${total} %`);
        }
      } else {
        alert(`Total Percentage is ${total} %`);
      }
    }
  };

  const handleCheck1 = () => {
    const num1 = parseInt(editcounselShare);
    const num2 = parseInt(editcoCounselShare);
    if (isNaN(num1) || isNaN(num2)) {
      // toast.error("Please enter valid numbers.");
    } else {
      const sum = num1 + num2;
      if (sum === 50) {
        formData.append("CoCounselShare", coCounselShare);
        formData.append("CounselShare", counselShare);
      } else {
        toast.error("Sum is not 50.");
      }
    }
  };

  // -------------- HANDLE SUBMIT CASE STATUS ---------------
  const addJudgementStore = async (judgements, caseId) => {
    Swal({
      text: "Loading...",
      content: {
        element: "div",
        attributes: {
          innerHTML: `
                  <div style="display: flex; align-items: center; justify-content: center;">
                    <div class="spinner" style="
                      width: 30px;
                      height: 30px;
                      border: 4px solid rgba(0, 0, 0, 0.1);
                      border-left-color: #000;
                      border-radius: 50%;
                      animation: spin 1s linear infinite;
                    "></div>
                  </div>
                `,
        },
      },
      buttons: false,
      closeOnClickOutside: false,
    });
    const addJudgementStoreForm = new FormData();
    addJudgementStoreForm.append("Date", "");
    addJudgementStoreForm.append("CaseID", caseId);
    addJudgementStoreForm.append("CreatedBy", localStorage.getItem("UserId"));
    addJudgementStoreForm.append("AdminID", localStorage.getItem("id"));

    const pdfUrls = judgements.map((judgement) => ({
      order_type: judgement.order_type,
      order_date: judgement.order_date,
      pdf_link: judgement.pdf_link,
    }));
    addJudgementStoreForm.append("PdfUrl", JSON.stringify(pdfUrls));

    try {
      const response = await fetch(
        `${baseUrl}CaseStatusByGovWeb/downloadAndStore`,
        {
          method: "POST",
          headers: {
            Authorization: `${localStorage.getItem("access_token")}`,
            Accept: "*/*",
          },
          body: addJudgementStoreForm,
        }
      );

      const data = await response.json();
      Swal.close();
      setTimeout(() => {
        navigate("/home");
      }, 2000);
      if (data && data.status === "Success") {
        toast.success(data.message);
        setJudgements([]);
      } else {
        toast.error(data.message);
      }
    } catch (error) {
      console.error(error);
    }
  };

  //------------ SUBMIT CASE ----------

  const dataSave = async () => {
    setSaveButton(false);
    const filter =
      cocounselRow &&
      cocounselRow.filter(
        (item) => item.coCounselName !== "" && item.coCounselName !== "0"
      );

    const coCounselNameData =
      filter &&
      filter.map((coCounsel) =>
        coCounsel.coCounselName === "" ? "0" : coCounsel.coCounselName
      );
    const coCounselshareData =
      filter &&
      filter.map((coCounsel) =>
        coCounsel.coCounselShare === "" ? "0" : coCounsel.coCounselShare
      );

    const CounselName = [counsel, ...coCounselNameData];

    const CounselPer = [counselShare, ...coCounselshareData];

    const CounselType =
      CounselPer &&
      CounselPer.map((obj, index) => {
        return index === 0 ? 1 : 2;
      });

    formData.append("AdminID", localStorage.getItem("id"));
    formData.append("CreatedBy", localStorage.getItem("UserId"));
    formData.append("CourtID", court);
    formData.append("StateID", stateId);
    formData.append("CaseTypeID", type === "0" ? "" : type);
    formData.append("DistrictID", districtId);
    formData.append("CityID", cityId);
    formData.append("DiaryNo", diaryNo);
    formData.append("DiaryYear", diaryYear);
    formData.append("CaseNo", caseNo);
    formData.append("CaseYear", caseYear);
    formData.append("ClientID", client);
    formData.append("CaseName", title.toUpperCase());
    formData.append("CaseSummary", summary.toUpperCase());
    formData.append("Petitioners", patitioners.toUpperCase());
    formData.append("Intervenors", intervenors.toUpperCase());
    formData.append("CounselID", CounselName);
    formData.append("CounselPer", CounselPer);
    formData.append("CounselType", CounselType);
    formData.append("FixedFees", fixedFees);
    formData.append("AppearanceFee", appearanceFee);
    formData.append("AOR", aor.toUpperCase());
    formData.append("Respondants", respondants.toUpperCase());
    formData.append("RetainedFor", retaineed);
    formData.append("CoCounselID", coCounsel);
    formData.append("Expenses", expenses);
    formData.append("NonAppearanceFee", nonAppearanceFee);
    formData.append("Date", filingDate);
    formData.append("CityCourtID", 1);
    formData.append("FilingDate", filingDate);
    // formData.append("VillageID", villageID);
    formData.append("CompanyPer", compantPrecentage);
    formData.append("BranchCity", branchCity);

    try {
      const response = await fetch(`${baseUrl}Cases/AddCase`, {
        method: "POST",
        body: formData,
        headers: {
          Authorization: `${localStorage.getItem("access_token")}`,
          Accept: "*/*",
        },
      });
      const data = await response.json();
      if (data && data.result.message == "Session Time Expire.") {
        toast.error("Token expired please login again");
        setTimeout(() => {
          closeButton.click();
          navigate("/");
        }, 1000);
      }

      if (data.result.length && data.result[0].message === "Success") {
        toast.success("Case added successfully");
        handleClose();
        setBranchCity("");
        setCourt("");
        setStateId("");
        setType("");
        setDistrictId("");
        setCityId("");
        setDiaryNo("");
        setDiaryYear("");
        setCaseNo("");
        setCaseYear("");
        setClient("");
        setTitle1("");
        setTitle2("");
        setTitle("");
        setSummary("");
        setPatitioners("");
        setIntervenors("");
        setCounsel("");
        setCocounselRow([{ coCounselName: "", coCounselShare: "" }]);
        setCounselShare("");
        setFixedFees("");
        setAppearanceFee("");
        setAor("");
        setTotalCoCounsel("0");
        setRespondants("");
        setRetaineed("");
        setCoCounsel("");
        setCoCounselShare("");
        setExpenses("");
        setNonAppearanceFee("");
        setFilingdate("");
        setFileNo("");
        setSaveButton(true);
        if (judgements && judgements.length) {
          Swal({
            text: "Do you want to add event ?",
            icon: "info",
            buttons: {
              cancel: {
                text: "No",
                value: null,
                visible: true,
                className: "",
                closeModal: true,
              },
              confirm: {
                text: "Yes",
                value: true,
                visible: true,
                className: "",
                closeModal: true,
              },
            },
            closeOnClickOutside: false,
            dangerMode: true,
          }).then((willAdd) => {
            if (willAdd) {
              addJudgementStore(judgements, data.result[0].CaseID);
            } else {
              setTimeout(() => {
                navigate("/home");
              }, 2000);
            }
          });
        } else {
          setTimeout(() => {
            navigate("/home");
          }, 2000);
        }
      } else {
        toast.error(data.result.message);
        setSaveButton(true);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const sweetAlert = () => {
    Swal({
      title: "Are you sure ?",
      text: "We have identified a duplicate record based on the combination of caseYear, Court name , Case type , Client name  and Case No. Are you certain you wish to proceed with adding this duplicate record ?",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then((willDelete) => {
      if (willDelete) {
        dataSave();
      } else {
        Swal("Case not added !");
      }
    });
  };

  const duplicateCase = async () => {
    duplicateCaseForm.append("CaseYear", caseYear);
    duplicateCaseForm.append("CaseNo", caseNo);
    duplicateCaseForm.append("CourtID", court);
    duplicateCaseForm.append("CaseTypeID", type);
    duplicateCaseForm.append("ClientID", client);
    try {
      const response = await fetch(`${baseUrl}Cases/IsDuplicateCase`, {
        method: "POST",
        body: duplicateCaseForm,
        headers: {
          Authorization: `${localStorage.getItem("access_token")}`,
          Accept: "*/*",
        },
      });

      const data = await response.json();

      if (data && data.result[0].IsDuplicateCase === "1") {
        sweetAlert();
      } else {
        dataSave();
      }
    } catch (error) {
      console.error(error);
    }
  };

  const submitHandler = async (event) => {
    event.preventDefault();
    duplicateCase();
  };

  // ----------- GET CAPTCHA CODE ----------

  const getCaptchaCode = async () => {
    try {
      const response = await fetch(
        `${baseUrl}CaseStatusByGovWeb/GetCsrfToken`,
        {
          method: "POST",
        }
      );
      const data = await response.json();

      if (data && data.status === "Success") {
        setCsrfToken(data.CsrfToken);
        setCaptchaPath(data.CaptchaPath);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const formatRespondents = (respondentsString) => {
    // Regular expression to match each respondent
    const respondentPattern = /\s*(\d+)\s+([A-Z\s@]+(?:\(DEAD\)|))/g;
    let match;
    const respondentsArray = [];

    while ((match = respondentPattern.exec(respondentsString)) !== null) {
      const number = match[1];
      const name = match[2].trim();
      const details = respondentsString
        .substring(match.index + match[0].length)
        .split(/\d+\s+/)[0]
        .trim();
      respondentsArray.push({ number, name, details });
    }

    return respondentsArray.map(
      (respondent) =>
        `${respondent.number}. ${respondent.name}: ${respondent.details}`
    );
  };

  // -------------- HANDLE SUBMIT CASE STATUS ---------------
  const handleCaseStatusSubmit = async (e) => {
    e.preventDefault();
    Swal({
      text: "Loading...",
      content: {
        element: "div",
        attributes: {
          innerHTML: `
                  <div style="display: flex; align-items: center; justify-content: center;">
                    <div class="spinner" style="
                      width: 30px;
                      height: 30px;
                      border: 4px solid rgba(0, 0, 0, 0.1);
                      border-left-color: #000;
                      border-radius: 50%;
                      animation: spin 1s linear infinite;
                    "></div>
                  </div>
                `,
        },
      },
      buttons: false,
      closeOnClickOutside: false,
    });
    const caseStatusForm = new FormData();
    caseStatusForm.append("CourtID", caseStatusCourtID);
    caseStatusForm.append("CaseType", caseStatusCaseType);
    caseStatusForm.append("CaseNumber", caseStatusCaseNumber);
    caseStatusForm.append("CaptchaCode", caseStatusCaptchaCode);
    caseStatusForm.append("CaseYear", caseStatusCaseYear);
    caseStatusForm.append("CsrfToken", csrfToken);

    try {
      const response = await fetch(
        `${baseUrl}CaseStatusByGovWeb/fetch_case_status`,
        {
          method: "POST",
          headers: {
            Authorization: `${localStorage.getItem("access_token")}`,
            Accept: "*/*",
          },
          body: caseStatusForm,
        }
      );

      const data = await response.json();
      Swal.close();
      if (data && data.result.message === "Session Time Expire.") {
        toast.error("Token expired please login again");
        navigate("/");
      }

      if (data && data.status === "Success") {
        if (data && data.result.petitioners) {
          toast.success("Success !");
          getCaptchaCode();
          setCaseStatusCaseYear("");
          setCaseStatusCaptchaCode("");
          setCaseStatusCaseNumber("");
          setCaseStatusCaseType("");
          setcaseTypeID(data.result.caseTypeID);
          setCaseStatusCourtID("");
          setCaptchaPath("");
          setDistrictId(data.result.CourtCityID);
          setStateId("20");
          setCaseYear(data.result.CaseYear);
          setCaseNo(data.result.CaseNumber);
          setCourt("2");
          setCsrfToken("");
          setTitle1(
            data.result.petitioners &&
              data.result.petitioners.split("2")[0].split("1")[1]
          );
          setTitle2(
            data.result.respondent &&
              data.result.respondent.split("2")[0].split("1")[1]
          );
          setPatitioners(formatRespondents(data.result.petitioners).join("\n"));
          setRespondants(formatRespondents(data.result.respondent).join("\n"));
          setJudgements(data.result.judgements);
          closeCaseStatusForm.click();
        } else {
          setcaseTypeID(data.result.caseTypeID);
          if (
            data.result.registration_number === "Registration Number not found."
          ) {
            setregistration_number("");
          } else {
            setregistration_number(data.result.registration_number);
          }
          toast.error("Data not found");
        }
      } else {
        toast.error(data.message);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const handleReset = () => {
    setCaseStatusCourtID("");
    setCaseStatusCaseType("");
    setCaseStatusCaseNumber("");
    setCaseStatusCaptchaCode("");
    setCaseStatusCaseYear("");
    getCaptchaCode();
    setregistration_number("");
    setcaseTypeID("");
  };

  //------------ CASE TABLE ----------

  //-------- TITLE ------
  const handleTitle1 = (e) => {
    setTitle1(e.target.value);
    setPatitioners(e.target.value);
    setTitle(e.target.value + " " + "VS" + " " + title2);
  };

  const handleTitle2 = (e) => {
    setTitle2(e.target.value);
    setRespondants(e.target.value);
    setTitle(title1 + " " + "VS" + " " + e.target.value);
  };

  const handlePatitioners = (e) => {
    setPatitioners(e.target.value);
  };

  const handleRespondants = (e) => {
    setRespondants(e.target.value);
  };

  //------------ VIEW CITY ----------

  const getState = async () => {
    try {
      const response = await fetch(`${baseUrl}Master/ViewState`, {
        method: "POST",
        headers: {
          Authorization: `${localStorage.getItem("access_token")}`,
          Accept: "*/*",
        },
      });
      const data = await response.json();
      if (data && data.result.message == "Session Time Expire.") {
        toast.error("Token expired please login again");
        setTimeout(() => {
          navigate("/");
        }, 3000);
      }
      setState(data.result);
    } catch (error) {
      console.error(error);
    }
  };

  //------------ VIEW CITY ----------

  const getDistrict = async () => {
    districtForm.append("state_id", stateId);
    try {
      const response = await fetch(`${baseUrl}Master/ViewDistricByStateID`, {
        method: "POST",
        body: districtForm,
        headers: {
          Authorization: `${localStorage.getItem("access_token")}`,
          Accept: "*/*",
        },
      });
      const data = await response.json();
      if (data && data.result.message == "Session Time Expire.") {
        toast.error("Token expired please login again");
        setTimeout(() => {
          navigate("/");
        }, 3000);
      }
      setDistrict(data.result);
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    setTitle(title1 + " " + "VS" + " " + title2);
  }, [title1, title2]);

  useEffect(() => {
    if (editcounsel === "") {
      setEditCounsel("");
      setViewUserCoCounselData([]);
    } else {
      const data =
        viewUserData && viewUserData.filter((item) => item.Id !== editcounsel);
      setViewUserCoCounselData(data);
    }
  }, [editcounsel]);

  // ------------------Add Client----------------
  const formCData = new FormData();
  const [FirstName, setFirstName] = useState("");
  const [LastName, setLastName] = useState("");
  const [CompanyName, setCompanyName] = useState("");
  const [Designation, setDesignation] = useState("");
  const [HouseNo, setHouseNo] = useState("");
  const [MobileNo, setMobileNo] = useState("");
  const [Phone, setPhone] = useState("");
  const [Email, setEmail] = useState("");
  const [IsLead, setIsLead] = useState("");
  const [CityID, setCityID] = useState([]);
  const [Address, setAddress] = useState("");
  const [PinCode, setPinCode] = useState("");
  const [Source, setSource] = useState("");
  const [ReferredByClientID, setReferredByClientID] = useState("");
  const [ReferredByCounselID, setReferredByCounselID] = useState("");

  const [CountryID, setCountryID] = useState("1");
  const [StateID, setStateID] = useState([]);

  const handleInputChange = (e) => {
    const value = e.target.value;
    // Use regular expression to check if the input contains only numbers
    const isValidInput = /^\d{10}$/.test(value);

    setMobileNo(value);
    setIsValid(isValidInput);
  };

  // ----------- VIEW User/Counsel---------
  const ViewUserData = new FormData();
  const [optionsUser, setOptionsUser] = useState([]);

  const [countriesUser, setCountriesUser] = useState([]);

  const getCountriesUser = async () => {
    ViewUserData.append("AdminID", localStorage.getItem("id"));
    ViewUserData.append("UserID", localStorage.getItem("uId"));
    try {
      const response = await fetch(`${baseUrl}Admin/ViewUser`, {
        method: "POST",

        headers: {
          Authorization: `${localStorage.getItem("access_token")}`,
          Accept: "*/*",
        },
        body: ViewUserData,
      });

      const data = await response.json();
      if (data && data.result.message == "Session Time Expire.") {
        toast.error("Token expired please login again");
        navigate("/");
        window.location.reload();
      }

      setCountriesUser(data && data.result);

      const clientData =
        data && data.result.advocateUser.filter((item) => item.Status === "1");

      const sortData =
        clientData &&
        clientData.sort((a, b) => {
          const nameA = a.Name.toUpperCase();
          const nameB = b.Name.toUpperCase();
          if (nameA < nameB) {
            return -1;
          }
          if (nameA > nameB) {
            return 1;
          }
          return 0;
        });

      setOptionsUser(sortData);
      if (data.response_code === 400) {
        toast.error("Token expired please login again");
      }
    } catch (error) {
      console.error(error);
    }
  };

  // ----------- VIEW Year---------
  const ViewYearData = new FormData();
  const [optionsYear, setOptionYear] = useState([]);
  ViewYearData.append("AdminID", localStorage.getItem("id"));
  const getYear = async () => {
    try {
      const response = await fetch(`${baseUrl}Master/ViewYear`, {
        method: "POST",

        headers: {
          Authorization: `${localStorage.getItem("access_token")}`,
          Accept: "*/*",
        },
        body: ViewYearData,
      });

      const data = await response.json();
      if (data && data.result.message == "Session Time Expire.") {
        toast.error("Token expired please login again");
        navigate("/");
        window.location.reload();
      }

      const y_data = data && data.result.filter((item) => item.Status === "1");

      const Mapped =
        y_data &&
        y_data.map((item) => ({
          value: item.Year,
          label: item.Year,
        }));
      setOptionYear(Mapped);
      if (data.response_code === 400) {
        toast.error("Token expired please login again");
      }
    } catch (error) {
      console.error(error);
    }
  };

  // ----------- VIEW Client---------
  const ViewData = new FormData();
  const [options, setOptions] = useState([]);

  ViewData.append("AdminID", localStorage.getItem("id"));

  const getCountries = async () => {
    try {
      const response = await fetch(`${baseUrl}Client/ViewClient`, {
        method: "POST",

        headers: {
          Authorization: `${localStorage.getItem("access_token")}`,
          Accept: "*/*",
        },
        body: ViewData,
      });

      const data = await response.json();
      if (data && data.result.message == "Session Time Expire.") {
        toast.error("Token expired please login again");
        navigate("/");
        window.location.reload();
      }

      const clientData =
        data && data.result.filter((item) => item.Status === "1");

      const Mapped =
        clientData &&
        clientData.map((item) => ({
          value: item.Id,
          label: `${item.FirstName} ${item.LastName}`,
        }));
      setOptions(Mapped);
      if (data.response_code === 400) {
        toast.error("Token expired please login again");
      }
    } catch (error) {
      console.error(error);
    }
  };

  const HandleCSubmit = async (e) => {
    e.preventDefault();
    formCData.append("AdminID", localStorage.getItem("id"));
    formCData.append("FirstName", FirstName.toUpperCase());
    formCData.append("LastName", LastName.toUpperCase());
    formCData.append("CompanyName", CompanyName);
    formCData.append("Designation", Designation);
    formCData.append("HouseNo", HouseNo);
    if (MobileNo.length === 10) {
      formCData.append("MobileNo", MobileNo);
    } else {
      toast.error("Invalid Mobile No");
    }
    formCData.append("Phone", Phone);
    formCData.append("Email", Email);
    formCData.append("IsLead", selectedOption);
    formCData.append("CityID", cityId);
    formCData.append("Address", Address);
    formCData.append("PinCode", PinCode);
    formCData.append("Source", Source);
    formCData.append("ReferredByClientID", ReferredByClientID);
    formCData.append("ReferredByCounselID", ReferredByCounselID);
    formCData.append("CreatedBy", localStorage.getItem("UserId"));
    formCData.append("CountryID", CountryID);
    formCData.append("StateID", stateId);
    formCData.append("DistrictID", districtId);
    formCData.append("Comments", text);

    // Iterate through the fields and update empty or undefined fields
    for (let pair of formCData.entries()) {
      let fieldName = pair[0];
      let fieldValue = pair[1];

      if (fieldValue === "undefined" || fieldValue === "") {
        // Set the field value to three spaces
        formCData.set(fieldName, "");
      }
    }

    try {
      const response = await fetch(`${baseUrl}Client/AddClient`, {
        method: "POST",

        headers: {
          Authorization: `${localStorage.getItem("access_token")}`,
          Accept: "*/*",
        },
        body: formCData,
      });

      const data = await response.json();
      if (data && data.result.message == "Session Time Expire.") {
        toast.error("Token expired please login again");
        navigate("/");
        window.location.reload();
      }
      if (data.result[0].code === "200") {
        handleClickback();
        clearForm();
        toast.success("Client Added !");
        getCountries();
        getClient();
      } else {
        toast.error(data.result[0].message);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const handleCounselChange = (selectedOption) => {
    if (selectedOption === "") {
      setCounsel("0");
      setCoCounselData([]);
    } else {
      setCounsel(selectedOption);
      const data =
        optionsUser && optionsUser.filter((item) => item.Id !== selectedOption);
      setCoCounselData(data);
    }
  };

  const handleSelectChange = (selectedOption) => {
    if (selectedOption === null) {
      setClient(null);
    } else {
      setClient(selectedOption.value);
    }
  };

  useEffect(() => {}, []);
  function clearForm() {
    var fields = document.getElementsByClassName("name");
    for (var i = 0; i < fields.length; i++) {
      fields[i].value = "";
    }
  }

  const [selectedOption, setSelectedOption] = useState([]);

  const [text, setText] = useState("");

  // dropdown

  const [State, setState1] = useState([]);
  const getState1 = async () => {
    try {
      const response = await fetch(`${baseUrl}Master/ViewState`, {
        method: "POST",

        headers: {
          Authorization: `${localStorage.getItem("access_token")}`,
          Accept: "*/*",
        },
      });

      const data2 = await response.json();
      if (data2 && data2.result.message == "Session Time Expire.") {
        toast.error("Token expired please login again");
        setTimeout(() => {
          navigate("/");
        }, 3000);
      }
      setState1(data2.result);
      setState(data2.result);
    } catch (error) {
      console.error(error);
    }
  };
  const district1 = new FormData();

  const [District, setDistrict1] = useState([]);
  const getDistrict1 = async () => {
    district1.append("state_id", StateID);
    try {
      const response = await fetch(`${baseUrl}Master/ViewDistricByStateID`, {
        method: "POST",
        body: district,
        headers: {
          Authorization: `${localStorage.getItem("access_token")}`,
          Accept: "*/*",
        },
      });

      const data3 = await response.json();
      if (data3 && data3.result.message == "Session Time Expire.") {
        toast.error("Token expired please login again");
        navigate("/");
        window.location.reload();
      }
      setDistrict1(data3.result);
      setDistrict(data3.result);
    } catch (error) {
      console.error(error);
    }
  };

  const [City, setCity1] = useState([]);
  const getCity1 = async () => {
    cityForm.append("district_id", CityID);

    try {
      const response = await fetch(`${baseUrl}Master/ViewCityByDistrictid`, {
        method: "POST",
        body: cityForm,
        headers: {
          Authorization: `${localStorage.getItem("access_token")}`,
          Accept: "*/*",
        },
      });

      const data1 = await response.json();
      if (data1 && data1.result.message == "Session Time Expire.") {
        toast.error("Token expired please login again");
        setTimeout(() => {
          navigate("/");
        }, 3000);
      }
      setCity1(data1.result);
      setCity(data1.result);
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    getState1();
    getCity1();
    getDistrict1();
  }, [StateID, CityID]);

  //------------ VIEW CITY ----------

  const getCity = async () => {
    cityForm.append("district_id", districtId);
    try {
      const response = await fetch(`${baseUrl}Master/ViewCityByDistrictid`, {
        method: "POST",
        body: cityForm,
        headers: {
          Authorization: `${localStorage.getItem("access_token")}`,
          Accept: "*/*",
        },
      });
      const data = await response.json();

      setCity(data.result);
      if (data && data.result.message == "Session Time Expire.") {
        toast.error("Token expired please login again");
        setTimeout(() => {
          navigate("/");
        }, 3000);
      }
    } catch (error) {
      console.error(error);
    }
  };

  //------------ VIEW VILEGE ----------

  const getVillege = async () => {
    villegeForm.append("city_id", cityId);
    try {
      const response = await fetch(`${baseUrl}Master/ViewVillageByCityId`, {
        method: "POST",
        body: villegeForm,
        headers: {
          Authorization: `${localStorage.getItem("access_token")}`,
          Accept: "*/*",
        },
      });
      const data = await response.json();
      setVillage(data.result);
      if (data && data.result.message == "Session Time Expire.") {
        toast.error("Token expired please login again");
        setTimeout(() => {
          navigate("/");
        }, 3000);
      }
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    getDistrict();
    setCity(null);
  }, [stateId]);

  useEffect(() => {
    getCity();
    setVillage(null);
  }, [districtId]);

  useEffect(() => {
    getVillege();
  }, [cityId]);

  useEffect(() => {
    getCaseType();
  }, [court]);

  useEffect(() => {
    getState();
    getCity();
    getDistrict();
  }, [StateID, CityID]);
  //------------ Checking the share ----------
  useEffect(() => {
    if (counselShare !== "" && coCounselShare !== "") {
      handleCheck();
    }
  }, [counselShare, coCounselShare]);

  useEffect(() => {
    if (editcounselShare !== "" && editcoCounselShare !== "") {
      handleCheck1();
    }
  }, [editcounselShare, editcoCounselShare]);

  useEffect(() => {
    if (localStorage.getItem("access_token") === null) {
      navigate("/");
    } else {
      window.scrollTo(0, 0);
      getCaseView();
      getState();
    }
  }, []);

  return (
    <>
      <DocumentTitle title="ADD CASE | LEGAL CRM">
        <div>
          <div className="wraper">
            <Header />
            <div className="mainWraper">
              <Sidebar />
              <div className="pageBody">
                <div className="pageContent">
                  <div className="PageTitle">
                    <h3>Add Case</h3>
                  </div>

                  <div>
                    <div>
                      {showField && (
                        <div className="add_wrap">
                          <form
                            role="form "
                            className="clientf"
                            onSubmit={HandleCSubmit}
                          >
                            <div className="main_heading">
                              <h2>Add New Client</h2>
                            </div>

                            <hr />
                            <div className="form_flex caseMobile">
                              <div className="form-group">
                                <label for="uname">
                                  Full Name <text className="m_star">*</text>
                                </label>
                                <input
                                  autoComplete="off"
                                  className="name"
                                  type="text"
                                  placeholder="Enter Full Name"
                                  onChange={(e) => setFirstName(e.target.value)}
                                  required
                                />
                              </div>
                              <div className="form-group">
                                <label for="uname">
                                  Mobile No. <text className="m_star">*</text>
                                </label>
                                <input
                                  onKeyPress={handleKeyPress}
                                  maxLength={10}
                                  className="name"
                                  autoComplete="off"
                                  type="text"
                                  placeholder="Enter Mobile No"
                                  onChange={handleInputChange}
                                  required
                                />
                              </div>
                            </div>
                            <div className="form_flex caseMobile">
                              <div className="form-group">
                                <label htmlFor="username">
                                  State <text className="m_star">*</text>{" "}
                                </label>
                                <select
                                  required
                                  className="name"
                                  name="cars"
                                  id="cars"
                                  onChange={(e) => setStateId(e.target.value)}
                                >
                                  <option>--Select State--</option>
                                  {state && state.length ? (
                                    state &&
                                    state.map((val, index) => {
                                      return (
                                        <option
                                          key={index}
                                          value={val.state_id}
                                        >
                                          {val.state_title}
                                        </option>
                                      );
                                    })
                                  ) : (
                                    <option value="">Loading...</option>
                                  )}
                                </select>
                              </div>
                              <div className="form-group">
                                <label htmlFor="username">
                                  {" "}
                                  District <text className="m_star">*</text>
                                </label>
                                <select
                                  required
                                  className="name"
                                  name="cars"
                                  id="cars"
                                  onChange={(e) =>
                                    setDistrictId(e.target.value)
                                  }
                                >
                                  <option>--Select District--</option>
                                  {district && district.length ? (
                                    district.map((val, index) => {
                                      return (
                                        <option
                                          key={index}
                                          value={val.district_id}
                                        >
                                          {val.district_title}
                                        </option>
                                      );
                                    })
                                  ) : (
                                    <option value="">Loading...</option>
                                  )}
                                </select>
                              </div>
                              <div className="form-group">
                                <label htmlFor="username">
                                  Tehsil <text className="m_star">*</text>
                                </label>
                                <select
                                  className="name"
                                  required
                                  name="cars"
                                  id="cars"
                                  onChange={(e) => setCityId(e.target.value)}
                                >
                                  <option value="">--Select Tehsil--</option>
                                  {city && city.length ? (
                                    city.map((val, index) => {
                                      return (
                                        <option key={index} value={val.city_id}>
                                          {val.city_title}
                                        </option>
                                      );
                                    })
                                  ) : (
                                    <option value="">Loading...</option>
                                  )}
                                </select>
                              </div>
                            </div>
                            <div className="btn_main">
                              <button type="submit" className="btn_save">
                                Save
                              </button>
                              <button
                                type="button"
                                onClick={handleClickback}
                                className="btn_save btn_cancle"
                              >
                                Cancel
                              </button>
                            </div>
                            {/* </div> */}
                          </form>
                        </div>
                      )}
                    </div>
                    <div className="popup_modal ">
                      <button
                        type="button"
                        className="close  btn_popup"
                        data-dismiss="modal"
                        aria-label="Close"
                      >
                        <span aria-hidden="true" onClick={handleClickback}>
                          ×
                        </span>
                      </button>

                      <a
                        type="button"
                        className="clientpop"
                        data-toggle="modal"
                        onClick={handleClick}
                      >
                        + Add Client if you missed
                      </a>
                      <a
                        type="button"
                        className="btn btn-primary btn_client"
                        data-toggle="modal"
                        data-target="#exampleModalLongCheckCaseStatus"
                      >
                        Check Case Status
                      </a>
                      <div className="dashed_popup">
                        <form
                          role="form"
                          onSubmit={submitHandler}
                          ref={formRef}
                        >
                          <div className="form_flex caseMobile">
                            <div className="form-group">
                              <label htmlFor="uname">
                                Case Name<text className="m_star">*</text>
                              </label>
                              <input
                                value={title1}
                                className="name"
                                type="text"
                                placeholder="Enter Case Name"
                                name="uname"
                                onChange={handleTitle1}
                                required
                              />
                            </div>
                            VS
                            <div className="form-group">
                              <label htmlFor="uname">&nbsp;</label>
                              <input
                                value={title2}
                                className="name"
                                type="text"
                                placeholder="Enter Case Name"
                                name="uname"
                                onChange={handleTitle2}
                              />
                            </div>
                            <div className="form-group">
                              <label htmlFor="uname" className="clientd">
                                Client<text className="m_star">*</text>
                              </label>
                              <Select
                                required
                                className="basic-single"
                                placeholder="Please Select"
                                classNamePrefix="select"
                                onChange={handleSelectChange}
                                isClearable={true}
                                isSearchable={true}
                                name="color"
                                options={options}
                              />
                            </div>
                          </div>

                          <div className="form_flex caseMobile">
                            <div className="form-group">
                              <label htmlFor="username">Court </label>
                              <select
                                value={court}
                                className="name"
                                name="cars"
                                id="cars"
                                onChange={(e) => {
                                  const selectedCourtId = e.target.value;
                                  const selectedCourtName = courtData.find(
                                    (val) => val.Id === selectedCourtId
                                  )?.CourtName;
                                  handleCourtChange(
                                    selectedCourtId,
                                    selectedCourtName
                                  );
                                }}
                              >
                                <option value="">--Please Select--</option>

                                {courtData &&
                                  courtData.map((val, index) => {
                                    return (
                                      <option key={index} value={val.Id}>
                                        {val.CourtName}
                                      </option>
                                    );
                                  })}
                              </select>
                            </div>

                            <div className="form-group">
                              <label htmlFor="username">Case Type </label>
                              <select
                                className="name"
                                name="cars"
                                id="cars"
                                onChange={(e) => setType(e.target.value)}
                              >
                                <option value="">--Please Select--</option>
                                {caseType && caseType.length ? (
                                  caseType &&
                                  caseType.map((val, index) => {
                                    return (
                                      <option
                                        key={index}
                                        value={val.Id}
                                        selected={val.Id === caseTypeID}
                                      >
                                        {val.CaseType}
                                      </option>
                                    );
                                  })
                                ) : (
                                  <>
                                    <option value="Booked Consultation/Case">
                                      Booked Consultation / Case
                                    </option>

                                    <option value="">
                                      Please select court first...
                                    </option>
                                  </>
                                )}
                              </select>
                            </div>
                            <div className="form-group">
                              <label htmlFor="uname">Case No.</label>
                              <input
                                onKeyPress={handleKeyPress}
                                value={caseNo}
                                className="name"
                                type="text"
                                placeholder="Enter Case No."
                                name="uname"
                                onChange={(e) => setCaseNo(e.target.value)}
                              />
                            </div>
                          </div>
                          <div className="form_flex caseMobile">
                            <div className="form-group">
                              <label htmlFor="uname">
                                Case Year <text className="m_star">*</text>
                              </label>

                              <select
                                required
                                value={caseYear}
                                className="name"
                                name="cars"
                                id="cars"
                                onChange={(e) => setCaseYear(e.target.value)}
                              >
                                <option value="">---Please select---</option>
                                {optionsYear &&
                                  optionsYear.map((val, index) => {
                                    return (
                                      <option key={index} value={val.value}>
                                        {val.label}
                                      </option>
                                    );
                                  })}
                              </select>
                            </div>
                            <div className="form-group">
                              <label htmlFor="username">
                                Court State <text className="m_star">*</text>
                              </label>
                              <select
                                value={stateId}
                                className="name"
                                required
                                name="cars"
                                id="cars"
                                onChange={(e) => setStateId(e.target.value)}
                              >
                                <option value="">--Select State--</option>
                                {state && state.length ? (
                                  state &&
                                  state.map((val, index) => {
                                    return (
                                      <option key={index} value={val.state_id}>
                                        {val.state_title}
                                      </option>
                                    );
                                  })
                                ) : (
                                  <option value="">Loading...</option>
                                )}
                              </select>
                            </div>
                            <div className="form-group">
                              <label htmlFor="username">
                                {" "}
                                Court District <text className="m_star">*</text>
                              </label>
                              <select
                                value={districtId}
                                className="name"
                                name="cars"
                                required
                                id="cars"
                                onChange={(e) => setDistrictId(e.target.value)}
                              >
                                <option value="">--Select District--</option>
                                {district && district.length ? (
                                  district.map((val, index) => {
                                    return (
                                      <option
                                        key={index}
                                        value={val.district_id}
                                      >
                                        {val.district_title}
                                      </option>
                                    );
                                  })
                                ) : (
                                  <option value="">Loading...</option>
                                )}
                              </select>
                            </div>
                          </div>
                          <div className="form_flex caseMobile">
                            <div className="form-group">
                              <label htmlFor="username">Court Tehsil</label>
                              <select
                                className="name"
                                name="cars"
                                id="cars"
                                onChange={(e) => setCityId(e.target.value)}
                              >
                                <option value="">--Select Tehsil--</option>
                                {city && city.length ? (
                                  city.map((val, index) => {
                                    return (
                                      <option key={index} value={val.city_id}>
                                        {val.city_title}
                                      </option>
                                    );
                                  })
                                ) : (
                                  <option value="">Loading...</option>
                                )}
                              </select>
                            </div>
                            <div className="form-group">
                              <label htmlFor="uname">
                                Case Booked At <text className="m_star">*</text>
                              </label>

                              <select
                                required
                                value={branchCity}
                                className="name"
                                name="cars"
                                id="cars"
                                onChange={(e) => setBranchCity(e.target.value)}
                              >
                                <option value="">---Please select---</option>
                                {branchCityData &&
                                  branchCityData.map((val, index) => {
                                    return (
                                      <option key={index} value={val.Id}>
                                        {val.BranchCity}
                                      </option>
                                    );
                                  })}
                              </select>
                            </div>

                            <div className="form-group">
                              <label htmlFor="uname">Filing Date </label>
                              <input
                                className="name"
                                value={filingDate}
                                type="date"
                                placeholder=""
                                name="uname"
                                onChange={(e) => setFilingdate(e.target.value)}
                              />
                            </div>
                          </div>

                          {showInputField && (
                            <>
                              <div className="main_heading">
                                <h2>Diary</h2>
                              </div>
                              <div className="form_flex caseMobile">
                                <div className="form-group">
                                  <label htmlFor="uname">Diary No.</label>
                                  <input
                                    className="name"
                                    type="text"
                                    placeholder="Enter Diary No."
                                    name="uname"
                                    onChange={(e) => setDiaryNo(e.target.value)}
                                  />
                                </div>
                                <div className="form-group">
                                  <label htmlFor="uname">Diary Year</label>
                                  <select
                                    className="name"
                                    name="cars"
                                    id="cars"
                                    onChange={(e) =>
                                      setDiaryYear(e.target.value)
                                    }
                                  >
                                    <option value="">--Please Select--</option>
                                    {optionsYear &&
                                      optionsYear.map((val, index) => {
                                        return (
                                          <option key={index} value={val.label}>
                                            {val.label}
                                          </option>
                                        );
                                      })}
                                  </select>
                                </div>
                                <div className="form-group">
                                  <label htmlFor="uname">Name OF AOR</label>
                                  <input
                                    className="name"
                                    type="text"
                                    placeholder="Enter Name OF AOR"
                                    onChange={(e) => setAor(e.target.value)}
                                  />
                                </div>
                              </div>
                            </>
                          )}
                          <hr />
                          <div className="main_heading">
                            <h2>Case Summary</h2>
                          </div>

                          <div className="form_flex caseMobile">
                            <div className="form-group">
                              <label htmlFor="uname">Summary</label>
                              <textarea
                                className="name"
                                type="text"
                                placeholder="Enter Case Summary"
                                name="uname"
                                onChange={(e) => setSummary(e.target.value)}
                              />
                            </div>
                          </div>
                          <hr />
                          <div className="main_heading">
                            <h2>Counsel</h2>
                          </div>

                          <div className="form_flex caseMobile">
                            <div className="form-group">
                              <label htmlFor="uname">
                                Counsel (Incharge){" "}
                                <text className="m_star">*</text>{" "}
                              </label>
                              <select
                                className="name"
                                name="cars"
                                id="cars"
                                value={counsel}
                                onChange={(e) =>
                                  handleCounselChange(e.target.value)
                                }
                                required
                              >
                                <option value="">Please select</option>
                                {optionsUser &&
                                  optionsUser.map((val, index) => {
                                    return (
                                      <option key={index} value={val.Id}>
                                        {val.Name}
                                      </option>
                                    );
                                  })}
                              </select>
                            </div>

                            <div className="form-group">
                              <label htmlFor="uname">
                                Counsel Share ( In % )
                              </label>
                              <input
                                className="name"
                                type="text"
                                placeholder="%"
                                value={counselShare}
                                onChange={handleCounselShareChange}
                              />
                            </div>
                          </div>
                          <hr />
                          {cocounselRow &&
                            cocounselRow.map((row, index) => (
                              <div key={index}>
                                <div className="form_flex caseMobile">
                                  <div className="form-group">
                                    <label htmlFor={`coCounselName-${index}`}>
                                      Co-Counsel _ {index + 1}
                                    </label>
                                    <select
                                      className="name"
                                      name="cars"
                                      id="cars"
                                      value={row.coCounselName}
                                      onChange={(e) =>
                                        handlecoCounsel(index, e.target.value)
                                      }
                                    >
                                      <option value="">
                                        --Please Select--
                                      </option>
                                      {coCounselData &&
                                        coCounselData.map((val, index) => {
                                          return (
                                            <option value={val.Id}>
                                              {val.Name}
                                            </option>
                                          );
                                        })}
                                    </select>
                                  </div>

                                  <div className="form-group">
                                    <label htmlFor={`coCounselShare-${index}`}>
                                      Co Counsel Share ( In % )
                                    </label>
                                    <input
                                      className="name"
                                      type="text"
                                      placeholder="%"
                                      value={row.coCounselShare}
                                      onChange={(e) =>
                                        handleCoCounselShareChange(
                                          index,
                                          e.target.value
                                        )
                                      }
                                    />
                                  </div>
                                  {index === 0 && (
                                    <a
                                      onClick={addRow}
                                      style={{
                                        color: "white",
                                        cursor: "pointer",
                                        margin: "10px",
                                        padding: "6px 10px",
                                        background: "green",
                                      }}
                                    >
                                      +
                                    </a>
                                  )}

                                  {index !== 0 && (
                                    <a
                                      onClick={() => removeRow(index)}
                                      style={{
                                        color: "white",
                                        cursor: "pointer",
                                        margin: "10px",
                                        padding: "6px 6px",
                                      }}
                                    >
                                      ❌
                                    </a>
                                  )}
                                </div>
                              </div>
                            ))}
                          <div className="form_flex caseMobile">
                            <div className="form-group">
                              <label htmlFor="uname">Company Percentage</label>
                              <input
                                className="name"
                                readOnly
                                type="text"
                                value={compantPrecentage}
                              />
                            </div>
                            <div className="form-group">
                              <label htmlFor="uname">
                                Total Co Counsel Share
                              </label>
                              <input
                                className="name"
                                readOnly
                                type="text"
                                value={totalCoCounsel}
                              />
                            </div>
                          </div>

                          <hr />
                          <div className="form_flex caseMobile">
                            <div className="form-group">
                              <label htmlFor="uname">Retaineed For</label>
                              <select
                                className="name"
                                name="cars"
                                id="cars"
                                onChange={(e) => setRetaineed(e.target.value)}
                              >
                                <option value="">--Please Select--</option>
                                <option value="Petitioners">
                                  PETITIONERS / PLAINTIFFS / APPELLANTS
                                </option>
                                <option value="Respondants">
                                  RESPONDANTS / DEFANDANTS
                                </option>
                                <option value="Intervenors">
                                  INTERVENORS / COMPLAINANTS
                                </option>
                              </select>
                            </div>
                            <div className="form-group">
                              <label htmlFor="uname">
                                Intervenors/ Complainants
                              </label>
                              <textarea
                                className="name"
                                rows={2}
                                cols={10}
                                placeholder="Enter Intervenors"
                                onChange={(e) => setIntervenors(e.target.value)}
                              />
                            </div>
                          </div>
                          <div className="form_flex caseMobile">
                            <div className="form-group">
                              <label htmlFor="uname">
                                Petitioners/ Plaintiffs
                              </label>
                              <textarea
                                className="name"
                                rows={2}
                                cols={10}
                                value={patitioners}
                                placeholder="Enter Petitioners"
                                onChange={handlePatitioners}
                              />
                            </div>

                            <div className="form-group">
                              <label htmlFor="uname">
                                Respondants/ Defandants
                              </label>
                              <textarea
                                className="name"
                                rows={2}
                                cols={10}
                                value={respondants}
                                placeholder="Enter Respondants"
                                onChange={handleRespondants}
                              />
                            </div>
                          </div>
                          <hr />
                          <div className="main_heading">
                            <h2>Payment Structure</h2>
                          </div>

                          <div className="form_flex caseMobile">
                            <div className="form-group">
                              <label htmlFor="uname">Fixed Fees</label>
                              <input
                                className="name"
                                onKeyPress={handleKeyPress}
                                type="text"
                                placeholder="Enter Fixed Fees"
                                name="uname"
                                onChange={(e) => setFixedFees(e.target.value)}
                              />
                            </div>
                            <div className="form-group">
                              <label htmlFor="uname">Expenses</label>
                              <select
                                className="name"
                                name="cars"
                                id="cars"
                                onChange={(e) => setExpenses(e.target.value)}
                              >
                                <option value="">--Please Select--</option>
                                <option value="0">EXCLUSIVE</option>
                                <option value="1">INCLUSIVE</option>
                              </select>
                            </div>
                          </div>
                          <div className="form_flex caseMobile">
                            <div className="form-group">
                              <label htmlFor="uname">
                                Effective Appearance Fees
                              </label>
                              <input
                                className="name"
                                onKeyPress={handleKeyPress}
                                type="text"
                                placeholder="Per Effective Appearance Fees"
                                name="uname"
                                onChange={(e) =>
                                  setAppearanceFee(e.target.value)
                                }
                              />
                            </div>
                            <div className="form-group">
                              <label htmlFor="uname">
                                Non Effective Appr. Fees
                              </label>
                              <input
                                className="name"
                                onKeyPress={handleKeyPress}
                                type="text"
                                placeholder="per Non Effective Appr. Fees"
                                name="uname"
                                onChange={(e) =>
                                  setNonAppearanceFee(e.target.value)
                                }
                              />
                            </div>
                          </div>
                          {saveButton ? (
                            <div className="btn_main caseMobile">
                              <button
                                type="submit"
                                className="btn_save"
                                data-dismiss={popup ? "modal" : ""}
                              >
                                Save
                              </button>
                            </div>
                          ) : (
                            ""
                          )}
                        </form>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <Footer />
          </div>

          {/*--------- Toster ---------*/}

          <ToastContainer
            position="top-right"
            autoClose={1000}
            hideProgressBar={false}
            newestOnTop={false}
            closeOnClick
            rtl={false}
            pauseOnFocusLoss
            draggable
            pauseOnHover
          />
        </div>
      </DocumentTitle>

      {/*-------------------- Check Case Status FORM-------------------- */}
      <div
        className="modal fade"
        id="exampleModalLongCheckCaseStatus"
        tabIndex="-1"
        role="dialog"
        aria-labelledby="exampleModalLongTitle"
        aria-hidden="true"
      >
        <div
          className="modal-dialog popUp modal-xl"
          role="document"
          // style={{ maxWidth: "75%" }}
        >
          <div className="modal-content">
            <div className="add_wrap">
              <div className="main_heading">
                <h2>Check Case Status</h2>
              </div>
            </div>
            <div className="popup_modal ">
              <button
                type="button"
                className="close btn_popup closeCaseStatusForm"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
              <div className="dashed_popup">
                <form role="form " onSubmit={handleCaseStatusSubmit}>
                  <div className="form_flex caseMobile">
                    <div className="form-group">
                      <label for="uname">
                        Court ID
                        <text className="m_star">*</text>
                      </label>
                      <select
                        required
                        value={caseStatusCourtID}
                        onChange={(e) => setCaseStatusCourtID(e.target.value)}
                      >
                        <option value="">Select</option>
                        <option value="GWL">Gwalior</option>
                        <option value="JBP">Jabalpur</option>
                        <option value="IND">Indore</option>
                      </select>
                    </div>
                    <div className="form-group">
                      <label for="uname">
                        Case Type
                        <text className="m_star">*</text>
                      </label>
                      <select
                        required
                        value={caseStatusCaseType}
                        onChange={(e) => setCaseStatusCaseType(e.target.value)}
                      >
                        <option value="">Case Type</option>
                        <option value="63">AA - ARBITRATION APPEAL</option>
                        <option value="100">AB - ADVISORY BOARD</option>
                        <option value="64">AC - ARBITRATION CASE</option>
                        <option value="65">AR - ARBITRATION REVISION</option>
                        <option value="61">ARBA - ARBITRATION APPEAL</option>
                        <option value="60">ARBC - ARBITRATION CASES</option>
                        <option value="101">CA(R) - CIVIL APPLICATION</option>
                        <option value="102">
                          CDR - CIRIMINAL DEATH REFRENCE
                        </option>
                        <option value="74">CEA - CENTRAL EXCISE APPEAL</option>
                        <option value="27">CER - CENTRAL EXCISE REF.</option>
                        <option value="30">CESR - CENTRAL EXCISE SALES</option>
                        <option value="83">CFA - CFA</option>
                        <option value="103">
                          CFA(MBI) - CIVIL FIRST APPEAL(MBI)
                        </option>
                        <option value="104">
                          CMA - CIVIL MISCELLANEOUS APPLICATION
                        </option>
                        <option value="105">
                          CMA(R) - CIVIL MISCELLANEOUS APPEAL
                        </option>
                        <option value="106">
                          CMAP(R) - CIVIL MISCELLANEOUS APPLICATION
                        </option>
                        <option value="107">
                          CMAPL - CIVIL MISCELLANEOUS APPEAL
                        </option>
                        <option value="108">
                          CMC - CIVIL MISCELLANEOUS CASE
                        </option>
                        <option value="109">
                          CMEC - CIVIL MISCELLANEOUS EXECUTION CASE
                        </option>
                        <option value="110">
                          CMJ - CIVIL MISCELLANEOUS JURISDICTION
                        </option>
                        <option value="111">
                          CMP - CIVIL MISCELLANEOUS PETITION
                        </option>
                        <option value="112">
                          CMSA - CIVIL MISCELLANEOUS SECOND APPEAL
                        </option>
                        <option value="113">
                          CMWP - CIVIL MISCELLANEOUS WRIT PETITION
                        </option>
                        <option value="69">COMA - COMPANY APPEAL</option>
                        <option value="21">COMP - COMPANY PETITION</option>
                        <option value="31">COMPA - COMPANY APPEALS</option>
                        <option value="66">CONA - CONTEMPT APPEAL</option>
                        <option value="67">
                          CONC - CONTEMPT PETITION CIVIL
                        </option>
                        <option value="73">
                          CONCR - CONTEMPT PETITION CRIMINAL
                        </option>
                        <option value="15">CONT - CONTEMPT. PETITION</option>
                        <option value="54">CONTR - CRIMINAL CONTEMPT</option>
                        <option value="114">COS - CIVIL ORIGINAL SUIT</option>
                        <option value="140">
                          CP(CRI) - CONTEMPT PETITION (CRIMINAL)
                        </option>
                        <option value="19">CR - CIVIL REVISION</option>
                        <option value="51">CRA - CRIMINAL APPEAL</option>
                        <option value="115">
                          CRCC - CRIMINAL CONFORMATION CASE
                        </option>
                        <option value="116">CREF - CIVIL REFERENCE</option>
                        <option value="141">
                          CREGA - CIVIL REGULAR APPEAL
                        </option>
                        <option value="117">CREV - CIVIL REVIEW</option>
                        <option value="118">
                          CRMA - CRIMINAL MISCELLANEOUS APPLICATION
                        </option>
                        <option value="142">
                          CRMC - CRIMINAL MISCELLANEOUS CASE
                        </option>
                        <option value="119">
                          CRP - CIVIL REVISION PETITION
                        </option>
                        <option value="53">CRR - CRIMINAL REVISION</option>
                        <option value="55">CRRE - CRIMINAL REFERENCE</option>
                        <option value="71">CRRF - CRIMINAL REFERENCE</option>
                        <option value="72">
                          CRRFC - CRIMINAL REFERENCE CAPITAL
                        </option>
                        <option value="120">
                          CRSA - CRIMINAL SPECIAL APPEAL
                        </option>
                        <option value="34">CS - CIVIL SUIT</option>
                        <option value="82">CSA - CSA</option>
                        <option value="121">
                          CSA(MBI) - CIVIL SECOND APPEAL(MBI)
                        </option>
                        <option value="122">CSAP - CIVIL SPECIAL APPEAL</option>
                        <option value="123">DR - DEATH REFERENCE</option>
                        <option value="125">EC - EXECUTION CASE</option>
                        <option value="126">
                          EMC - EXECUTION MISCELLANEOUS CASE
                        </option>
                        <option value="127">
                          EOC - EXECUTION OBJECTION CASE
                        </option>
                        <option value="20">EP - ELECTION PETITION</option>
                        <option value="13">FA - FIRST APPEAL</option>
                        <option value="75">
                          FEMA - FORIEGN EXCHANGE MANAGEMENT APPEAL
                        </option>
                        <option value="26">ITA - INCOME TAX APPEAL</option>
                        <option value="22">ITR - INCOME-TAX REFERENCE</option>
                        <option value="17">LPA - LETTER PATENT APPEAL</option>
                        <option value="128">LPAA - LETTER PATENT APPEAL</option>
                        <option value="12">MA - MISC. APPEAL</option>
                        <option value="129">MA(cri) - MA(CRI)</option>
                        <option value="35">
                          MACE - MISC. APPEAL (C. EXCISE)
                        </option>
                        <option value="59">MACOM - MISC.APPEAL(COMPANY)</option>
                        <option value="62">MACTR - COMMERCIAL TAX REF.</option>
                        <option value="28">MAIT - MISC. APPEAL (I.T.)</option>
                        <option value="130">
                          MAPL - MISCELLANEOUS APPLICATION
                        </option>
                        <option value="58">MAVAT - MISC. APPEAL(VAT)</option>
                        <option value="18">MCC - MISC. CIVIL CASE</option>
                        <option value="70">MCOMA - MISC. COMPANY APPEAL</option>
                        <option value="24">MCP - MISC. CIVIL PETITION</option>
                        <option value="52">MCRC - MISC. CRIMINAL CASE</option>
                        <option value="56">MCRP - MISC. CRI. PETITION</option>
                        <option value="131">
                          MEC - MISCELLANEOUS EXECUTION CASE
                        </option>
                        <option value="132">
                          MFA - MISCALLENEOUS FIRST APPREAL
                        </option>
                        <option value="16">MP - MISC. PETITION</option>
                        <option value="90">
                          MSA - MISCELLANEOUS SECOND APPEAL
                        </option>
                        <option value="133">
                          MSAL - MISCALLENEOUS SECOND APPREAL
                        </option>
                        <option value="25">MWP - MISC. WRIT PETITION</option>
                        <option value="80">OA - ORIGINAL APPLICATION</option>
                        <option value="135">OC - OBJECTION CASE</option>
                        <option value="79">OTA - OTHER TAX APPEALS</option>
                        <option value="136">RC - REVIEW CASE</option>
                        <option value="68">RP - REVIEW PETITION</option>
                        <option value="14">SA - SECOND APPEAL</option>
                        <option value="85">SATMA - SATMA</option>
                        <option value="86">SATMCC - SATMCC</option>
                        <option value="88">SATOA - SATOA</option>
                        <option value="84">SATOT - SATOT</option>
                        <option value="89">SATTA - SATTA</option>
                        <option value="137">
                          SCA - SPECIAL CRIMINAL APPEAL
                        </option>
                        <option value="138">SCR - SMALL CAUSE REVISION</option>
                        <option value="139">
                          SCR(MBI) - SMALL CAUSE REVISION(MBI)
                        </option>
                        <option value="29">STR - SALES TAX REFERENCE</option>
                        <option value="81">TA - TRANSFER APPLICATION</option>
                        <option value="76">TR - TAX REFERENCE</option>
                        <option value="77">
                          VATA - VALUE ADDED TAX APPEAL
                        </option>
                        <option value="57">WA - WRIT APPEAL</option>
                        <option value="11">WP - WRIT PETITION</option>
                        <option value="32">WPS - WRIT PET. (SERVICE)</option>
                        <option value="78">WTA - WEALTH TAX APPEAL</option>
                        <option value="23">WTR - WEALTH-TAX REFERENCE</option>
                      </select>
                    </div>
                    <div className="form-group">
                      <label for="uname">
                        Case Number <text className="m_star">*</text>
                      </label>
                      <input
                        required
                        autoComplete="off"
                        className="name"
                        value={caseStatusCaseNumber}
                        type="text"
                        placeholder="Enter Case No"
                        onChange={(e) =>
                          setCaseStatusCaseNumber(e.target.value)
                        }
                      />
                    </div>
                  </div>
                  <div className="form_flex caseMobile">
                    <div className="form-group">
                      <label for="uname">
                        Case Year
                        <text className="m_star">*</text>
                      </label>
                      <select
                        value={caseStatusCaseYear}
                        required
                        className="name"
                        name="cars"
                        id="cars"
                        onChange={(e) => setCaseStatusCaseYear(e.target.value)}
                      >
                        <option value="">---Please select---</option>
                        {optionsYear &&
                          optionsYear.map((val, index) => {
                            return (
                              <option key={index} value={val.value}>
                                {val.label}
                              </option>
                            );
                          })}
                      </select>
                    </div>
                    <div className="form-group">
                      <label for="uname">Captcha</label>
                      <div>
                        <img src={captchaPath} />
                      </div>
                    </div>
                    <div className="form-group">
                      <label for="uname">
                        Captcha Code
                        <text className="m_star">*</text>
                      </label>
                      <input
                        autoComplete="off"
                        className="name"
                        type="text"
                        placeholder="Enter Captcha Code"
                        required
                        value={caseStatusCaptchaCode}
                        onChange={(e) =>
                          setCaseStatusCaptchaCode(e.target.value)
                        }
                      />
                    </div>
                  </div>
                  {registration_number ? (
                    <div className="form-group">
                      <div style={{ fontWeight: "700", color: "red" }}>
                        {`This case has been registered under ${registration_number}`}
                      </div>
                    </div>
                  ) : (
                    ""
                  )}

                  <div className="btn_main caseMobile">
                    <button
                      type="submit"
                      className="btn_save btn_cancle"
                      onClick={handleReset}
                    >
                      Reset
                    </button>
                    <button type="submit" className="btn_save">
                      Save
                    </button>
                    <button
                      type="button"
                      className="btn_save btn_cancle"
                      data-dismiss="modal"
                    >
                      Cancel
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/*-------- TOASTER ---------*/}

      <ToastContainer
        position="top-right"
        autoClose={1000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
    </>
  );
};

export default AddCase;
