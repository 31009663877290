import React, { useState, useRef } from "react";
import Swal from "sweetalert";
import Header from "./Header";
import Sidebar from "./Sidebar";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useContext } from "react";
import { url } from "../index";
import { useEffect } from "react";
import { useNavigate } from "react-router";
import DocumentTitle from "react-document-title";
import Footer from "./Footer";

const SearchByCaseType = () => {
  const baseUrl = useContext(url);
  const navigate = useNavigate();
  const [data, setData] = useState("");
  const [number, setNumber] = useState("");
  const [year, setYear] = useState("");
  const [selectedCaseType, setSelectedCaseType] = useState("");

  // -------------- HANDLE SUBMIT CASE STATUS ---------------
  const handleSubmit = async (e) => {
    e.preventDefault();
    setData("");
    Swal({
      text: "Loading...",
      content: {
        element: "div",
        attributes: {
          innerHTML: `
                  <div style="display: flex; align-items: center; justify-content: center;">
                    <div class="spinner" style="
                      width: 30px;
                      height: 30px;
                      border: 4px solid rgba(0, 0, 0, 0.1);
                      border-left-color: #000;
                      border-radius: 50%;
                      animation: spin 1s linear infinite;
                    "></div>
                  </div>
                `,
        },
      },
      buttons: false,
      closeOnClickOutside: false,
    });
    const caseStatusForm = new FormData();
    caseStatusForm.append("regcase_type", selectedCaseType);
    caseStatusForm.append("reg_no", number);
    caseStatusForm.append("reg_year", year);

    try {
      const response = await fetch(
        `${baseUrl}CaseStatusByGovWeb/getCaseDataByRegistration`,
        {
          method: "POST",
          headers: {
            Authorization: `${localStorage.getItem("access_token")}`,
            Accept: "*/*",
          },
          body: caseStatusForm,
        }
      );

      const data = await response.json();
      Swal.close();
      if (data && data.result.message === "Session Time Expire.") {
        toast.error("Token expired please login again");
        navigate("/");
      }

      if (data && data.status === "Success") {
        toast.success("Success !");
        setSelectedCaseType("");
        setNumber("");
        setYear("");
        setData(data && data.result);
      } else {
        toast.error(data.result.message);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const resetForm = () => {
    setSelectedCaseType("");
    setNumber("");
    setYear("");
    setData("");
  };

  useEffect(() => {
    if (localStorage.getItem("access_token") === null) {
      navigate("/");
    } else {
      window.scrollTo(0, 0);
    }
  }, []);

  return (
    <>
      <DocumentTitle title="Search Case Type | LEGAL CRM">
        <div>
          <div className="wraper">
            <Header />
            <div className="mainWraper">
              <Sidebar />
              <div className="pageBody">
                <div className="pageContent">
                  <div className="PageTitle">
                    <h3>Search By Case Type</h3>
                  </div>
                  <div>
                    <div className="popup_modal ">
                      <div className="dashed_popup">
                        <form role="form" onSubmit={handleSubmit}>
                          <div className="form_flex caseMobile">
                            <div className="form-group">
                              <label htmlFor="dtd">
                                Choose Option <text className="m_star">*</text>
                              </label>
                              <select
                                required
                                value={selectedCaseType}
                                onChange={(e) =>
                                  setSelectedCaseType(e.target.value)
                                }
                              >
                                <option value="">Case Type</option>
                                <option value="117">
                                  MACC [ MOTOR ACCIDENT CLAIM CASES ]
                                </option>
                                <option value="201">
                                  BA [ BAIL APPLICATION ]
                                </option>
                                <option value="122">
                                  COMMA [ COMMERCIAL APPEAL ]
                                </option>
                                <option value="121">
                                  COMMS [ COMMERCIAL SUITS ]
                                </option>
                                <option value="205">
                                  CRA [ REGULAR CRIMINAL APPEAL ]
                                </option>
                                <option value="202">
                                  CRR [ CRIMINAL REVISION ]
                                </option>
                                <option value="203">
                                  DE [ DEPARTMENTAL ENQUIRY ]
                                </option>
                                <option value="103">
                                  EP [ ELECTION PETITION ]
                                </option>
                                <option value="105">
                                  EX A [ EXECUTION CLASS-A ]
                                </option>
                                <option value="104">
                                  EX AB [ EXECUTION ARBITRATION ]
                                </option>
                                <option value="106">
                                  EX B [ EXECUTION CLASS-B ]
                                </option>
                                <option value="107">
                                  EX LA [ EXECUTION LAND ACQUISITION ]
                                </option>
                                <option value="108">
                                  EX MJC [ EXECUTION MOTOR ACCIDENT CLAIM CASES
                                  ]
                                </option>
                                <option value="110">
                                  MCA [ MISCELLANEOUS CIVIL APPEAL ]
                                </option>
                                <option value="111">
                                  MJC [ MISCELLANEOUS JUDICIAL CASES (CIVIL) ]
                                </option>
                                <option value="112">
                                  MJC AV [ MJC ARBITRATION ]
                                </option>
                                <option value="113">
                                  MJC GW [ MJC GUARDIAN &amp; WARDS ACT ]
                                </option>
                                <option value="114">
                                  MJC INS [ MJC INSOLVENCY ACT ]
                                </option>
                                <option value="109">
                                  MJC MACC [ MISC. JUDICIAL CASE ACCIDENT CLAIMS
                                  REPORT ]
                                </option>
                                <option value="204">
                                  MJC R [ MISCELLANEOUS JUDICIAL CASES
                                  (CRIMINAL) ]
                                </option>
                                <option value="116">
                                  MJC SUC [ MJC SUCCESSION MATTERS ]
                                </option>
                                <option value="115">
                                  MJC WKF [ MJC MUSLIM WAKF ACT ]
                                </option>
                                <option value="118">
                                  RCA [ REGULAR CIVIL APPEAL ]
                                </option>
                                <option value="101">
                                  RCS A [ CIVIL SUIT CLASS-A ]
                                </option>
                                <option value="102">
                                  RCS B [ CIVIL SUIT CLASS-B ]
                                </option>
                                <option value="119">
                                  RCS HM [ REGULAR CIVIL SUIT (HINDU MARRIGE
                                  ACT) ]
                                </option>
                                <option value="206">
                                  RCT [ REGULAR CRIMINAL TRIAL ]
                                </option>
                                <option value="210">
                                  SC [ SPECIAL CASES ]
                                </option>
                                <option value="212">
                                  SC ATR [ SPECIAL CASES (ATROCITY) ]
                                </option>
                                <option value="213">
                                  SC CBI [ SPECIAL CASES (CBI) ]
                                </option>
                                <option value="211">
                                  SC DOCT [ SPECIAL CASES (ANTI DACOITY) ]
                                </option>
                                <option value="214">
                                  SC ELE [ SPECIAL CASES (ELECTRICITY) ]
                                </option>
                                <option value="215">
                                  SC EOW [ SPECIAL CASES (EOW) ]
                                </option>
                                <option value="216">
                                  SC LOK [ SPECIAL CASES (LOKAYUKT) ]
                                </option>
                                <option value="208">
                                  SC MPSIDC [ SPECIAL CASE MPSIDC ]
                                </option>
                                <option value="217">
                                  SC NDPS [ SPECIAL CASES (NDPS) ]
                                </option>
                                <option value="209">
                                  SC NIA [ SPECIAL CASE N.I. ACT ]
                                </option>
                                <option value="219">
                                  SC PPM [ SPECIAL CASES FOR POLITICAL PERSON(M)
                                  ]
                                </option>
                                <option value="220">
                                  SC PPS [ SPECIAL CASES FOR POLITICAL PERSON(S)
                                  ]
                                </option>
                                <option value="120">
                                  SCS [ SMALL CAUSE SUITS CASES ]
                                </option>
                                <option value="207">
                                  ST [ SESSIONS TRIAL ]
                                </option>
                                <option value="218">
                                  SUM [ SUMMARY TRIAL ]
                                </option>
                                <option value="199">
                                  UN CIV [ Unregistered-Civil ]
                                </option>
                                <option value="299">
                                  UN CR [ Unregistered-Criminal ]
                                </option>
                              </select>
                            </div>
                            <div className="form-group">
                              <label htmlFor="Number ">
                                Number<text className="m_star">*</text>
                              </label>
                              <input
                                required
                                name="Number"
                                id="Number"
                                autoComplete="off"
                                className="name"
                                value={number}
                                type="text"
                                placeholder="Enter Number"
                                onChange={(e) => setNumber(e.target.value)}
                              />
                            </div>
                            <div className="form-group">
                              <label htmlFor="Year">
                                Year
                                <text className="m_star">*</text>
                              </label>
                              <input
                                name="Year"
                                id="Year"
                                autoComplete="off"
                                className="name"
                                type="text"
                                placeholder="Enter Year"
                                required
                                value={year}
                                onChange={(e) => setYear(e.target.value)}
                              />
                            </div>
                          </div>
                          <div className="btn_main caseMobile">
                            <button type="submit" className="btn_save">
                              Save
                            </button>
                            <button
                              type="button"
                              className="btn_save btn_cancle"
                              data-dismiss="modal"
                              onClick={resetForm}
                            >
                              Cancel
                            </button>
                          </div>
                        </form>
                      </div>
                    </div>
                  </div>
                  {data && (
                    <div className="cardBody">
                      <table className="table detail_table">
                        <thead>
                          <tr className="top_table">
                            <th>S NO.</th>
                            <th>CI NO.</th>
                            <th>CASE NO.</th>
                            <th>PARTY NAME</th>
                            <th>ADVOCATE</th>
                          </tr>
                        </thead>
                        <tbody>
                          {data &&
                            data.map((val, index) => {
                              return (
                                <tr>
                                  <td>{index + 1}</td>
                                  <td>
                                    <a href={`/#/casetype-details/${val.CINO}`}>
                                      {val.CINO}
                                    </a>
                                  </td>
                                  <td>{val.CaseNo}</td>
                                  <td>{val.PartyName}</td>
                                  <td>{val.Advocate}</td>
                                </tr>
                              );
                            })}
                        </tbody>
                      </table>
                    </div>
                  )}
                </div>
              </div>
            </div>
            <Footer />
          </div>
        </div>
      </DocumentTitle>

      {/*-------- TOASTER ---------*/}

      <ToastContainer
        position="top-right"
        autoClose={1000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
    </>
  );
};

export default SearchByCaseType;
