import React, { useEffect, useState } from "react";
import { Route, Routes } from "react-router-dom";
import ViewUser from "./components/ViewUser";
import ViewRole from "./components/viewrole/ViewRole";
import Login from "./components/Login";
import Error from "./components/Error";
import Permission from "./components/Permission";
import CaseLead from "./components/CaseLead";
import ClientLead from "./components/ClientLeads";
import ViewClientLead from "./components/Clients";
import Case from "./components/Case";
import Events from "./components/Events";
import ClientDetails from "./components/ClientDetails";
import Notes from "./components/Notes";
import LetterTemplates from "./components/LetterTemplates";
import Task from "./components/Task";
import Expenses from "./components/Expenses";
import Document from "./components/Document";
import DocumentTemp from "./components/DocumentTemp";
import Unauthorized from "./components/Unauthorized";
import Profile from "./components/ProfilePage";
import TaskStage from "./components/TaskStage";
import Bank from "./components/Bank";
import Estimates from "./components/Estimates";
import InvoiceTemp from "./components/InvoiceTemp";
import Letter from "./components/Letter";
import Appointments from "./components/Appointments";
import CalendarPage from "./components/CalendarPage";
import Receipts from "./components/Receipts";
import Invoice from "./components/Invoice";
import SettleAmount from "./components/SettleAmount";
import Config from "./components/Config";
import CaseDetails from "./components/CaseDetails";
import PDF from "./components/PDF";
import Rpdf from "./components/Rpdf";
import Analysis from "./components/Analysis";
import CaseTransfer from "./components/CaseTransfer";
import Payment from "./components/Payment";
import EventPdf from "./components/EventPdf";
import CasePdf from "./components/CasePdf";
import AdminProfile from "./components/AdminProfile";
import CaseReport from "./components/CaseReport";
import CaseFlowReport from "./components/CaseFlowReport";
import EarningReport from "./components/EarningReport";
import EstimatePDF from "./components/EstimatePDF";
import LetterCD from "./components/LetterCD";
import EventCD from "./components/EventCD";
import NotesCD from "./components/NotesCD";
import ExpenseCD from "./components/ExpenseCD";
import AppointCD from "./components/AppointCD";
import Hometest from "./components/Hometest";
import AddCase from "./components/AddCase";
import AddTask from "./components/AddTask";
import AddNote from "./components/AddNote";
import AddExpenses from "./components/AddExpenses";
import AddReceipt from "./components/AddReceipt";
import AddInvoice from "./components/AddInvoice";
import AddAppointment from "./components/AddAppointment";
import BookedCases from "./components/BookedCases";
import IRE from "./components/IRE";
import RCPWP from "./components/RCPWP";
import PDFWP from "./components/PDFWP";
import GoogleTest from "./components/GoogleTest";
import Testing from "./components/Testing";
import CaseType from "./components/CaseType";
import UserAttendance from "./components/UserAttendance";
import UserInvoice from "./components/UserInvoice";
import UserReceipt from "./components/UserReceipt";
import CounselData from "./components/CounselData";
import ReceiptCounselData from "./components/ReceiptCounselData";
import Terms from "./components/Terms";
import Privacy from "./components/Privacy";
import AreaCase from "./components/AreaCase";
import AreaClient from "./components/AreaClient";
import DocumentCD from "./components/DocumentCD";
import Invoicefilter from "./components/Invoicefilter";
// import Homefirst from "./components/Homefirst";
import Recfilter from "./components/Recfilter";
import DiaryAllow from "./components/DiaryAllow";
import SearchLowyerName from "./components/SearchLowyerName";
import SearchByCaseType from "./components/SearchByCaseType";
import CaseTypeDetails from "./components/CaseTypeDetails";

const App = () => {

  useEffect(() => {
    document.title = "LEGAL CRM";
  }, []);

  return (
    <>
      <Routes>
        {/** <Route path="/" element={<Homefirst />}></Route> */}
        <Route path="/" element={<Login />}></Route>
        <Route path="/view-bookedCases/:id" element={<BookedCases />}></Route>
        <Route
          path="/view-invoice/:id/:month_year"
          element={<UserInvoice />}
        ></Route>
        <Route
          path="/view-invoice-filter/:id/:from/:to"
          element={<Invoicefilter />}
        ></Route>
        <Route
          path="/view-receipt/:id/:month_year"
          element={<UserReceipt />}
        ></Route>
        <Route
          path="/view-receipt-filter/:id/:from/:to"
          element={<Recfilter />}
        ></Route>
        <Route
          path="/view-invoice-counsel/:id"
          element={<CounselData />}
        ></Route>
        <Route
          path="/view-receipt-counsel/:id"
          element={<ReceiptCounselData />}
        ></Route>
        <Route path="/view-area-case/:id" element={<AreaCase />}></Route>
        <Route path="/view-area-client/:id" element={<AreaClient />}></Route>
        <Route path="/view-casetype/:id" element={<CaseType />}></Route>
        <Route path="/view-cash-flow/:id" element={<IRE />}></Route>
        <Route path="/add-case" element={<AddCase />}></Route>
        <Route path="/add-task" element={<AddTask />}></Route>
        <Route path="/add-note" element={<AddNote />}></Route>
        <Route path="/add-appointment" element={<AddAppointment />}></Route>
        <Route path="/add-receipt" element={<AddReceipt />}></Route>
        <Route path="/add-invoice" element={<AddInvoice />}></Route>
        <Route path="/add-expenses" element={<AddExpenses />}></Route>
        <Route path="/home" element={<Hometest />}></Route>
        <Route path="/view-user" element={<ViewUser />}></Route>
        <Route path="/view-role" element={<ViewRole />}></Route>
        <Route path="*" element={<Error />}></Route>
        <Route path="/view-permission" element={<Permission />}></Route>
        <Route path="/view-case-lead" element={<CaseLead />}></Route>
        <Route path="/view-case" element={<Case />}></Route>
        <Route path="/view-events" element={<Events />}></Route>
        <Route path="/view-note" element={<Notes />}></Route>
        <Route path="/view-documenttemplate" element={<DocumentTemp />}></Route>
        <Route path="/view-client" element={<ViewClientLead />}></Route>
        <Route path="/view-client-leads" element={<ClientLead />}></Route>
        <Route path="/view-task" element={<Task />}></Route>
        <Route path="/view-taskstage" element={<TaskStage />}></Route>
        <Route path="/view-expenses" element={<Expenses />}></Route>
        <Route path="/view-letter" element={<Letter />}></Route>
        <Route path="/view-case-flow" element={<CaseFlowReport />}></Route>
        <Route path="/view-appointments" element={<Appointments />}></Route>
        <Route
          path="/view-clientdetails/:id"
          element={<ClientDetails />}
        ></Route>
        <Route
          path="/view-letter-template"
          element={<LetterTemplates />}
        ></Route>
        <Route path="/unauthorized" element={<Unauthorized />}></Route>
        <Route path="/view-profile" element={<Profile />}></Route>
        <Route path="/view-my-profile" element={<AdminProfile />}></Route>
        <Route path="/view-payment-history" element={<Payment />}></Route>
        <Route path="/view-bank" element={<Bank />}></Route>
        <Route path="/view-receipt" element={<Receipts />}></Route>
        <Route path="/view-calendar" element={<CalendarPage />}></Route>
        <Route path="/view-analysis" element={<Analysis />}></Route>
        <Route path="/view-estimates" element={<Estimates />}></Route>
        <Route path="/view-invoicetemplate" element={<InvoiceTemp />}></Route>
        <Route path="/view-document" element={<Document />}></Route>
        <Route path="/view-invoice" element={<Invoice />}></Route>
        <Route path="/view-settleamount" element={<SettleAmount />}></Route>
        <Route path="/view-config" element={<Config />}></Route>
        <Route path="/View_Receipt_WP/:id" element={<RCPWP />}></Route>
        <Route path="/View_Invoice_WP/:id" element={<PDFWP />}></Route>
        <Route path="/view-casedetails/:id" element={<CaseDetails />}></Route>
        <Route path="/view-Letterdetails/:id" element={<LetterCD />}></Route>
        <Route path="/view-Eventdetails/:id" element={<EventCD />}></Route>
        <Route path="/view-Expensedetails/:id" element={<ExpenseCD />}></Route>
        <Route path="/view-Documentdetails/:id" element={<DocumentCD />}></Route>
        <Route path="/view-diary-count/:id" element={<DiaryAllow />}></Route>
        <Route
          path="/view-Appointmentdetails/:id"
          element={<AppointCD />}
        ></Route>
        <Route path="/view-Notesdetails/:id" element={<NotesCD />}></Route>
        <Route path="/View_invoice_PDF/:id" element={<PDF />}></Route>
        <Route path="/View_receipt_PDF/:id" element={<Rpdf />}></Route>
        {/* <Route path="/View_Event_PDF/:id" element={<EventPdf />}></Route> */}
        <Route path="/View-casetransfer" element={<CaseTransfer />}></Route>
        <Route path="/View_Event_PDF/:id" element={<EventPdf />}></Route>
        <Route path="/View_Estimate_PDF/:id" element={<EstimatePDF />}></Route>
        <Route path="/View_Case_PDF/:id" element={<CasePdf />}></Route>
        <Route path="/view-case-reports" element={<CaseReport />}></Route>
        <Route path="/view-earning-report" element={<EarningReport />}></Route>
        <Route path="/google-test" element={<GoogleTest />}></Route>
        <Route path="/test" element={<Testing />}></Route>
        <Route path="/view-terms-and-condition" element={<Terms />}></Route>
        <Route path="/view-privacy-policy" element={<Privacy />}></Route>
        <Route path="/view-attendance" element={<UserAttendance />}></Route>
        <Route path="/search-lawyername" element={<SearchLowyerName />}></Route>
        <Route path="/search-casetype" element={<SearchByCaseType />}></Route>
        <Route path="/casetype-details/:id" element={<CaseTypeDetails />}></Route>
      </Routes>
      {/* <Footer /> */}
    </>
  );
};

export default App;
